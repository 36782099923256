import React from 'react';
import { Route, Routes } from 'react-router-dom';
import GlobalStyle from './styles/globalstyle';
import { ThemeProvider } from 'styled-components';
import { PrimaryTheme } from './styles/theme';
import AuthProvider from './hooks/useAuth';
import Login from './pages/login';
import Layout from './pages/layout';
import Dashboard from './pages/dashboard';
import Categories from './pages/categories/create';
import Administrators from './pages/administrators/create';
import Category from './pages/categories/edit';
import Administrator from './pages/administrators/edit';
import DeleteAdministrator from './pages/administrators/delete';
import DeleteCategory from './pages/categories/delete';
import Products from './pages/products/create';
import Product from './pages/products/edit';
import DeleteProduct from './pages/products/delete';
import Brands from './pages/brands/create';
import Brand from './pages/brands/edit';
import DeleteBrand from './pages/brands/delete';
import Banners from './pages/banners/create';
import Banner from './pages/banners/edit';
import DeleteBanner from './pages/banners/delete';
import Users from './pages/users/list';
import User from './pages/users/view';
import Texts from './pages/texts/create';
import Text from './pages/texts/edit';
import DeleteText from './pages/texts/delete';
import Cupons from './pages/cupons/create';
import Cupon from './pages/cupons/edit';
import DeleteCupon from './pages/cupons/delete';
import Integrations from './pages/integrations';
import Orders from './pages/orders/list';
import Images from './pages/images/view';
import Order from './pages/orders/view';
import Subcategories from './pages/subcategories/create';
import FastDeliveries from './pages/fastDeliveries/create';
import FastDelivery from './pages/fastDeliveries/edit';
import FastDeliveryDelete from './pages/fastDeliveries/delete';
import Advertisements from './pages/advertisements/create';
import Advertisement from './pages/advertisements/edit';
import DeleteAdvertisement from './pages/advertisements/delete';
import Promotions from './pages/promotions/create';
import Promotion from './pages/promotions/edit';
import DeletePromotion from './pages/promotions/delete';
import DeleteUser from './pages/users/delete';
import Subcategory from './pages/subcategories/edit';
import DeleteSubcategory from './pages/subcategories/delete';
import Newsletters from './pages/newsletter/list';
import DeleteNewsletter from './pages/newsletter/delete';
import Reclamations from './pages/reclamations/list';
import Reclamation from './pages/reclamations/view';
import DeleteReclamation from './pages/reclamations/delete';
import Resumes from './pages/resumes/list';
import Resume from './pages/resumes/view';
import Chargebacks from './pages/chargebacks/create';
import Chargeback from './pages/chargebacks/edit';
import Offers from './pages/offers/create';
import Offer from './pages/offers/edit';
import DeleteOffer from './pages/offers/delete';

const App: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={PrimaryTheme}>
        <GlobalStyle />
        <AuthProvider>
          <Routes>
            <Route element={<Layout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="integracoes" element={<Integrations />} />
              <Route path="administradores" element={<Administrators />} />
              <Route path="administradores/:id" element={<Administrator />} />
              <Route path="administradores/remover/:id" element={<DeleteAdministrator />} />
              <Route path="categorias" element={<Categories />} key={Math.random()} />
              <Route path="categorias/:id" element={<Category />} />
              <Route path="categorias/remover/:id" element={<DeleteCategory />} />
              <Route path="subcategorias" element={<Subcategories />} />
              <Route path="subcategorias/:id" element={<Subcategory />} />
              <Route path="subcategorias/remover/:id" element={<DeleteSubcategory />} />
              <Route path="produtos" element={<Products />} />
              <Route path="produtos/:id" element={<Product />} />
              <Route path="produtos/remover/:id" element={<DeleteProduct />} />
              <Route path="marcas" element={<Brands />} />
              <Route path="marcas/:id" element={<Brand />} />
              <Route path="marcas/remover/:id" element={<DeleteBrand />} />
              <Route path="banners" element={<Banners />} />
              <Route path="banners/:id" element={<Banner />} />
              <Route path="banners/remover/:id" element={<DeleteBanner />} />
              <Route path="usuarios" element={<Users />} />
              <Route path="usuarios/:id" element={<User />} />
              <Route path="usuarios/remover/:id" element={<DeleteUser />} />
              <Route path="textos" element={<Texts />} />
              <Route path="textos/:id" element={<Text />} />
              <Route path="textos/remover/:id" element={<DeleteText />} />
              <Route path="cupons" element={<Cupons />} />
              <Route path="cupons/:id" element={<Cupon />} />
              <Route path="cupons/remover/:id" element={<DeleteCupon />} />
              <Route path="pedidos" element={<Orders />} />
              <Route path="pedidos/:id" element={<Order />} />
              <Route path="estornos" element={<Chargebacks />} />
              <Route path="estornos/:id" element={<Chargeback />} />
              <Route path="imagens" element={<Images />} />
              <Route path="entregas-rapidas" element={<FastDeliveries />} />
              <Route path="entregas-rapidas/:id" element={<FastDelivery />} />
              <Route path="entregas-rapidas/remover/:id" element={<FastDeliveryDelete />} />
              <Route path="anuncios" element={<Advertisements />} />
              <Route path="anuncios/:id" element={<Advertisement />} />
              <Route path="anuncios/remover/:id" element={<DeleteAdvertisement />} />
              <Route path="promocoes" element={<Promotions />} />
              <Route path="promocoes/:id" element={<Promotion />} />
              <Route path="promocoes/remover/:id" element={<DeletePromotion />} />
              <Route path="ofertas" element={<Offers />} />
              <Route path="ofertas/:id" element={<Offer />} />
              <Route path="ofertas/remover/:id" element={<DeleteOffer />} />
              <Route path="newsletter" element={<Newsletters />} />
              <Route path="newsletter/remover/:id" element={<DeleteNewsletter />} />
              <Route path="reclamacoes" element={<Reclamations />} />
              <Route path="reclamacoes/:id" element={<Reclamation />} />
              <Route path="reclamacoes/remover/:id" element={<DeleteReclamation />} />
              <Route path="curriculos" element={<Resumes />} />
              <Route path="curriculos/:id" element={<Resume />} />
            </Route>
            <Route path="*" element={<Login />} />
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
