import styled from 'styled-components';

interface AlertContainerProps {
  background: string;
  color: string;
}

export const AlertContainer = styled.div<AlertContainerProps>`
  display: flex;
  height: 42px;
  margin-bottom: 8px;
  border-radius: 6px;
  align-items: center;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 0 12px;
  font-size: 0.9rem;
`;
