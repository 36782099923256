import styled from 'styled-components';

export const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('/images/admin/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &: before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(8, 77, 166, 0.5);
    width: 100%;
    height: 100%;
  }
`;

export const LoginBox = styled.div`
  position: relative;
  width: 400px;
  padding: 15px;
  z-index: 999;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 12px;
  }
`;

export const LoginButtonsContainer = styled.div`
  display: flex;

  div:first-child {
    margin-right: 5px;
  }
`;
