import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat', sans-serif !important;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .ck-editor__editable_inline {
    min-height: 300px;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    background: ${({ theme }) => theme.background.third};
  }
`;

export default GlobalStyle;
