import { styled } from 'styled-components';

export const ReclamationsContainer = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 12px;
  position: relative;
  min-height: 100px;

  h1 {
    font-size: 1rem;
    color: ${({ theme }) => theme.background.fifth};
    margin-bottom: 12px;
  }
`;

export const ReclamationsRemoveLabel = styled.p`
  font-size: 0.9rem;
  margin-bottom: 8px;
`;
