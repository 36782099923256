import React, { useEffect, useState } from 'react';
import { IconBox, IconContainer, IconContentContainer, IconHeader } from '../styles/components/icon';
import * as IconsFa from 'react-icons/fa';
import * as IconsRi from 'react-icons/ri';
import * as IconsMd from 'react-icons/md';
import * as IconsBs from 'react-icons/bs';
import * as IconsCi from 'react-icons/ci';
import * as IconsCg from 'react-icons/cg';
import * as IconsGi from 'react-icons/gi';
import * as IconsAi from 'react-icons/ai';
import * as IconsBi from 'react-icons/bi';
import * as IconsFi from 'react-icons/fi';
import * as IconsLu from 'react-icons/lu';
import Loading from './loading';
import CustomInput from './input';

interface IconProps {
  currentIcon?: string;
  stateRef: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const Icon: React.FC<IconProps> = ({ stateRef, currentIcon }) => {
  const [loading, setLoading] = useState(true);
  const [activeIcon, setActiveIcon] = useState<string>();
  const defaultValue = Object.values(IconsFa)
    .concat(Object.values(IconsRi))
    .concat(Object.values(IconsMd))
    .concat(Object.values(IconsBs))
    .concat(Object.values(IconsCi))
    .concat(Object.values(IconsCg))
    .concat(Object.values(IconsGi))
    .concat(Object.values(IconsAi))
    .concat(Object.values(IconsBi))
    .concat(Object.values(IconsFi))
    .concat(Object.values(IconsLu));
  const [icons, setIcons] = useState(defaultValue);
  const limit = 250;

  useEffect(() => {
    stateRef(activeIcon);
  }, [activeIcon]);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setActiveIcon(currentIcon);
  }, [currentIcon]);

  const searchIcon = (search: string): void => {
    if (search.length > 0) {
      setIcons(defaultValue.filter(icon => icon.name.toLowerCase().includes(search.toLowerCase())));
    } else {
      setIcons(defaultValue);
    }
  };

  return (
    <>
      <IconHeader>
        <h1>ICONES</h1>
        <CustomInput>
          <input
            placeholder="Pesquisar ícones"
            onKeyUp={e => {
              searchIcon(e.currentTarget.value);
            }}
          />
        </CustomInput>
      </IconHeader>
      <IconContainer>
        {loading ? (
          <Loading />
        ) : (
          <>
            <IconContentContainer>
              {icons.length > 0 ? (
                <>
                  {icons.slice(0, limit).map((Icon, i) => (
                    <IconBox
                      onClick={() => {
                        activeIcon === Icon.name ? setActiveIcon(undefined) : setActiveIcon(Icon.name);
                      }}
                      active={activeIcon === Icon.name}
                      key={i}
                    >
                      <Icon />
                    </IconBox>
                  ))}
                </>
              ) : (
                <>Nenhum ícone encontrado</>
              )}
            </IconContentContainer>
          </>
        )}
      </IconContainer>
    </>
  );
};

export default Icon;
