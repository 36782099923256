import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading';
import { type TextResponse } from './create';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomButtom from '../../components/button';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import { TextsContainer, TextsRemoveLabel } from '../../styles/pages/texts';
import { useAuth } from '../../hooks/useAuth';

const DeleteText: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [text, setText] = useState<TextResponse>();
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [isRemoved, setIsRemoved] = useState(false);

  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    ApplicationApi.get<TextResponse>(`textos/id/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setText(data);
        setFormLoading(false);
      })
      .catch(() => {
        setFormLoading(false);
        setAlerts({ type: AlertTypes.ERROR, message: 'Texto não encontrada' });
      });
  }, []);

  const submitForm = async (): Promise<void> => {
    setFormLoading(true);

    try {
      await ApplicationApi.delete(`textos/${id}`, { headers: { Authorization: `Bearer ${token}` } });

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Texto removido com sucesso' });
      setIsRemoved(true);
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível remover texto' });
    }

    setFormLoading(false);
  };

  return (
    <TextsContainer>
      {formLoading ? <Loading /> : <></>}
      {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
      {isRemoved ? (
        <>
          <Row>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/textos');
                  }}
                  type="button"
                >
                  VOLTAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </>
      ) : (
        <>
          {text !== undefined ? (
            <>
              <TextsRemoveLabel>
                <b>ID:</b> {text.id}
              </TextsRemoveLabel>
              <TextsRemoveLabel>
                <b>Título:</b> {text.title}
              </TextsRemoveLabel>
              <TextsRemoveLabel>
                <b>Texto:</b> {text.text}
              </TextsRemoveLabel>
              <Row>
                <Column xl={2}>
                  <CustomButtom background={PrimaryTheme.background.secondary}>
                    <button
                      onClick={() => {
                        navigate('/textos');
                      }}
                      type="button"
                    >
                      CANCELAR
                    </button>
                  </CustomButtom>
                </Column>
                <Column xl={2}>
                  <CustomButtom background={ButtonTheme.background.error}>
                    <button onClick={submitForm} type="button">
                      CONFIRMAR EXCLUSÃO
                    </button>
                  </CustomButtom>
                </Column>
              </Row>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </TextsContainer>
  );
};

export default DeleteText;
