// @ts-nocheck
import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductsContainer, TextareaContainer } from '../../styles/pages/products';
import CustomSelect from '../../components/select';
import CurrencyInput from 'react-currency-input';
import { type CategoryResponse } from '../categories/create';
import InputMask from 'react-input-mask';
import { UploadDocument, UploadMultiple, type UploadResponse } from '../../components/upload';
import Fancybox from '../../components/fancybox';
import { FancyboxFooter } from '../../styles/components/fancybox';
import {
  type ProductBody,
  type ProductResponse,
  type ProductClassesResponse,
  type ProductRecipesResponse,
  type ProductRoutesOfAdministrationResponse,
  type ProductStripsResponse,
  type ProductTypesResponse,
  type ProductStatusResponse,
} from './create';
import { BsCurrencyDollar } from 'react-icons/bs';
import { type BrandResponse } from '../brands/create';
import { formatDecimal, formatMoney } from '../../helpers/numberMethods';
import { useAuth } from '../../hooks/useAuth';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';

const bodyDefaultValue = {
  name: '',
  price: '',
  recipeId: 1,
  especifications: '',
  ms: '',
  activePrinciple: '',
  description: '',
  ean: '',
  stock: 0,
  statusId: 1,
  categoryId: null,
};

const Product: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [alerts, setAlerts] = useState<AlertsProps>();
  const [uploadFancybox, setUploadFancybox] = useState(false);
  const [documentUploadFancybox, setDocumentUploadFancybox] = useState(false);

  const [recipes, setRecipes] = useState<ProductRecipesResponse[]>([]);
  const [routes, setRoutes] = useState<ProductRoutesOfAdministrationResponse[]>([]);
  const [classes, setClasses] = useState<ProductClassesResponse[]>([]);
  const [strips, setStrips] = useState<ProductStripsResponse[]>([]);
  const [types, setTypes] = useState<ProductTypesResponse[]>([]);
  const [categories, setCategoris] = useState<CategoryResponse[]>([]);
  const [status, setStatus] = useState<ProductStatusResponse[]>([]);
  const [brands, setBrands] = useState<BrandResponse[]>([]);

  const [formLoading, setFormLoading] = useState(true);

  const [productImages, setProductImages] = useState<UploadResponse[]>();
  const [productDocument, setProductDocument] = useState<UploadResponse>();
  const [description, setDescription] = useState<string>('');
  const [especifications, setEspecifications] = useState<string>('');
  const [body, setBody] = useState<ProductBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<ProductResponse>(`produtos/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setProductImages(data.productImages.map(value => value.uploads));
        setProductDocument(data.productDocuments?.uploads);
        setBody({
          ...data,
          price: formatMoney(data.price),
          newPrice: data.newPrice != null ? formatMoney(data.newPrice) : undefined,
        });
        setFormLoading(false);
      })
      .catch(() => {});

    ApplicationApi.get<ProductRecipesResponse[]>('produtos/receitas', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setRecipes(data);
      })
      .catch(() => {});

    ApplicationApi.get<ProductRoutesOfAdministrationResponse[]>('produtos/administracao', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setRoutes(data);
      })
      .catch(() => {});

    ApplicationApi.get<ProductClassesResponse[]>('produtos/classes', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setClasses(data);
      })
      .catch(() => {});

    ApplicationApi.get<ProductStatusResponse[]>('produtos/status', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setStatus(data);
      })
      .catch(() => {});

    ApplicationApi.get<ProductClassesResponse[]>('produtos/tarjas', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setStrips(data);
      })
      .catch(() => {});

    ApplicationApi.get<ProductClassesResponse[]>('produtos/tipos', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setTypes(data);
      })
      .catch(() => {});

    ApplicationApi.get<CategoryResponse[]>('categorias', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setCategoris(data);
      })
      .catch(() => {});

    ApplicationApi.get<BrandResponse[]>('marcas', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setBrands(data);
      })
      .catch(() => {});
  }, []);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch<ProductResponse>(
        `produtos/${id}`,
        {
          ...body,
          price: formatDecimal(body.price),
          newPrice: body.newPrice !== undefined && body.newPrice !== '' ? formatDecimal(body.newPrice) : null,
          description,
          especifications,
          imagesIds: productImages?.map(value => value.id),
          documentId: productDocument?.id,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados atualizados com sucesso!' });
    } catch {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar produto, verifique as informações inseridas e tente novamente.',
      });
    }

    setFormLoading(false);
  };

  return (
    <>
      {!formLoading ? (
        <>
          <Fancybox active={documentUploadFancybox}>
            <UploadDocument currentUpload={productDocument} stateRef={setProductDocument} />
            <FancyboxFooter>
              <Row>
                <Column xl={3}>
                  <CustomButtom>
                    <button
                      onClick={() => {
                        setDocumentUploadFancybox(false);
                      }}
                    >
                      SELECIONAR
                    </button>
                  </CustomButtom>
                </Column>
                <Column xl={3}>
                  <CustomButtom background={PrimaryTheme.background.secondary}>
                    <button
                      onClick={() => {
                        setProductDocument(undefined);
                        setDocumentUploadFancybox(false);
                      }}
                    >
                      CANCELAR
                    </button>
                  </CustomButtom>
                </Column>
              </Row>
            </FancyboxFooter>
          </Fancybox>
          <Fancybox active={uploadFancybox}>
            <UploadMultiple
              path="produtos"
              ean={body.ean !== '' ? body.ean : undefined}
              currentUpload={productImages}
              stateRef={setProductImages}
            />
            <FancyboxFooter>
              <Row>
                <Column xl={3}>
                  <CustomButtom>
                    <button
                      onClick={() => {
                        setUploadFancybox(false);
                      }}
                    >
                      SELECIONAR
                    </button>
                  </CustomButtom>
                </Column>
                <Column xl={3}>
                  <CustomButtom background={PrimaryTheme.background.secondary}>
                    <button
                      onClick={() => {
                        setProductImages(uploads => []);
                        setUploadFancybox(false);
                      }}
                    >
                      CANCELAR
                    </button>
                  </CustomButtom>
                </Column>
              </Row>
            </FancyboxFooter>
          </Fancybox>
        </>
      ) : (
        <></>
      )}
      <ProductsContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Nome">
                <input
                  value={body.name}
                  onChange={e => {
                    setBody({ ...body, name: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={1}>
              <CustomInput marginBottom={true} label="Preço" icon={<BsCurrencyDollar />}>
                <CurrencyInput
                  onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBody({ ...body, price: e.target.value });
                  }}
                  value={body.price}
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </CustomInput>
            </Column>
            <Column xl={1}>
              {body.promotionProducts?.length > 0 ? (
                <CustomInput marginBottom={true} label="Promoção" icon={<BsCurrencyDollar />}>
                  <input value={formatMoney(body.promotionProducts[0].price)} disabled />
                </CustomInput>
              ) : (
                <CustomInput marginBottom={true} label="Promoção" icon={<BsCurrencyDollar />}>
                  <CurrencyInput
                    onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setBody({ ...body, newPrice: e.target.value });
                    }}
                    value={body.newPrice}
                    decimalSeparator=","
                    thousandSeparator="."
                  />
                </CustomInput>
              )}
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="EAN">
                <input
                  value={body.ean}
                  onChange={e => {
                    setBody({ ...body, ean: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Princípio ativo">
                <input
                  value={body.activePrinciple}
                  onChange={e => {
                    setBody({ ...body, activePrinciple: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              {body.productRoutes !== undefined ? (
                <CustomSelect label="Forma de administração">
                  <Select
                    onChange={e => {
                      setBody({ ...body, routeId: e?.value ?? undefined });
                    }}
                    defaultValue={
                      body.productRoutes !== null
                        ? { label: body.productRoutes.name, value: body.productRoutes.id }
                        : { label: 'Selecione' }
                    }
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={routes.map(route => ({ label: route.name, value: route.id }))}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={2}>
              {body.productStrips !== undefined ? (
                <CustomSelect label="Classificação">
                  <Select
                    onChange={e => {
                      setBody({ ...body, stripId: e?.value ?? undefined });
                    }}
                    defaultValue={
                      body.productStrips !== null
                        ? { label: body.productStrips.name, value: body.productStrips.id }
                        : { label: 'Selecione' }
                    }
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={strips.map(strip => ({ label: strip.name, value: strip.id }))}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={2}>
              {body.productClasses !== undefined ? (
                <CustomSelect label="Classe de medicamento">
                  <Select
                    onChange={e => {
                      setBody({ ...body, classId: e?.value ?? undefined });
                    }}
                    defaultValue={
                      body.productClasses !== null
                        ? { label: body.productClasses.name, value: body.productClasses.id }
                        : { label: 'Selecione' }
                    }
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={classes.map(clas => ({ label: clas.name, value: clas.id }))}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={2}>
              {body.productRecipes !== undefined ? (
                <CustomSelect label="Prescrição médica">
                  <Select
                    onChange={e => {
                      setBody({ ...body, recipeId: e?.value ?? 1 });
                    }}
                    defaultValue={
                      body.productRecipes !== null
                        ? { label: body.productRecipes.name, value: body.productRecipes.id }
                        : { label: 'Selecione' }
                    }
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={recipes.map(recipe => ({ label: recipe.name, value: recipe.id }))}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={2}>
              {body.productTypes !== undefined ? (
                <CustomSelect label="Tipo de medicamento">
                  <Select
                    onChange={e => {
                      setBody({ ...body, typeId: e?.value ?? 1 });
                    }}
                    defaultValue={
                      body.productTypes !== null
                        ? { label: body.productTypes.name, value: body.productTypes.id }
                        : { label: 'Selecione' }
                    }
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={types.map(type => ({ label: type.name, value: type.id }))}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={2}>
              {body.brands !== undefined ? (
                <CustomSelect label="Marca">
                  <Select
                    onChange={e => {
                      setBody({ ...body, brandId: e?.value ?? 1 });
                    }}
                    defaultValue={
                      body.brands !== null ? { label: body.brands.name, value: body.brands.id } : { label: 'Selecione' }
                    }
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={brands.map(brand => ({ label: brand.name, value: brand.id }))}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Registro MS">
                <input
                  value={body.ms}
                  onChange={e => {
                    setBody({ ...body, ms: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Largura">
                <InputMask
                  value={body.width}
                  mask="999"
                  onChange={e => {
                    setBody({ ...body, width: parseInt(e.target.value) });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Altura">
                <InputMask
                  value={body.height}
                  mask="999"
                  onChange={e => {
                    setBody({ ...body, height: parseInt(e.target.value) });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Comprimento">
                <InputMask
                  value={body.length}
                  mask="999"
                  onChange={e => {
                    setBody({ ...body, length: parseInt(e.target.value) });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Peso">
                <InputMask
                  value={body.weight}
                  mask="999"
                  onChange={e => {
                    setBody({ ...body, weight: parseInt(e.target.value) });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              {body.categories !== undefined ? (
                <CustomSelect label="Categoria">
                  <Select
                    onChange={e => {
                      setBody({ ...body, categoryId: e?.value ?? 1 });
                    }}
                    defaultValue={
                      body.categories !== null
                        ? { label: body.categories.name, value: body.categories.id }
                        : { label: 'Selecione' }
                    }
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={categories.map(category => ({ label: category.name, value: category.id }))}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={4}>
              {body.subcategories !== undefined ? (
                <CustomSelect label="Subcategoria">
                  <Select
                    onChange={e => {
                      setBody({ ...body, subcategoryId: e?.value ?? 1 });
                    }}
                    defaultValue={
                      body.subcategories !== null
                        ? { label: body.subcategories.name, value: body.subcategories.id }
                        : { label: 'Selecione' }
                    }
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={categories
                      .filter(item => item.id === body.categoryId)[0]
                      ?.subcategories.map((subcategory, i) => ({ label: subcategory.name, value: subcategory.id }))}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={1}>
              <CustomInput marginBottom={true} label="Estoque">
                <input
                  value={body.stock}
                  onChange={e => {
                    setBody({ ...body, stock: parseInt(e.target.value) });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              {body.productStatus !== undefined ? (
                <CustomSelect label="Status">
                  <Select
                    onChange={e => {
                      setBody({ ...body, statusId: e?.value ?? 1 });
                    }}
                    defaultValue={
                      body.productStatus !== null
                        ? { label: body.productStatus.name, value: body.productStatus.id }
                        : { label: 'Selecione' }
                    }
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={status.map(stat => ({ label: stat.name, value: stat.id }))}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Bula">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setDocumentUploadFancybox(true);
                    }}
                  >
                    {productDocument !== undefined ? `${productDocument.filename}` : 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Fotos">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setUploadFancybox(true);
                    }}
                  >
                    {productImages !== undefined && productImages.length > 0
                      ? `${productImages.length} SELECIONADO(S)`
                      : 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={6}>
              <TextareaContainer>
                <p>Descrição</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={body.description ?? ''}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setDescription(data);
                  }}
                />
              </TextareaContainer>
            </Column>
            <Column xl={6}>
              <TextareaContainer>
                <p>Especificações</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={body.especifications ?? ''}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setEspecifications(data);
                  }}
                />
              </TextareaContainer>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/produtos');
                  }}
                  type="button"
                >
                  VOLTAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.fifth}>
                <button
                  onClick={() =>
                    window.open(
                      `https://www.drogamalu.com.br/produtos/${body.slug}?token=1316bfd46cb2c849698dce2214e8ba13`,
                      'new',
                    )
                  }
                  type="button"
                >
                  PRÉ-VISUALIZAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </ProductsContainer>
    </>
  );
};

export default Product;
