import React, { type FormEvent, createRef, useState, useEffect } from 'react';
import { FaUser, FaLock, FaEnvelope } from 'react-icons/fa6';

import { LoginBox, LoginButtonsContainer, LoginContainer } from '../styles/pages/login';

import CustomInput from '../components/input';
import CustomButtom from '../components/button';
import Loading from '../components/loading';
import Alert, { AlertTypes } from '../components/alerts';
import { PrimaryTheme } from '../styles/theme';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const { onLogin, token } = useAuth();

  const [currentPage, setCurrentPage] = useState<string>('login');

  const loginInputRef = createRef<HTMLInputElement>();
  const passwordInputRef = createRef<HTMLInputElement>();

  document.title = `Administração :: Login`;

  useEffect(() => {
    if (token !== null) navigate('/dashboard');
  }, [token]);

  const loginSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    setLoading(true);

    try {
      if (loginInputRef.current != null && passwordInputRef.current != null) {
        await onLogin(loginInputRef.current.value, passwordInputRef.current.value);
      } else {
        throw new Error();
      }
    } catch {
      setErrors(true);
    }

    setLoading(false);
  };

  return (
    <LoginContainer>
      <LoginBox>
        {loading ? <Loading /> : <></>}
        {currentPage === 'login' ? (
          <>
            <h1>Login</h1>
            <form onSubmit={loginSubmit}>
              {errors ? <Alert type={AlertTypes.ERROR}>Login e/ou senha inválido(s)</Alert> : <></>}
              <CustomInput marginBottom={true} icon={<FaUser />}>
                <input ref={loginInputRef} placeholder="Digite seu login..." required />
              </CustomInput>
              <CustomInput marginBottom={true} icon={<FaLock />}>
                <input ref={passwordInputRef} placeholder="Digite sua senha" type="password" required />
              </CustomInput>
              <LoginButtonsContainer>
                <CustomButtom>
                  <button type="submit">ENTRAR</button>
                </CustomButtom>
                <CustomButtom background={PrimaryTheme.background.secondary}>
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentPage('senha');
                    }}
                  >
                    LEMBRAR SENHA{' '}
                  </button>
                </CustomButtom>
              </LoginButtonsContainer>
            </form>
          </>
        ) : (
          <>
            <h1>Lembrar senha</h1>
            <form>
              {errors ? <Alert type={AlertTypes.ERROR}>Login e/ou senha inválido(s)</Alert> : <></>}
              <CustomInput marginBottom={true} icon={<FaEnvelope />}>
                <input ref={loginInputRef} type="email" placeholder="Digite seu e-mail..." required />
              </CustomInput>
              <LoginButtonsContainer>
                <CustomButtom>
                  <button
                    onClick={() => {
                      setCurrentPage('login');
                    }}
                    type="button"
                  >
                    CANCELAR
                  </button>
                </CustomButtom>
                <CustomButtom background={PrimaryTheme.background.secondary}>
                  <button type="submit">ENVIAR</button>
                </CustomButtom>
              </LoginButtonsContainer>
            </form>
          </>
        )}
      </LoginBox>
    </LoginContainer>
  );
};

export default Login;
