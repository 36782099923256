import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading';
import { type ProductResponse } from './create';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomButtom from '../../components/button';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import { ProductsContainer, ProductsRemoveLabel } from '../../styles/pages/products';
import { useAuth } from '../../hooks/useAuth';
import { formatMoney } from '../../helpers/numberMethods';

const DeleteProduct: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [product, setProduct] = useState<ProductResponse>();
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [isRemoved, setIsRemoved] = useState(false);

  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    ApplicationApi.get<ProductResponse>(`produtos/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setProduct(data);
        setFormLoading(false);
      })
      .catch(() => {
        setFormLoading(false);
        setAlerts({ type: AlertTypes.ERROR, message: 'Produto não encontrado' });
      });
  }, []);

  const submitForm = async (): Promise<void> => {
    setFormLoading(true);

    try {
      await ApplicationApi.delete(`produtos/${id}`, { headers: { Authorization: `Bearer ${token}` } });

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Produto removido com sucesso' });
      setIsRemoved(true);
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível remover produto' });
    }

    setFormLoading(false);
  };

  return (
    <ProductsContainer>
      {formLoading ? <Loading /> : <></>}
      {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
      {isRemoved ? (
        <>
          <Row>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/produtos');
                  }}
                  type="button"
                >
                  VOLTAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </>
      ) : (
        <>
          {product !== undefined ? (
            <>
              <ProductsRemoveLabel>
                <b>ID:</b> {product.id}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Nome:</b> {product.name}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Código:</b> {product.code}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>EAN:</b> {product.ean}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Preço:</b> R$ {formatMoney(product.price)}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Promoção:</b> R$ {product.newPrice !== null ? formatMoney(product.newPrice) : '0,00'}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Princípio ativo:</b> {product.activePrinciple}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Forma de administração:</b> {product.productRoutes?.name}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Classificação:</b> {product.productStrips?.name}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Prescrição médica:</b> {product.productRecipes?.name}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Tipo de medicamento:</b> {product.productTypes?.name}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Classe medicamento:</b> {product.productClasses?.name}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Marca:</b> {product.brands?.name}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Registro MS:</b> {product.ms}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Descrição:</b> {product.description}
              </ProductsRemoveLabel>
              <ProductsRemoveLabel>
                <b>Especificações:</b> {product.especifications}
              </ProductsRemoveLabel>
              <Row>
                <Column xl={2}>
                  <CustomButtom background={PrimaryTheme.background.secondary}>
                    <button
                      onClick={() => {
                        navigate('/produtos');
                      }}
                      type="button"
                    >
                      CANCELAR
                    </button>
                  </CustomButtom>
                </Column>
                <Column xl={2}>
                  <CustomButtom background={ButtonTheme.background.error}>
                    <button onClick={submitForm} type="button">
                      CONFIRMAR EXCLUSÃO
                    </button>
                  </CustomButtom>
                </Column>
              </Row>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </ProductsContainer>
  );
};

export default DeleteProduct;
