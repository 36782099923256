import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomButtom from '../../components/button';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import { useAuth } from '../../hooks/useAuth';
import { type UserResponse } from './list';
import { UsersContainer, UsersRemoveLabel } from '../../styles/pages/users';
import { formatDateToBR } from '../../helpers/dateFormat';

const DeleteUser: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [user, setUser] = useState<UserResponse>();
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [isRemoved, setIsRemoved] = useState(false);

  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    ApplicationApi.get<UserResponse>(`usuarios/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setUser({ ...data, birthdate: formatDateToBR(data.birthdate, true, true) });
        setFormLoading(false);
      })
      .catch(() => {
        setFormLoading(false);
        setAlerts({ type: AlertTypes.ERROR, message: 'Usuário não encontrado' });
      });
  }, []);

  const submitForm = async (): Promise<void> => {
    setFormLoading(true);

    try {
      await ApplicationApi.delete(`usuarios/${id}`, { headers: { Authorization: `Bearer ${token}` } });

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Usuário removido com sucesso' });
      setIsRemoved(true);
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível remover usuário' });
    }

    setFormLoading(false);
  };

  return (
    <UsersContainer>
      {formLoading ? <Loading /> : <></>}
      {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
      {isRemoved ? (
        <>
          <Row>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/usuarios');
                  }}
                  type="button"
                >
                  VOLTAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </>
      ) : (
        <>
          {user !== undefined ? (
            <>
              <UsersRemoveLabel>
                <b>ID:</b> {user.id}
              </UsersRemoveLabel>
              <UsersRemoveLabel>
                <b>Nome:</b> {user.fullname}
              </UsersRemoveLabel>
              <UsersRemoveLabel>
                <b>Data de Nascimento:</b> {user.birthdate}
              </UsersRemoveLabel>
              <UsersRemoveLabel>
                <b>Sexo:</b> {user.gender}
              </UsersRemoveLabel>
              <UsersRemoveLabel>
                <b>Telefone:</b> {user.phone}
              </UsersRemoveLabel>
              <UsersRemoveLabel>
                <b>Telefone Residencial:</b> {user.homePhone}
              </UsersRemoveLabel>
              <UsersRemoveLabel>
                <b>Telefone Comercial:</b> {user.workPhone}
              </UsersRemoveLabel>
              <UsersRemoveLabel>
                <b>Documento:</b> {user.document}
              </UsersRemoveLabel>
              <UsersRemoveLabel>
                <b>E-mail:</b> {user.email}
              </UsersRemoveLabel>
              <Row>
                <Column xl={2}>
                  <CustomButtom background={PrimaryTheme.background.secondary}>
                    <button
                      onClick={() => {
                        navigate('/usuarios');
                      }}
                      type="button"
                    >
                      CANCELAR
                    </button>
                  </CustomButtom>
                </Column>
                <Column xl={2}>
                  <CustomButtom background={ButtonTheme.background.error}>
                    <button onClick={submitForm} type="button">
                      CONFIRMAR EXCLUSÃO
                    </button>
                  </CustomButtom>
                </Column>
              </Row>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </UsersContainer>
  );
};

export default DeleteUser;
