import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomButtom from '../../components/button';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import { useAuth } from '../../hooks/useAuth';
import { type ReclamationResponse } from '../../interfaces/reclamation';
import { ReclamationsContainer, ReclamationsRemoveLabel } from '../../styles/pages/reclamations';

const DeleteReclamation: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [reclamation, setReclamation] = useState<ReclamationResponse>();
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [isRemoved, setIsRemoved] = useState(false);

  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    ApplicationApi.get<ReclamationResponse>(`reclamacoes/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setReclamation(data);
        setFormLoading(false);
      })
      .catch(() => {
        setFormLoading(false);
        setAlerts({ type: AlertTypes.ERROR, message: 'Reclamação não encontrada' });
      });
  }, []);

  const submitForm = async (): Promise<void> => {
    setFormLoading(true);

    try {
      await ApplicationApi.delete(`reclamacoes/${id}`, { headers: { Authorization: `Bearer ${token}` } });

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Reclamação removida com sucesso' });
      setIsRemoved(true);
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível remover reclamação' });
    }

    setFormLoading(false);
  };

  return (
    <ReclamationsContainer>
      {formLoading ? <Loading /> : <></>}
      {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
      {isRemoved ? (
        <>
          <Row>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/reclamacoes');
                  }}
                  type="button"
                >
                  VOLTAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </>
      ) : (
        <>
          {reclamation !== undefined ? (
            <>
              <ReclamationsRemoveLabel>
                <b>ID:</b> {reclamation.id}
              </ReclamationsRemoveLabel>
              <ReclamationsRemoveLabel>
                <b>Nome:</b> {reclamation.name}
              </ReclamationsRemoveLabel>
              <ReclamationsRemoveLabel>
                <b>E-mail:</b> {reclamation.email}
              </ReclamationsRemoveLabel>
              <ReclamationsRemoveLabel>
                <b>Telefone:</b> {reclamation.phone ?? '-'}
              </ReclamationsRemoveLabel>
              <ReclamationsRemoveLabel>
                <b>Assunto:</b> {reclamation.subject}
              </ReclamationsRemoveLabel>
              <ReclamationsRemoveLabel>
                <b>Detalhes:</b> {reclamation.details}
              </ReclamationsRemoveLabel>
              <Row>
                <Column xl={2}>
                  <CustomButtom background={PrimaryTheme.background.secondary}>
                    <button
                      onClick={() => {
                        navigate('/reclamacoes');
                      }}
                      type="button"
                    >
                      CANCELAR
                    </button>
                  </CustomButtom>
                </Column>
                <Column xl={2}>
                  <CustomButtom background={ButtonTheme.background.error}>
                    <button onClick={submitForm} type="button">
                      CONFIRMAR EXCLUSÃO
                    </button>
                  </CustomButtom>
                </Column>
              </Row>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </ReclamationsContainer>
  );
};

export default DeleteReclamation;
