import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import { FaPen, FaTrash } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import CustomTextarea from '../../components/textarea';
import Fancybox from '../../components/fancybox';
import { FancyboxFooter } from '../../styles/components/fancybox';
import { useAuth } from '../../hooks/useAuth';
import { type ProductResponse } from '../products/create';
import Products from '../../components/productsOffer';
import { MdOutlineContentCopy } from 'react-icons/md';
import { OffersContainer } from '../../styles/pages/offers';
import { AiOutlineCalendar } from 'react-icons/ai';
import InputMask from 'react-input-mask';
import { formatDateToUS } from '../../helpers/dateFormat';

export interface OfferResponse {
  id: number;
  name: string;
  description: string | null;
  expirationDate: string;
  offerProducts: Array<{
    promotionId: number;
    productId: number;
    price: string;
    quantity: number;
    products: ProductResponse;
  }>;
  updatedAt: Date;
  createdAt: Date;
}

export interface OfferBody {
  name: string;
  description: string | null;
  expirationDate: string;
  products: Array<{ productId: number; price: string }>;
}

const bodyDefaultValue = {
  name: '',
  description: '',
  expirationDate: '',
  products: [],
};

const Offers: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [offers, setOffers] = useState<OfferResponse[]>([]);

  const [productsFancybox, setProductsFancybox] = useState(false);
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const [products, setProducts] = useState<ProductResponse[]>();
  const [body, setBody] = useState<OfferBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<OfferResponse[]>('ofertas', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setOffers(data);
        setTableLoading(false);
      })
      .catch(() => {});
  }, []);

  const resetFormFields = (): void => {
    setBody(bodyDefaultValue);
    setProducts(undefined);
  };

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      const { data } = await ApplicationApi.post<OfferResponse>(
        'ofertas',
        {
          ...body,
          products: products?.map(product => ({
            productId: product.id,
            price: product.price,
            quantity: product.quantity ?? 1,
          })),
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados cadastrados com sucesso!' });
      setOffers(offer => [...offer, data]);
      resetFormFields();
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível cadastrar oferta' });
    }

    setFormLoading(false);
  };

  return (
    <>
      <Fancybox active={productsFancybox}>
        <Products setState={setProducts} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setProductsFancybox(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setProductsFancybox(false);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <OffersContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Nome">
                <input
                  value={body.name}
                  onChange={e => {
                    setBody({ ...body, name: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Vencimento" icon={<AiOutlineCalendar />}>
                <InputMask
                  mask="99/99/9999 99:99"
                  onChange={e => {
                    setBody({ ...body, expirationDate: formatDateToUS(e.target.value, true) });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Produtos">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setProductsFancybox(true);
                    }}
                  >
                    <MdOutlineContentCopy />{' '}
                    {products !== undefined && products?.length > 0
                      ? `${products?.length} Produto(s) selecionado(s)`
                      : 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={12}>
              <CustomTextarea marginBottom={true} label="Descrição">
                <textarea
                  value={body.description ?? ''}
                  onChange={e => {
                    setBody({ ...body, description: e.target.value });
                  }}
                />
              </CustomTextarea>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button onClick={resetFormFields} type="reset">
                  LIMPAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </OffersContainer>
      <OffersContainer>
        {tableLoading ? <Loading /> : <></>}
        <Datatable
          columns={[
            { field: 'id', title: 'ID', width: 80 },
            { field: 'name', title: 'TÍTULO' },
            { field: 'createdAt', title: 'CADASTRO', width: 200 },
            { field: 'edit', title: 'EDITAR', width: 80 },
            { field: 'remove', title: 'EXCLUIR', width: 80 },
          ]}
          data={offers.map(offer => ({
            id: offer.id,
            name: offer.name,
            createdAt: new Date(offer.createdAt).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }),
            edit: (
              <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
                <button
                  onClick={() => {
                    navigate(`${offer.id}`);
                  }}
                >
                  <FaPen />
                </button>
              </CustomButtom>
            ),
            remove: (
              <CustomButtom background={ButtonTheme.background.error} small>
                <button
                  onClick={() => {
                    navigate(`remover/${offer.id}`);
                  }}
                >
                  <FaTrash />
                </button>
              </CustomButtom>
            ),
          }))}
        />
      </OffersContainer>
    </>
  );
};

export default Offers;
