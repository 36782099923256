import React, { useState, type ReactNode, useEffect } from 'react';
import { ReclamationsContainer } from '../../styles/pages/reclamations';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import ApplicationApi from '../../api/config';
import { useAuth } from '../../hooks/useAuth';
import { type ReclamationResponse } from '../../interfaces/reclamation';
import CustomButtom from '../../components/button';
import { ButtonTheme } from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaTrash } from 'react-icons/fa6';

export default function Reclamations(): ReactNode {
  const { token } = useAuth();
  const navigate = useNavigate();

  const [tableLoading, setTableLoading] = useState(true);
  const [reclamations, setReclamations] = useState<ReclamationResponse[]>([]);

  useEffect(() => {
    const getReclamations = async (): Promise<void> => {
      setTableLoading(true);

      try {
        const { data } = await ApplicationApi.get<ReclamationResponse[]>('reclamacoes', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setReclamations(data);
      } catch {}

      setTableLoading(false);
    };

    getReclamations().catch(() => {});
  }, []);

  return (
    <ReclamationsContainer>
      {tableLoading ? <Loading /> : <></>}
      <Datatable
        columns={[
          { field: 'id', title: 'ID', width: 80 },
          { field: 'name', title: 'NOME' },
          { field: 'email', title: 'E-MAIL' },
          { field: 'status', title: 'STATUS' },
          { field: 'createdAt', title: 'CADASTRO', width: 200 },
          { field: 'view', title: 'VISUALIZAR', width: 80 },
          { field: 'remove', title: 'EXCLUIR', width: 80 },
        ]}
        data={reclamations.map(reclamation => ({
          id: reclamation.id,
          name: reclamation.name,
          email: reclamation.email,
          status: reclamation.reclamationStatus.name,
          createdAt: new Date(reclamation.createdAt).toLocaleDateString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }),
          view: (
            <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
              <button
                onClick={() => {
                  navigate(`${reclamation.id}`);
                }}
              >
                <FaEye />
              </button>
            </CustomButtom>
          ),
          remove: (
            <CustomButtom background={ButtonTheme.background.error} small>
              <button
                onClick={() => {
                  navigate(`remover/${reclamation.id}`);
                }}
              >
                <FaTrash />
              </button>
            </CustomButtom>
          ),
        }))}
      />
    </ReclamationsContainer>
  );
}
