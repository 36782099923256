import styled from 'styled-components';

interface CustomSelectContainerProps {
  marginBottom?: boolean;
}

export const CustomSelectContainer = styled.div<CustomSelectContainerProps>`
  margin-bottom: ${({ marginBottom }) => (marginBottom ?? false ? '16px' : '0')};
`;

export const CustomSelectLabel = styled.p`
  font-weight: 300;
  font-size: 0.8rem;
  margin-bottom: 5px;
`;
