import React, { type ReactElement } from 'react';
import { CustomTextareaContainer, CustomTextareaLabel } from '../styles/components/textarea';

interface CustomTextareaProps {
  children: ReactElement;
  marginBottom?: boolean;
  label?: string;
}

const CustomTextarea: React.FC<CustomTextareaProps> = ({ children, marginBottom, label }) => {
  return (
    <>
      {label != null ? <CustomTextareaLabel>{label}</CustomTextareaLabel> : <></>}
      <CustomTextareaContainer marginBottom={marginBottom}>{children}</CustomTextareaContainer>
    </>
  );
};

export default CustomTextarea;
