import React, { type ReactElement } from 'react';
import { FancyboxContainer, FancyboxContentContainer } from '../styles/components/fancybox';

interface FancyboxProps {
  children: ReactElement | ReactElement[] | string;
  active: boolean;
}

const Fancybox: React.FC<FancyboxProps> = ({ children, active }) => {
  return (
    <FancyboxContainer active={active}>
      <FancyboxContentContainer>{children}</FancyboxContentContainer>
    </FancyboxContainer>
  );
};

export default Fancybox;
