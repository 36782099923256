import styled from 'styled-components';

interface IconBoxProps {
  active: boolean;
}

export const IconContainer = styled.div`
  width: calc(100vw - 1000px);
  height: 380px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding: 12px;
  background: ${({ theme }) => theme.background.third};
`;

export const IconContentContainer = styled.div`
  gap: 6px;
  display: flex;
  flex-wrap: wrap;
`;

export const IconHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 66px;

  h1 {
    font-size: 0.9rem;
  }
`;

export const IconBox = styled.div<IconBoxProps>`
  background: ${({ theme, active }) => (active ? theme.background.primary : '#fff')};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;

  svg {
    font-size: 1rem;
    color: ${({ theme, active }) => (active ? theme.color.primary : theme.color.secondary)};
  }
`;
