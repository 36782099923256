import React, { useEffect, useState } from 'react';
import {
  ProductsHeader,
  ProductsContainer,
  ProductItem,
  ProductItemBox,
  ProductPriceContainer,
  ProductSortContainer,
} from '../styles/components/products';
import CustomInput from './input';
import { type ProductResponse } from '../pages/products/create';
import ApplicationApi from '../api/config';
import Loading from './loading';
import Alert, { AlertTypes } from './alerts';
import Row from './row';
import Column from './column';
import { FaRegCircle } from 'react-icons/fa6';
import { formatDecimal, formatMoney } from '../helpers/numberMethods';
import { FaCheckCircle, FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa';
import CurrencyInput from 'react-currency-input';

interface ProductsProps {
  setState: React.Dispatch<React.SetStateAction<ProductResponse[] | undefined>>;
  currentProducts?: ProductResponse[];
  price?: boolean;
  quantity?: boolean;
}

const Products: React.FC<ProductsProps> = ({ setState, currentProducts }) => {
  console.log(currentProducts);
  const [loading, setLoading] = useState<boolean>(true);

  const [sortedBy, setSortedBy] = useState('ASC');

  const [products, setProducts] = useState<ProductResponse[]>([]);
  const [searchedProducts, setSearchedProducts] = useState<ProductResponse[]>([]);

  const [selectedProducts, setSelectedProducts] = useState<ProductResponse[]>(currentProducts ?? []);

  const [productsCustomPrice, setProductsCustomPrice] = useState<
    Array<{ productId: number; newPrice: string; quantity: number }>
  >(
    currentProducts?.map(product => ({
      newPrice: product.price,
      productId: product.id,
      quantity: product?.quantity ?? 1,
    })) ?? [],
  );

  useEffect(() => {
    ApplicationApi.get<ProductResponse[]>('produtos/home')
      .then(({ data }) => {
        setProducts(data);
        setSearchedProducts(
          data.sort((a, b) => {
            if (sortedBy === 'ASC') {
              return a.name > b.name ? 1 : -1;
            } else {
              return a.name < b.name ? 1 : -1;
            }
          }),
        );
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    setSearchedProducts(data =>
      data.sort((a, b) => {
        if (sortedBy === 'ASC') {
          return a.name > b.name ? 1 : -1;
        } else {
          return a.name < b.name ? 1 : -1;
        }
      }),
    );
  }, [sortedBy]);

  useEffect(() => {
    if (productsCustomPrice.length > 0) {
      const array: ProductResponse[] = [];

      selectedProducts.forEach(item => {
        if (productsCustomPrice.some(product => product.productId === item.id)) {
          array.push({
            ...item,
            price: productsCustomPrice.filter(data => data.productId === item.id)[0].newPrice,
            quantity: productsCustomPrice.filter(data => data.productId === item.id)[0].quantity,
          });
        } else {
          array.push({
            ...item,
            price: item.price,
          });
        }
      });

      setState(array);
    } else {
      setState(selectedProducts);
    }
  }, [selectedProducts, productsCustomPrice]);

  const searchProducts = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value !== null) {
      const result = products.filter(o =>
        Object.keys(o).some(k =>
          String(o[k as keyof typeof o])
            .toLowerCase()
            .includes(e.target.value.toLowerCase()),
        ),
      );

      setSearchedProducts(result);
    }
  };

  const addCustomPrice = (productId: number, newPrice: string): void => {
    const countProductsWithCustomPrice = productsCustomPrice.some(item => item.productId === productId);

    if (countProductsWithCustomPrice) {
      const arrayWithoutProduct = productsCustomPrice.filter(item => item.productId !== productId);
      const productInArray = productsCustomPrice.filter(item => item.productId === productId)[0];

      setProductsCustomPrice([
        ...arrayWithoutProduct,
        {
          ...productInArray,
          newPrice: newPrice !== '' ? formatDecimal(newPrice).toFixed(2) : '0.00',
        },
      ]);
    } else {
      setProductsCustomPrice([
        ...productsCustomPrice,
        {
          productId,
          newPrice: newPrice !== '' ? formatDecimal(newPrice).toFixed(2) : '0.00',
          quantity: 1,
        },
      ]);
    }
  };

  const addCustomQuantity = (productId: number, quantity: number): void => {
    const countProductsWithCustomPrice = productsCustomPrice.some(item => item.productId === productId);
    const productInSelectedArray = selectedProducts.filter(item => item.id === productId)[0];

    if (countProductsWithCustomPrice) {
      const arrayWithoutProduct = productsCustomPrice.filter(item => item.productId !== productId);
      const productInArray = productsCustomPrice.filter(item => item.productId === productId)[0];

      setProductsCustomPrice([
        ...arrayWithoutProduct,
        {
          ...productInArray,
          quantity,
        },
      ]);
    } else {
      setProductsCustomPrice([
        ...productsCustomPrice,
        {
          productId,
          newPrice: productInSelectedArray.price,
          quantity,
        },
      ]);
    }
  };

  return (
    <>
      <ProductsHeader>
        <h1>PRODUTOS</h1>
        <CustomInput>
          <input placeholder="Pesquisar produtos" onChange={searchProducts} />
        </CustomInput>
      </ProductsHeader>
      <ProductsContainer>
        {loading ? <Loading /> : <></>}
        {searchedProducts.length > 0 ? (
          <Row>
            <>
              <Column xl={12}>
                <ProductSortContainer>
                  {sortedBy === 'DESC' ? (
                    <FaSortAlphaDown
                      onClick={() => {
                        setSortedBy('ASC');
                      }}
                    />
                  ) : (
                    <FaSortAlphaDownAlt
                      onClick={() => {
                        setSortedBy('DESC');
                      }}
                    />
                  )}
                </ProductSortContainer>
              </Column>
              {searchedProducts.map((product, i) => (
                <>
                  {selectedProducts.some(item => item.id === product.id) ? (
                    <Column xl={4} key={i}>
                      <ProductItemBox>
                        <ProductItem
                          onClick={() => {
                            setSelectedProducts(products => products.filter(item => product.id !== item.id));
                          }}
                        >
                          <div>
                            <h1>{product.name.substring(0, 30)}...</h1>
                            <h2>R$ {formatMoney(product.price)}</h2>
                          </div>
                          <FaCheckCircle />
                        </ProductItem>
                        <ProductPriceContainer>
                          <p>Preço</p>
                          <CustomInput>
                            <CurrencyInput
                              onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
                                addCustomPrice(product.id, e.target.value);
                              }}
                              value={
                                productsCustomPrice.some(item => item.productId === product.id)
                                  ? formatMoney(
                                      productsCustomPrice.filter(item => item.productId === product.id)[0].newPrice,
                                    )
                                  : formatMoney(product.price)
                              }
                              decimalSeparator=","
                              thousandSeparator="."
                            />
                          </CustomInput>
                        </ProductPriceContainer>
                        <ProductPriceContainer>
                          <p>Quantidade</p>
                          <CustomInput>
                            <input
                              onChange={e => {
                                addCustomQuantity(product.id, parseInt(e.target.value));
                              }}
                              value={
                                productsCustomPrice.filter(item => item.productId === product.id)[0]?.quantity ?? 1
                              }
                            />
                          </CustomInput>
                        </ProductPriceContainer>
                      </ProductItemBox>
                    </Column>
                  ) : (
                    <Column xl={4} key={i}>
                      <ProductItem
                        onClick={() => {
                          setSelectedProducts(products => [...products, { ...product, quantity: 1 }]);
                        }}
                      >
                        <div>
                          <h1>{product.name.substring(0, 30)}...</h1>
                          <h2>R$ {formatMoney(product.price)}</h2>
                        </div>
                        <FaRegCircle />
                      </ProductItem>
                    </Column>
                  )}
                </>
              ))}
            </>
          </Row>
        ) : (
          <Alert type={AlertTypes.WARNING}>Nenhum produto encontrado</Alert>
        )}
      </ProductsContainer>
    </>
  );
};

export default Products;
