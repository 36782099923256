// @ts-nocheck
import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { type OrderResponse } from './list';
import CustomSelect from '../../components/select';
import {
  OrderCheckoutContainer,
  OrderDeliveryContainer,
  OrderPaymentContainer,
  OrderProductContainer,
  OrderProductImageContainer,
  OrderProductInfoContainer,
  OrderUserContainer,
  OrdersContainer,
} from '../../styles/pages/orders';
import { type ProductResponse } from '../products/create';
import { type UserPaymentResponse, type UserAddressResponse, type UserResponse } from '../users/list';
import { type CuponResponse } from '../cupons/create';
import { BsCurrencyDollar } from 'react-icons/bs';
import { formatMoney } from '../../helpers/numberMethods';
import { useAuth } from '../../hooks/useAuth';
import { type FastDeliveryResponse } from '../fastDeliveries/create';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import { formatDateToBR } from '../../helpers/dateFormat';
import { DeliveryTypes } from '../../enums/delivery';
import { TbReportMoney, TbTruckDelivery, TbUser } from 'react-icons/tb';
import { PaymentTypes } from '../../enums/payment';
import { OrderStatus } from '../../enums/order';
import JsPDF from 'jspdf';

export interface OrderBody {
  code: string;
  orderId: string | null;
  amount: string;
  subTotal: string;
  statusId: number;
  notificationUrl: string;
  users?: UserResponse;
  discountAmount?: string | null;
  discountCoupons?: CuponResponse | null;
  orderProducts?: Array<{
    orderId: number;
    productId: number;
    products: ProductResponse;
    quantity: number;
  }>;
  orderPayments?: {
    id?: number;
    chargeId?: string | null;
    orderId?: number;
    paymentTypeId?: number;
    paymentsTypes?: { id: number; name: string; description: string | null };
    userPaymentId?: number | null;
    userPayments?: UserPaymentResponse | null;
    qrCode?: string | null;
    qrLink?: string | null;
    ticketCode?: string | null;
    ticketLink?: string | null;
    observations?: string | null;
  } | null;
  orderStatus?: OrderStatusResponse;
  orderDeliveries?: {
    id?: number;
    chargeId?: string | null;
    orderId?: number;
    deliveryTypeId?: number;
    deliveryTypes?: { id: number; name: string; description: string };
    fastDeliveryId?: number | null;
    fastDeliveries?: FastDeliveryResponse | null;
    company?: string | null;
    deliveryTimeMin?: number | null;
    deliveryTimeMax?: number | null;
    price?: string;
    userAddressId?: number;
    userAddresses?: UserAddressResponse | null;
    trackingCode?: string | null;
    observations?: string | null;
  };
}

interface OrderStatusResponse {
  id: number;
  name: string;
  description: string | null;
}

const bodyDefaultValue = {
  code: '',
  amount: '',
  subTotal: '',
  statusId: 1,
  notificationUrl: '',
  orderId: null,
};

const Order: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [alerts, setAlerts] = useState<AlertsProps>();

  const [status, setStatus] = useState<OrderStatusResponse[]>([]);

  const [formLoading, setFormLoading] = useState(true);

  const [body, setBody] = useState<OrderBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<OrderResponse>(`pedidos/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setBody({
          ...data,
          amount: formatMoney(data.amount),
          subTotal: formatMoney(data.subTotal),
          orderDeliveries: { ...data.orderDeliveries, price: data.orderDeliveries?.price ?? '0.00' },
        });
        setFormLoading(false);
      })
      .catch(() => {
        setAlerts({ message: 'Não foi possível recuperar pedido', type: AlertTypes.WARNING });
        setFormLoading(false);
      });

    ApplicationApi.get<OrderStatusResponse[]>(`pedidos/status`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setStatus(data);
      })
      .catch(() => {});
  }, [id]);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch<OrderResponse>(
        `pedidos/${id}/status`,
        { statusId: body.statusId },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados atualizados com sucesso!' });
    } catch {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar status do pedido, verifique as informações inseridas e tente novamente.',
      });
    }

    setFormLoading(false);
  };

  const handleGeneratePdf = (): void => {
    setFormLoading(true);
    const doc = new JsPDF('p', 'px', [595.28, 841.89], false);

    const idUsuario = 1;
    // 595 x 842

    doc.setFontSize(14);
    doc.setDrawColor(0, 0, 0);
    doc.setCharSpace(0);
    doc
      .html(
        `
        <div style="margin: 12px;">
        <table style="width: 571px;margin-bottom: 14px">
          ${body.orderProducts?.map(
            orderProduct => `<tr>
            <td style="width: 40px;padding-bottom: 8px">
              <div style="width: 40px;height: 40px;border: 1px solid #eee;border-radius: 6px;padding: 8px">
                <img src="${
                  orderProduct.products.productImages !== null
                    ? orderProduct.products.productImages[0].uploads.origin
                    : ''
                }" style="width: 35px;height: auto;" />
              </div>
            </td>
            <td style="padding-left: 8px;padding-bottom: 8px">
              <h1 style="margin:0;font-size: 8px;margin-bottom: 3px">${orderProduct.products.name}</h1>
              <h2 style="margin:0;font-size: 8px;margin-bottom: 3px">${orderProduct.quantity} Unidade(s)</h2>
              <h3 style="margin:0;font-size: 8px">${orderProduct.products.price}</h3>
            </td>
          </tr>`,
          )}
        </table>
        <div>
          <table style="width: 571px;margin-bottom: 24px;border: 1px solid #111;border-radius: 6px;overflow: hidden;padding: 12px">
            <tr>
              <td colspan="2" style="text-align: left;">
                <h1 style="margin: 0 0 12px 0;font-size: 10px;color: ${
                  PrimaryTheme.background.fifth
                };font-weight: 800">Informações do usuário</h1>
              </td>
            </tr>
            <tr>
              <td style="width:285px">
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>ID:</b>
                  <span>${body.users?.id}</span>
                </p>
              </td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>CPF:</b>
                  <span>${body.users?.document}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Nome Completo:</b>
                  <span>${body.users?.fullname}</span>
                </p>
              </td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Data de Nascimento:</b>
                  <span>${formatDateToBR(body.users?.birthdate, true, true)}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Sexo:</b>
                  <span>${body.users?.gender}</span>
                </p>
              </td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>E-mail:</b>
                  <span>${body.users?.email}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Celular:</b>
                  <span>${body.users?.phone}</span>
                </p>
              </td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Telefone Residencial:</b>
                  <span>${body.users?.homePhone}</span>
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <table style="width: 571px;margin-bottom: 24px;">
            <tr>
              <td colspan="2" style="text-align: left;">
                <h1 style="margin: 0 0 12px 0;font-size: 10px;color: ${
                  PrimaryTheme.background.fifth
                };font-weight: 800">Informações de Entrega</h1>
              </td>
            </tr>

            <!--

              TRANSPORTADORA
              <tr>
              <td style="width:285px">
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Tipo de Entrega:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Endereço:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Compania:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Complemento:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Tempo de Entrega:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Bairro:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Preço:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>CEP:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Cidade:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Estado:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            -->

            <!--

            ENTREGA RAPIDA

            <tr>
              <td style="width:285px">
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Tipo de Entrega:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Endereço:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Tempo de Entrega:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Complemento:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Preço:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Bairro:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>CEP:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Cidade:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Estado:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            -->

            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Tipo de Entrega:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Observações:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <table style="width: 571px;margin-bottom: 24px;">
            <tr>
              <td colspan="2" style="text-align: left;">
                <h1 style="margin: 0 0 12px 0;font-size: 10px;color: ${
                  PrimaryTheme.background.fifth
                };font-weight: 800">Informações de Pagamento</h1>
              </td>
            </tr>
            <!--

            <tr>
              <td style="width:285px">
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Tipo de Pagamento:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Nome:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Nº do Cartão:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Data de Vencimento:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>

            -->

            <!--

            <tr>
              <td style="width:285px">
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Tipo de Pagamento:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Código de Barras:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Link para impressão:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>

            -->

            <!--

            <tr>
              <td style="width:285px">
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Tipo de Pagamento:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>QR Code:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Link para impressão:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>

            -->

            <tr>
              <td style="width:285px">
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Tipo de Pagamento:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Disponibilidade:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0 0 4px 0;font-size: 8px">
                  <b>Observações:</b>
                  <span>${idUsuario}</span>
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>
        `,
        {
          async callback(doc: any) {
            await doc.save('document');
            setFormLoading(false);
          },
        },
      )
      .then(() => {
        setFormLoading(false);
      })
      .catch(() => {});
  };

  return (
    <>
      <OrdersContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Código">
                <input value={body.code} readOnly required />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Total" icon={<BsCurrencyDollar />}>
                <CurrencyInput value={body.amount} decimalSeparator="," thousandSeparator="." readOnly />
              </CustomInput>
            </Column>
            <Column xl={4}>
              {body.orderStatus !== undefined ? (
                <CustomSelect label="Status">
                  {body.orderStatus.id !== OrderStatus.ENTREGUE ? (
                    <Select
                      onChange={e => {
                        setBody({ ...body, statusId: e?.value ?? 1 });
                      }}
                      defaultValue={{ label: body.orderStatus.name, value: body.orderStatus.id }}
                      styles={{
                        control: baseStyles => ({
                          ...baseStyles,
                          border: 0,
                          borderRadius: '6px',
                          overflow: 'hidden',
                          ':focus': { border: 0, outline: 0 },
                          ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                          background: PrimaryTheme.background.third,
                          color: PrimaryTheme.background.fifth,
                          fontSize: '.9rem',
                          height: '42px',
                        }),
                      }}
                      placeholder="Selecione"
                      options={[
                        { label: 'Criado', value: OrderStatus.CRIADO },
                        { label: 'Aguardando pagamento', value: OrderStatus.AGUARDANDO_PAGAMENTO },
                        { label: 'Em análise', value: OrderStatus.EM_ANALISE },
                        { label: 'Pagamento confirmado', value: OrderStatus.PAGAMENTO_CONFIRMADO },
                        { label: 'Aceito', value: OrderStatus.ACEITO },
                        { label: 'Em separação', value: OrderStatus.EM_SEPARAÇÃO },
                        { label: 'Enviado', value: OrderStatus.ENVIADO },
                        { label: 'Aguardando coleta', value: OrderStatus.AGUARDANDO_COLETA },
                        { label: 'Entregue', value: OrderStatus.ENTREGUE },
                        { label: 'Pagamento recusado', value: OrderStatus.PAGAMENTO_RECUSADO },
                        { label: 'Aguardando estorno', value: OrderStatus.AGUARDANDO_ESTORNO },
                        { label: 'Cancelado', value: OrderStatus.CANCELADO },
                        { label: 'Erro ao criar pedido no PagSeguro', value: OrderStatus.ERRO_PAGASEGURO },
                      ]}
                    />
                  ) : (
                    <Select
                      defaultValue={{ label: body.orderStatus.name, value: body.orderStatus.id }}
                      styles={{
                        control: baseStyles => ({
                          ...baseStyles,
                          border: 0,
                          borderRadius: '6px',
                          overflow: 'hidden',
                          ':focus': { border: 0, outline: 0 },
                          ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                          background: PrimaryTheme.background.third,
                          color: PrimaryTheme.background.fifth,
                          fontSize: '.9rem',
                          height: '42px',
                        }),
                      }}
                      placeholder="Selecione"
                      isDisabled={true}
                      options={[
                        {
                          label: status.filter(sts => sts.id === OrderStatus.ENTREGUE)[0].name,
                          value: status.filter(sts => sts.id === OrderStatus.ENTREGUE)[0].id,
                        },
                      ]}
                    />
                  )}
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="PagSeguro - ID do pedido">
                <input value={body.orderId ?? ''} readOnly required />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="PagSeguro - ID da transação">
                <input value={body.orderPayments?.chargeId ?? ''} readOnly required />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="PagSeguro - URL de notificação">
                <input value={body.notificationUrl} readOnly required />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/pedidos');
                  }}
                  type="button"
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#0dcaf0">
                <button type="button" onClick={handleGeneratePdf}>
                  EXPORTAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </OrdersContainer>
      <OrdersContainer>
        {body.orderProducts?.map((orderProduct, i) => (
          <OrderProductContainer key={i}>
            <OrderProductImageContainer>
              {orderProduct.products.productImages.length > 0 ? (
                <img src={orderProduct.products.productImages[0].uploads.origin} />
              ) : (
                <MdOutlineImageNotSupported />
              )}
            </OrderProductImageContainer>
            <OrderProductInfoContainer>
              <h2>
                {orderProduct.products.ean} - {orderProduct.products.name}
              </h2>
              <h3>{orderProduct.quantity} unidade(s)</h3>
              <h4>R$ {formatMoney(orderProduct.products.newPrice ?? orderProduct.products.price)}</h4>
            </OrderProductInfoContainer>
          </OrderProductContainer>
        ))}
        <OrderUserContainer>
          <h1>
            <TbUser /> Informações do usuário
          </h1>
          <Row>
            <Column xl={6}>
              <h2>
                <b>ID: </b>
                {body.users?.id}
              </h2>
            </Column>
            <Column xl={6}>
              <h2>
                <b>CPF: </b>
                {body.users?.document}
              </h2>
            </Column>
            <Column xl={6}>
              <h2>
                <b>Nome completo: </b>
                {body.users?.fullname}
              </h2>
            </Column>
            <Column xl={6}>
              <h2>
                <b>Data de nascimento: </b>
                {formatDateToBR(body.users?.birthdate ?? '', true, true)}
              </h2>
            </Column>
            <Column xl={6}>
              <h2>
                <b>Sexo: </b>
                {body.users?.gender}
              </h2>
            </Column>
            <Column xl={6}>
              <h2>
                <b>E-mail: </b>
                {body.users?.email}
              </h2>
            </Column>
            <Column xl={6}>
              <h2>
                <b>Celular: </b>
                {body.users?.phone}
              </h2>
            </Column>
            <Column xl={6}>
              <h2>
                <b>Telefone Residencial: </b>
                {body.users?.homePhone ?? '-'}
              </h2>
            </Column>
          </Row>
        </OrderUserContainer>
        <OrderDeliveryContainer>
          <h1>
            <TbTruckDelivery /> Informações de entrega
          </h1>
          {body.orderDeliveries?.deliveryTypes?.id === DeliveryTypes.TRANSPORTADORA ? (
            <Row>
              <Column xl={6}>
                <h2>
                  <b>Tipo de entrega:</b> {body.orderDeliveries.deliveryTypes.name}
                </h2>
                <h2>
                  <b>Compania:</b> {body.orderDeliveries.company}
                </h2>
                <h2>
                  <b>Tempo de entrega:</b> de {body.orderDeliveries.deliveryTimeMin} a{' '}
                  {body.orderDeliveries.deliveryTimeMax} dia(s) úteis
                </h2>
                <h2>
                  <b>Preço:</b> R$ {formatMoney(body.orderDeliveries.price ?? '')}
                </h2>
              </Column>
              <Column xl={6}>
                <h2>
                  <b>Endereço:</b> {body.orderDeliveries.userAddresses?.address},{' '}
                  {body.orderDeliveries.userAddresses?.number}
                </h2>
                <h2>
                  <b>Complemento:</b> {body.orderDeliveries.userAddresses?.complement ?? '-'}
                </h2>
                <h2>
                  <b>Bairro: </b> {body.orderDeliveries.userAddresses?.district}
                </h2>
                <h2>
                  <b>CEP: </b> {body.orderDeliveries.userAddresses?.zipCode}
                </h2>
                <h2>
                  <b>Cidade: </b> {body.orderDeliveries.userAddresses?.city}
                </h2>
                <h2>
                  <b>Estado: </b> {body.orderDeliveries.userAddresses?.state}
                </h2>
              </Column>
            </Row>
          ) : (
            <></>
          )}
          {body.orderDeliveries?.deliveryTypes?.id === DeliveryTypes.ENTREGA_RAPIDA ? (
            <Row>
              <Column xl={6}>
                <h2>
                  <b>Tipo de entrega:</b> {body.orderDeliveries.deliveryTypes.name}
                </h2>
                <h2>
                  <b>Tempo de entrega:</b> {body.orderDeliveries.fastDeliveries?.deliveryTime}
                </h2>
                <h2>
                  <b>Preço:</b> R$ {formatMoney(body.orderDeliveries.fastDeliveries?.price ?? '')}
                </h2>
              </Column>
              <Column xl={6}>
                <h2>
                  <b>Endereço:</b> {body.orderDeliveries.userAddresses?.address},{' '}
                  {body.orderDeliveries.userAddresses?.number}
                </h2>
                <h2>
                  <b>Complemento:</b> {body.orderDeliveries.userAddresses?.complement ?? '-'}
                </h2>
                <h2>
                  <b>Bairro: </b> {body.orderDeliveries.userAddresses?.district}
                </h2>
                <h2>
                  <b>CEP: </b> {body.orderDeliveries.userAddresses?.zipCode}
                </h2>
                <h2>
                  <b>Cidade: </b> {body.orderDeliveries.userAddresses?.city}
                </h2>
                <h2>
                  <b>Estado: </b> {body.orderDeliveries.userAddresses?.state}
                </h2>
              </Column>
            </Row>
          ) : (
            <></>
          )}
          {body.orderDeliveries?.deliveryTypes?.id === DeliveryTypes.RETIRADA ? (
            <>
              <h2>
                <b>Tipo de entrega:</b> {body.orderDeliveries.deliveryTypes.name}
              </h2>
              <h2>
                <b>Observações:</b> {body.orderDeliveries.observations ?? '-'}
              </h2>
            </>
          ) : (
            <></>
          )}
        </OrderDeliveryContainer>
        <OrderPaymentContainer>
          <h1>
            <TbReportMoney /> Informações de Pagamento
          </h1>
          {body.orderPayments?.paymentsTypes?.id === PaymentTypes.CARTAO_CREDITO ? (
            <>
              <h2>
                <b>Tipo de pagamento:</b> {body.orderPayments.paymentsTypes.name}
              </h2>
              <h2>
                <b>Nome:</b> {body.orderPayments.userPayments?.name}
              </h2>
              <h2>
                <b>Nº do Cartão:</b> {body.orderPayments.userPayments?.cardNumber} -{' '}
                {body.orderPayments.userPayments?.flag}
              </h2>
              <h2>
                <b>Data de vencimento:</b> {body.orderPayments.userPayments?.expirationMonth}/
                {body.orderPayments.userPayments?.expirationYear}
              </h2>
            </>
          ) : (
            <></>
          )}
          {body.orderPayments?.paymentsTypes?.id === PaymentTypes.BOLETO ? (
            <>
              <h2>
                <b>Tipo de pagamento:</b> {body.orderPayments.paymentsTypes.name}
              </h2>
              <h2>
                <b>Código de barras:</b> {body.orderPayments.ticketCode ?? 'Não disponível'}
              </h2>
              <h2>
                <b>Link impressão:</b>{' '}
                <a href={body.orderPayments.ticketLink ?? ''} target="new">
                  {body.orderPayments.ticketLink ?? 'Não disponível'}
                </a>
              </h2>
            </>
          ) : (
            <></>
          )}
          {body.orderPayments?.paymentsTypes?.id === PaymentTypes.PIX ? (
            <>
              <h2>
                <b>Tipo de pagamento:</b> {body.orderPayments.paymentsTypes.name}
              </h2>
              <h2>
                <b>QRCode:</b> {body.orderPayments.qrCode ?? 'Não disponível'}
              </h2>
              <h2>
                <b>Link impressão:</b>{' '}
                <a href={body.orderPayments.qrLink ?? ''} target="new">
                  {body.orderPayments.qrLink ?? 'Não disponível'}
                </a>
              </h2>
            </>
          ) : (
            <></>
          )}
          {body.orderPayments?.paymentsTypes?.id === PaymentTypes.ENTREGA ? (
            <>
              <h2>
                <b>Tipo de pagamento:</b> {body.orderPayments.paymentsTypes.name}
              </h2>
              <h2>
                <b>Disponibilidade:</b> 1 dia útli
              </h2>
              <h2>
                <b>Observações:</b> {body.orderPayments.observations ?? '-'}
              </h2>
            </>
          ) : (
            <></>
          )}
        </OrderPaymentContainer>
        <OrderCheckoutContainer>
          <h2>
            <b>Subtotal:</b> R$ {body.subTotal}
          </h2>
          {body.discountAmount !== undefined && body.discountAmount !== null ? (
            <h2>
              <b>Descontos:</b> - R$ {body.discountAmount}
            </h2>
          ) : (
            <></>
          )}
          <h2>
            <b>Total:</b> R$ {body.amount}
          </h2>
        </OrderCheckoutContainer>
      </OrdersContainer>
    </>
  );
};

export default Order;
