import axios from 'axios';

// const apiUrl = 'http://localhost:4000';
const apiUrl = 'https://api.drogamalu.com.br';

const ApplicationApi = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
});

export { apiUrl };

export default ApplicationApi;
