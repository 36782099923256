import React, { type ReactElement, useEffect, useState } from 'react';
import {
  DatatableFooter,
  DatatableHeader,
  DatatablePaginationContainer,
  DatatableRadioContainer,
  DatatableTable,
  DatatableTd,
  DatatableTh,
} from '../styles/components/datatable';
import CustomInput from './input';
import CustomButtom from './button';
import { FaAngleDown, FaAngleUp, FaPlus } from 'react-icons/fa6';
import { PrimaryTheme } from '../styles/theme';
import Fancybox from './fancybox';
import Export from './export';
import Row from './row';
import Column from './column';
import { FaCheckCircle } from 'react-icons/fa';
import Loading from './loading';
import Alert from './alerts';
import { type AlertsProps } from '../interfaces/global';

interface DatatableProps {
  columns: Array<{ field: string; title: string; width?: number }>;
  data: any[];
  exportPath?: string;
  pagination?: boolean;
  children?: ReactElement;
  searchElement?: ReactElement;
  excludeSelecteds?: () => void;
  clearSelecteds?: () => void;
  approveErpProducts?: () => void;
  filterByStatus?: (status: string) => void;
  toggleCheckbox?: (checked: boolean) => void;
  currentFilterStatus?: string;
}

const Datatable: React.FC<DatatableProps> = ({
  data,
  exportPath,
  columns,
  pagination,
  children,
  searchElement,
  excludeSelecteds,
  clearSelecteds,
  approveErpProducts,
  filterByStatus,
  currentFilterStatus,
  toggleCheckbox,
}) => {
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [fancybox, setFancybox] = useState(false);

  // filters
  const [limit] = useState(15);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [order, setOrder] = useState('ASC');
  const [field, setField] = useState(columns[0].field);
  const [keyword, setKeyword] = useState<string>();

  const [loading] = useState<boolean>(false);
  const [alert] = useState<AlertsProps>();

  useEffect(() => {
    let result = [];
    let searchKeyword = [];

    if (keyword != null) {
      searchKeyword = data.filter(o =>
        Object.keys(o).some(k => String(o[k]).toLowerCase().includes(keyword.toLowerCase())),
      );

      result = searchKeyword.slice((page - 1) * limit, page * limit).sort((a, b) => {
        if (field === 'id') {
          return order === 'DESC' ? parseInt(b[field]) - parseInt(a[field]) : parseInt(a[field]) - parseInt(b[field]);
        } else if (field === 'createdAt') {
          return order === 'DESC'
            ? new Date(b[field]).getTime() - new Date(a[field]).getTime()
            : new Date(a[field]).getTime() - new Date(b[field]).getTime();
        }

        const x: string = String(a[field]).toLowerCase();
        const y = String(b[field]).toLowerCase();

        if (order === 'DESC') {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    } else {
      result = data.slice((page - 1) * limit, page * limit).sort((a, b) => {
        if (field === 'id') {
          return order === 'DESC' ? parseInt(b[field]) - parseInt(a[field]) : parseInt(a[field]) - parseInt(b[field]);
        } else if (field === 'createdAt') {
          return order === 'DESC'
            ? new Date(b[field]).getTime() - new Date(a[field]).getTime()
            : new Date(a[field]).getTime() - new Date(b[field]).getTime();
        }

        const x: string = String(a[field]).toLowerCase();
        const y = String(b[field]).toLowerCase();

        if (order === 'DESC') {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    setSortedData(result);
  }, [data, limit, page, order, field, keyword]);

  useEffect(() => {
    if (data.length > 0) {
      setPages(Math.ceil(data.length / limit));
    }
  }, [data]);

  return (
    <>
      <>
        {loading ? <Loading /> : <></>}
        <Fancybox active={fancybox}>
          <Export fancyboxSetState={setFancybox} path={exportPath ?? ''} />
        </Fancybox>
        <DatatableHeader>
          {alert !== undefined ? <Alert type={alert.type}>{alert.message}</Alert> : <></>}
          <Row>
            {exportPath !== undefined ? (
              <>
                {exportPath === 'produtos' ? (
                  <>
                    {clearSelecteds !== undefined ? (
                      <Column xl={2}>
                        <CustomButtom background={PrimaryTheme.background.fifth}>
                          <button
                            onClick={() => {
                              clearSelecteds();
                            }}
                            type="button"
                          >
                            LIMPAR SELECIONADOS
                          </button>
                        </CustomButtom>
                      </Column>
                    ) : (
                      <></>
                    )}
                    {excludeSelecteds !== undefined ? (
                      <Column xl={2}>
                        <CustomButtom background={PrimaryTheme.background.fifth}>
                          <button
                            onClick={() => {
                              const confirmBox = confirm('Deseja realmente excluir produtos selecionados?');

                              if (confirmBox) {
                                excludeSelecteds();
                              }
                            }}
                            type="button"
                          >
                            <FaCheckCircle /> EXCLUIR SELECIONADOS
                          </button>
                        </CustomButtom>
                      </Column>
                    ) : (
                      <></>
                    )}
                    {approveErpProducts !== undefined ? (
                      <Column xl={2}>
                        <CustomButtom background={PrimaryTheme.background.fifth}>
                          <button
                            onClick={() => {
                              const confirmBox = confirm('Deseja realmente aprovar todos os produtos com estoque?');

                              if (confirmBox) {
                                approveErpProducts();
                              }
                            }}
                            type="button"
                          >
                            <FaCheckCircle /> EXIBIR SELECIONADOS
                          </button>
                        </CustomButtom>
                      </Column>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <Column xl={1}>
                  <CustomButtom background={PrimaryTheme.background.secondary}>
                    <button
                      onClick={() => {
                        setFancybox(true);
                      }}
                      type="button"
                    >
                      <FaPlus /> EXPORTAR
                    </button>
                  </CustomButtom>
                </Column>
              </>
            ) : (
              <></>
            )}
            <Column xl={exportPath !== undefined ? (exportPath === 'produtos' ? 5 : 11) : 12}>
              {searchElement === undefined ? (
                <CustomInput>
                  <input
                    onChange={e => {
                      setKeyword(e.target.value);
                    }}
                    type="search"
                    placeholder="Pesquisar..."
                  />
                </CustomInput>
              ) : (
                <>{searchElement}</>
              )}
            </Column>
            {filterByStatus !== undefined ? (
              <Column xl={4}>
                <DatatableRadioContainer>
                  <div>
                    <input
                      type="radio"
                      value=""
                      radioGroup="status"
                      checked={currentFilterStatus === ''}
                      onChange={e => {
                        filterByStatus(e.target.checked ? e.target.value : '');
                      }}
                    />
                    <span>Todos</span>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="1"
                      radioGroup="status"
                      checked={currentFilterStatus === '1'}
                      onChange={e => {
                        filterByStatus(e.target.checked ? e.target.value : '');
                      }}
                    />
                    <span>Disponível</span>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="2"
                      radioGroup="status"
                      checked={currentFilterStatus === '2'}
                      onChange={e => {
                        filterByStatus(e.target.checked ? e.target.value : '');
                      }}
                    />
                    <span>Indisponível</span>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="3"
                      radioGroup="status"
                      checked={currentFilterStatus === '3'}
                      onChange={e => {
                        filterByStatus(e.target.checked ? e.target.value : '');
                      }}
                    />
                    <span>Sem estoque</span>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="4"
                      radioGroup="status"
                      checked={currentFilterStatus === '4'}
                      onChange={e => {
                        filterByStatus(e.target.checked ? e.target.value : '');
                      }}
                    />
                    <span>Oculto</span>
                  </div>
                </DatatableRadioContainer>
              </Column>
            ) : (
              <></>
            )}
          </Row>
        </DatatableHeader>
      </>
      <DatatableTable>
        <tr>
          {columns.map((column, i) => (
            <>
              {column.field === 'select' ? (
                <>
                  {toggleCheckbox !== undefined ? (
                    <DatatableTh>
                      <input
                        type="checkbox"
                        onChange={e => {
                          toggleCheckbox(e.target.checked);
                        }}
                      />
                    </DatatableTh>
                  ) : (
                    <DatatableTh></DatatableTh>
                  )}
                </>
              ) : (
                <DatatableTh
                  width={column.width}
                  key={i}
                  onClick={() => {
                    setField(column.field);
                    setOrder(order === 'ASC' ? 'DESC' : 'ASC');
                  }}
                >
                  {column.title}
                  {field === column.field ? <>{order === 'ASC' ? <FaAngleDown /> : <FaAngleUp />}</> : <></>}
                </DatatableTh>
              )}
            </>
          ))}
        </tr>
        {sortedData.length > 0 ? (
          <>
            {sortedData.map((value, i) => (
              <tr key={i}>
                {columns.map((column, u) => (
                  <DatatableTd key={u}>{value[column.field]}</DatatableTd>
                ))}
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <DatatableTd colSpan={columns.length} align="center">
              Nenhum registro encontrado.
            </DatatableTd>
          </tr>
        )}
      </DatatableTable>
      {pagination !== undefined ? (
        <>
          {pagination ? (
            <DatatableFooter>
              <DatatablePaginationContainer>
                {Array.apply(null, Array(pages)).map((_, i) => {
                  const pg = i + 1;

                  return (
                    <li
                      onClick={() => {
                        setPage(pg);
                      }}
                      className={page === pg ? 'active' : ''}
                      key={i}
                    >
                      {pg}
                    </li>
                  );
                })}
              </DatatablePaginationContainer>

              <p>
                Mostrando {limit >= data.length ? data.length : limit} de {data.length} registro(s)
              </p>
            </DatatableFooter>
          ) : (
            <>{children}</>
          )}
        </>
      ) : (
        <DatatableFooter>
          <DatatablePaginationContainer>
            {Array.apply(null, Array(pages)).map((_, i) => {
              const pg = i + 1;

              return (
                <li
                  onClick={() => {
                    setPage(pg);
                  }}
                  className={page === pg ? 'active' : ''}
                  key={i}
                >
                  {pg}
                </li>
              );
            })}
          </DatatablePaginationContainer>

          <p>
            Mostrando {limit >= data.length ? data.length : limit} de {data.length} registro(s)
          </p>
        </DatatableFooter>
      )}
    </>
  );
};

export default Datatable;
