import styled from 'styled-components';

interface AccordionContentContainerProps {
  active: boolean;
  itemsLength: number;
}

export const AccordionContainer = styled.div``;

export const AccordionHeaderItem = styled.div`
  padding: 0 12px;
  height: 40px;
  font-size: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background 0.2s ease;
  font-weight: 600;

  &:hover {
    background: #144380;
  }
`;

export const AccordionContentContainer = styled.div<AccordionContentContainerProps>`
  height: ${({ active, itemsLength }) => (active ? `calc(40px * ${itemsLength})` : '0')};
  overflow: hidden;
  transition:
    background 0.2s ease,
    height 0.2s linear;

  li {
    height: 40px;
    padding: 0 12px 0 22px;
    display: flex;
    align-items: center;
    font-size: 0.6rem;
    background: ${({ theme }) => theme.background.primary};
    transition: background 0.2s ease;
    cursor: pointer;

    &: hover {
      background: #144380;
    }

    svg {
      margin-right: 4px;
      font-size: 0.9rem;
    }
  }
`;
