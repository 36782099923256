export enum OrderStatus {
  CRIADO = 1,
  AGUARDANDO_PAGAMENTO = 2,
  EM_ANALISE = 3,
  PAGAMENTO_CONFIRMADO = 4,
  EM_SEPARAÇÃO = 5,
  ENVIADO = 6,
  AGUARDANDO_COLETA = 7,
  ENTREGUE = 8,
  PAGAMENTO_RECUSADO = 9,
  AGUARDANDO_ESTORNO = 10,
  CANCELADO = 11,
  ERRO_PAGASEGURO = 12,
  ACEITO = 13,
}
