import Decimal from 'decimal.js';

export const formatDecimal = (value: string): Decimal => {
  console.log(value);
  const number = value.replaceAll('.', '').replaceAll(',', '.');

  return new Decimal(number);
};

export const formatMoney = (amount: string): string => {
  const price = new Decimal(amount).toFixed(2);

  return price.replaceAll('.', ',').replace(/\d(?=(\d{3})+\.)/g, '$&.');
};
