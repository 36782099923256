import styled from 'styled-components';

interface ContentHeaderProfileImageProps {
  background: string;
}

export const LayoutContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: ${({ theme }) => theme.background.fourth};
  display: flex;
  flex-direction: column;
`;

export const ContentContainerHeader = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 12px 18px;
  justify-content: space-between;

  svg {
    font-size: 1.2rem;
    cursor: pointer;
  }
`;

export const ContentHeaderProfileContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.color.third};
    font-size: 1.5rem;
  }

  p {
    font-size: 0.75rem;
    font-weight: 600;
    margin-left: 5px;
  }
`;

export const HeaderNotificationContainer = styled.div`
  position: relative;
`;

export const HeaderNotificationContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #eee;
  font-weight: 600;
  background: #ff0000;
  font-size: 0.52rem;
`;

export const ContentHeaderProfileImage = styled.div<ContentHeaderProfileImageProps>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: url('${({ background }) => background}');
  background-size: cover;
  background-position: center;
  border: 1px solid #d4d4d4;
  margin-left: 14px;
`;

export const ContentContainerBox = styled.div`
  padding: 18px;
`;

export const ContentContainerTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.color.secondary};
  text-transform: capitalize;
`;
