import styled from 'styled-components';

interface CustomInputContainerProps {
  marginBottom?: boolean;
  icon: boolean;
  border?: {
    active: boolean;
    color: string;
  };
}

export const CustomInputContainer = styled.div<CustomInputContainerProps>`
  border-radius: 6px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.background.third};
  display: flex;
  margin-bottom: ${({ marginBottom }) => (marginBottom ?? false ? '16px' : '0')};

  input,
  select {
    width: 100%;
    height: 42px;
    border: ${({ border }) => (border != null ? `1px solid ${border.color}` : '0')};
    background: transparent;
    padding: 0 12px 0 ${({ icon }) => (icon ? '0' : '12px')};
    font-size: 0.9rem;
    color: ${({ theme }) => theme.background.fifth};
    font-family: Montserrat, sans-serif;
  }

  select {
    padding: 0 12px;
    margin-right: 12px;
  }

  input[readonly] {
    background-color: ${({ theme }) => theme.background.fourth};
  }

  input:focus-visible,
  select:focus-visible {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.background.fifth};
  }
`;

export const CustomInputLabel = styled.p`
  font-weight: 300;
  font-size: 0.8rem;
  margin-bottom: 5px;
`;
