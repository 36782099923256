import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { type FastDeliveryBody, type FastDeliveryResponse, type ZipCodeResponse } from './create';
import { FastDeliveriesContainer } from '../../styles/pages/fastDeliveries';
import CurrencyInput from 'react-currency-input';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { formatDecimal, formatMoney } from '../../helpers/numberMethods';
import { useAuth } from '../../hooks/useAuth';
import { BsCurrencyDollar } from 'react-icons/bs';

const bodyDefaultValue = {
  nick: '',
  price: '',
  zipCode: '',
  address: '',
  district: '',
  deliveryTime: 0,
  city: '',
  state: '',
};

const FastDelivery: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(true);

  const [body, setBody] = useState<FastDeliveryBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<FastDeliveryResponse>(`entregas-rapidas/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setBody({ ...data, price: formatMoney(data.price) });
        setFormLoading(false);
      })
      .catch(() => {
        setAlerts({ message: 'Não foi possível recuperar frete', type: AlertTypes.WARNING });
        setFormLoading(false);
      });
  }, [id]);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch<FastDeliveryResponse>(
        `entregas-rapidas/${id}`,
        { ...body, price: formatDecimal(body.price) },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados atualizados com sucesso!' });
    } catch {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar entrega rápida, verifique as informações inseridas e tente novamente.',
      });
    }

    setFormLoading(false);
  };

  const searchCep = async (e: React.FocusEvent<HTMLInputElement, Element>): Promise<void> => {
    try {
      setFormLoading(true);
      const zipCode = e.target.value;
      const { data } = await axios.get<ZipCodeResponse>(`https://viacep.com.br/ws/${zipCode}/json`);

      setBody({ ...body, address: data.logradouro, district: data.bairro, city: data.localidade, state: data.uf });
      setFormLoading(false);
    } catch {}
  };

  return (
    <>
      <FastDeliveriesContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Nome">
                <input
                  value={body.nick ?? ''}
                  onChange={e => {
                    setBody({ ...body, nick: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Prazo em minutos">
                <InputMask
                  value={body.deliveryTime}
                  mask="999"
                  onChange={e => {
                    setBody({ ...body, deliveryTime: parseInt(e.target.value) });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={1}>
              <CustomInput marginBottom={true} label="Preço" icon={<BsCurrencyDollar />}>
                <CurrencyInput
                  onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBody({ ...body, price: e.target.value });
                  }}
                  value={body.price}
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="CEP">
                <InputMask
                  value={body.zipCode}
                  mask="99999-999"
                  onChange={e => {
                    setBody({ ...body, zipCode: e.target.value });
                  }}
                  onBlur={searchCep}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Endereço">
                <input
                  value={body?.address}
                  onChange={e => {
                    setBody({ ...body, address: e.target.value });
                  }}
                  required
                  readOnly
                />
              </CustomInput>
            </Column>
            <Column xl={5}>
              <CustomInput marginBottom={true} label="Bairro">
                <input
                  value={body?.district}
                  required
                  readOnly
                  onChange={e => {
                    setBody({ ...body, district: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Cidade">
                <input
                  value={body?.city}
                  required
                  readOnly
                  onChange={e => {
                    setBody({ ...body, city: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Estado">
                <input
                  value={body?.state}
                  required
                  readOnly
                  onChange={e => {
                    setBody({ ...body, state: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/entregas-rapidas');
                  }}
                  type="button"
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </FastDeliveriesContainer>
    </>
  );
};

export default FastDelivery;
