import styled from 'styled-components';

export const UsersContainer = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 12px;
  position: relative;
  min-height: 100px;
`;

export const UsersRemoveLabel = styled.p`
  font-size: 0.9rem;
  margin-bottom: 8px;
`;

export const UsersSubtitle = styled.h2`
  font-size: 0.9rem;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.background.fifth};
`;

export const UsersAddressBox = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.background.fourth};
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 8px;

  h2 {
    font-size: 0.9rem;
    margin-bottom: 8px;
  }

  p {
    font-size: 0.8rem;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.background.fifth};
  }
`;

export const UsersPaymentBox = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.background.fourth};
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 8px;

  h2 {
    font-size: 0.9rem;
    margin-bottom: 8px;
  }

  p {
    font-size: 0.8rem;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.background.fifth};
  }
`;
