import styled from 'styled-components';

export const ExportContainer = styled.div`
  width: 400px;
  height: 300px;
`;

export const ExportHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 66px;

  h1 {
    font-size: 0.9rem;
  }
`;

export const ExportContentContainer = styled.div`
  padding: 12px;
  background: ${({ theme }) => theme.background.third};
  height: calc(100% - 132px);
`;

export const ExportInputContainer = styled.div`
  display: flex;

  input {
    margin-right: 5px;
  }
`;
