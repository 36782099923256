import React, { useState, type ReactElement } from 'react';
import { AccordionContainer, AccordionContentContainer, AccordionHeaderItem } from '../styles/components/accordion';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';

interface AccordionProps {
  title: string;
  children: ReactElement | ReactElement[];
  itemsLength: number;
}

const Accordion: React.FC<AccordionProps> = ({ children, itemsLength, title }) => {
  const [active, setActive] = useState(true);

  return (
    <AccordionContainer>
      <AccordionHeaderItem
        onClick={() => {
          active ? setActive(false) : setActive(true);
        }}
      >
        <span>{title}</span>
        {active ? <FaAngleDown /> : <FaAngleUp />}
      </AccordionHeaderItem>
      <AccordionContentContainer itemsLength={itemsLength} active={active}>
        {children}
      </AccordionContentContainer>
    </AccordionContainer>
  );
};

export default Accordion;
