import React, { createRef, useEffect, useState } from 'react';
import {
  ImageCard,
  ImageNewCard,
  ImagesContainer,
  ImagesContenteContainer,
  ImagesNoContentContainer,
} from '../../styles/pages/images';
import ApplicationApi from '../../api/config';
import { type UploadResponse } from '../../components/upload';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import { FaCheck, FaPlus, FaUpload } from 'react-icons/fa6';
import Loading from '../../components/loading';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomButtom from '../../components/button';
import { useAuth } from '../../hooks/useAuth';

const Images: React.FC = () => {
  const { token } = useAuth();

  const [uploads, setUploads] = useState<UploadResponse[]>([]);
  const [selectedUploads, setSelectedUploads] = useState<UploadResponse[] | undefined>();
  const [ids, setIds] = useState<number[]>();

  const [alerts, setAlerts] = useState<AlertsProps>();
  const [loading, setLoading] = useState<boolean>(true);

  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    ApplicationApi.get<UploadResponse[]>('uploads', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setUploads(data);
        setLoading(false);
      })
      .catch(() => {
        setAlerts({ message: 'Não foi possível recuperar uploads', type: AlertTypes.ERROR });
      });
  }, []);

  useEffect(() => {
    setIds(selectedUploads?.map(value => value.id));
  }, [selectedUploads]);

  const submitForm = async (): Promise<void> => {
    const files = inputRef.current?.files;

    if (files != null) {
      setLoading(true);
      try {
        const formData = new FormData();

        for (const x in files) {
          formData.append('files', files[x]);
        }

        const { data } = await ApplicationApi.post<UploadResponse[]>('uploads', formData, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoading(false);
        data.forEach(upload => {
          setUploads(uploads => [...uploads, upload]);
        });
      } catch {}
    }
  };

  const deleteUpload = async (): Promise<void> => {
    if (selectedUploads !== undefined) {
      setLoading(true);
      for await (const upload of selectedUploads) {
        await ApplicationApi.delete(`uploads/${upload.id}`, { headers: { Authorization: `Bearer ${token}` } });

        setUploads(uploads => uploads.filter(item => item.id !== upload.id));
        setSelectedUploads(uploads =>
          uploads !== undefined ? uploads.filter(item => item.id !== upload.id) : undefined,
        );
      }
      setLoading(false);
    } else {
      setAlerts({ message: 'Nenhum upload selecionado', type: AlertTypes.WARNING });
    }
  };

  const selectUpload = (upload: UploadResponse): void => {
    setSelectedUploads(uploads => (uploads !== undefined ? [...uploads, upload] : [upload]));
  };

  const removeUpload = (upload: UploadResponse): void => {
    setSelectedUploads(uploads => (uploads !== undefined ? uploads.filter(item => item.id !== upload.id) : undefined));
  };

  return (
    <>
      {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
      <ImagesContainer>
        {loading ? <Loading /> : <></>}
        <input type="file" onChange={submitForm} ref={inputRef} multiple max={10} accept="image/*" />
        {uploads.length > 0 ? (
          <ImagesContenteContainer>
            {uploads.map((upload, i) => (
              <>
                {ids !== undefined && ids.includes(upload.id) ? (
                  <ImageCard
                    active={true}
                    key={i}
                    background={upload.origin}
                    onClick={() => {
                      removeUpload(upload);
                    }}
                  >
                    <div className="hover">
                      <div>
                        <FaCheck />
                      </div>
                    </div>
                  </ImageCard>
                ) : (
                  <ImageCard
                    active={false}
                    key={i}
                    background={upload.origin}
                    onClick={() => {
                      selectUpload(upload);
                    }}
                  >
                    <div className="hover">
                      <div>
                        <FaCheck />
                      </div>
                    </div>
                  </ImageCard>
                )}
              </>
            ))}
            <ImageNewCard onClick={() => (!loading ? inputRef.current?.click() : false)}>
              <FaPlus />
            </ImageNewCard>
          </ImagesContenteContainer>
        ) : (
          <ImagesNoContentContainer onClick={() => inputRef.current?.click()}>
            <FaUpload />
            <p>ENVIAR ARQUIVOS</p>
          </ImagesNoContentContainer>
        )}
      </ImagesContainer>{' '}
      {selectedUploads !== undefined && selectedUploads.length > 0 ? (
        <Row>
          <Column xl={2}>
            <CustomButtom>
              <button onClick={deleteUpload}>EXCLUIR SELECIONADOS ({selectedUploads.length})</button>
            </CustomButtom>
          </Column>
          <Column xl={2}>
            <CustomButtom>
              <button
                onClick={() => {
                  setSelectedUploads(undefined);
                }}
              >
                LIMPAR
              </button>
            </CustomButtom>
          </Column>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default Images;
