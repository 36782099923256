import styled from 'styled-components';

export const TextsContainer = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 12px;
  position: relative;
  min-height: 100px;
`;

export const TextsRemoveLabel = styled.p`
  font-size: 0.9rem;
  margin-bottom: 8px;
`;

export const TextareaContainer = styled.div`
  margin-bottom: 16px;

  p {
    font-weight: 300;
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
`;
