import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import { FaPen, FaTrash, FaUpload } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { PromotionsContainer } from '../../styles/pages/promotions';
import CustomTextarea from '../../components/textarea';
import { Upload, type UploadResponse } from '../../components/upload';
import Fancybox from '../../components/fancybox';
import { FancyboxFooter } from '../../styles/components/fancybox';
import { useAuth } from '../../hooks/useAuth';
import { type ProductResponse } from '../products/create';
import Products from '../../components/products';
import { MdOutlineContentCopy } from 'react-icons/md';

export interface PromotionResponse {
  id: number;
  name: string;
  description: string | null;
  promotionImages: { uploads: UploadResponse } | null;
  promotionThumbnails: { uploads: UploadResponse } | null;
  promotionProducts: Array<{ promotionId: number; productId: number; price: string; products: ProductResponse }>;
  updatedAt: Date;
  createdAt: Date;
}

export interface PromotionBody {
  name: string;
  description: string | null;
  products: Array<{ productId: number; price: string }>;
}

const bodyDefaultValue = {
  name: '',
  description: '',
  products: [],
};

const Promotions: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [promotions, setPromotions] = useState<PromotionResponse[]>([]);

  const [uploadFancybox, setUploadFancybox] = useState(false);
  const [uploadThumbnailFancybox, setUploadThumbnailFancybox] = useState(false);
  const [productsFancybox, setProductsFancybox] = useState(false);
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const [image, setImage] = useState<UploadResponse>();
  const [thumbnail, setThumbnail] = useState<UploadResponse>();
  const [products, setProducts] = useState<ProductResponse[]>();
  const [body, setBody] = useState<PromotionBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<PromotionResponse[]>('promocoes', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setPromotions(data);
        setTableLoading(false);
      })
      .catch(() => {});
  }, []);

  const resetFormFields = (): void => {
    setBody(bodyDefaultValue);
    setImage(undefined);
    setThumbnail(undefined);
    setProducts(undefined);
  };

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      const { data } = await ApplicationApi.post<PromotionResponse>(
        'promocoes',
        {
          ...body,
          imageId: image?.id,
          thumbnailId: thumbnail?.id,
          products: products?.map(product => ({
            productId: product.id,
            price: product.price,
          })),
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados cadastrados com sucesso!' });
      setPromotions(promotion => [...promotion, data]);
      resetFormFields();
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível cadastrar promoção' });
    }

    setFormLoading(false);
  };

  return (
    <>
      <Fancybox active={uploadFancybox}>
        <Upload path="promocoes" currentUpload={image} stateRef={setImage} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setUploadFancybox(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setImage(undefined);
                    setUploadFancybox(false);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <Fancybox active={uploadThumbnailFancybox}>
        <Upload path="promocoes/thumbnails" currentUpload={thumbnail} stateRef={setThumbnail} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setUploadThumbnailFancybox(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setImage(undefined);
                    setUploadThumbnailFancybox(false);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <Fancybox active={productsFancybox}>
        <Products setState={setProducts} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setProductsFancybox(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setProductsFancybox(false);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <PromotionsContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Nome">
                <input
                  value={body.name}
                  onChange={e => {
                    setBody({ ...body, name: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Thumbnail">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setUploadThumbnailFancybox(true);
                    }}
                  >
                    <FaUpload /> {thumbnail?.filename ?? 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Banner">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setUploadFancybox(true);
                    }}
                  >
                    <FaUpload /> {image?.filename ?? 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Produtos">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setProductsFancybox(true);
                    }}
                  >
                    <MdOutlineContentCopy />{' '}
                    {products !== undefined && products?.length > 0
                      ? `${products?.length} Produto(s) selecionado(s)`
                      : 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={12}>
              <CustomTextarea marginBottom={true} label="Descrição">
                <textarea
                  value={body.description ?? ''}
                  onChange={e => {
                    setBody({ ...body, description: e.target.value });
                  }}
                />
              </CustomTextarea>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button onClick={resetFormFields} type="reset">
                  LIMPAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </PromotionsContainer>
      <PromotionsContainer>
        {tableLoading ? <Loading /> : <></>}
        <Datatable
          columns={[
            { field: 'id', title: 'ID', width: 80 },
            { field: 'name', title: 'TÍTULO' },
            { field: 'price', title: 'PREÇO' },
            { field: 'createdAt', title: 'CADASTRO', width: 200 },
            { field: 'edit', title: 'EDITAR', width: 80 },
            { field: 'remove', title: 'EXCLUIR', width: 80 },
          ]}
          data={promotions.map(promotion => ({
            id: promotion.id,
            name: promotion.name,
            createdAt: new Date(promotion.createdAt).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }),
            edit: (
              <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
                <button
                  onClick={() => {
                    navigate(`${promotion.id}`);
                  }}
                >
                  <FaPen />
                </button>
              </CustomButtom>
            ),
            remove: (
              <CustomButtom background={ButtonTheme.background.error} small>
                <button
                  onClick={() => {
                    navigate(`remover/${promotion.id}`);
                  }}
                >
                  <FaTrash />
                </button>
              </CustomButtom>
            ),
          }))}
        />
      </PromotionsContainer>
    </>
  );
};

export default Promotions;
