import React, { useState, useEffect } from 'react';
import CustomButtom from '../../components/button';
import { ButtonTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import { FaEye, FaTrash } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { type UploadResponse } from '../../components/upload';
import { UsersContainer } from '../../styles/pages/users';
import { useAuth } from '../../hooks/useAuth';

export interface UserAddressResponse {
  id: number;
  nick: string | null;
  address: string;
  complement: string | null;
  number: string;
  district: string;
  zipCode: string;
  city: string;
  state: string;
}

export interface UserPaymentResponse {
  userId: number;
  nick: string | null;
  cardNumber: string;
  ccv: number;
  expirationMonth: number;
  expirationYear: number;
  flag: string;
  name: string;
}

export interface UserResponse {
  id: number;
  fullname: string;
  document: string;
  email: string;
  password: string;
  birthdate: string;
  phone: string;
  statusId: number;
  status?: { id: number; name: string };
  userPayments?: UserPaymentResponse[];
  userAddresses?: UserAddressResponse[];
  userImages?: UploadResponse | null;
  gender?: string | null;
  workPhone?: string | null;
  homePhone?: string | null;
  updatedAt: Date;
  createdAt: Date;
}

const Users: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [users, setUsers] = useState<UserResponse[]>([]);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    ApplicationApi.get<UserResponse[]>('usuarios', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setUsers(data);
        setTableLoading(false);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <UsersContainer>
        {tableLoading ? <Loading /> : <></>}
        <Datatable
          exportPath="usuarios"
          columns={[
            { field: 'id', title: 'ID', width: 80 },
            { field: 'name', title: 'NOME' },
            { field: 'document', title: 'DOCUMENTO' },
            { field: 'createdAt', title: 'CADASTRO', width: 200 },
            { field: 'view', title: 'VISUALIZAR', width: 100 },
            { field: 'remove', title: 'EXCLUIR', width: 80 },
          ]}
          data={users.map(user => ({
            id: user.id,
            name: user.fullname,
            document: user.document,
            createdAt: new Date(user.createdAt).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }),
            view: (
              <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
                <button
                  onClick={() => {
                    navigate(`${user.id}`);
                  }}
                >
                  <FaEye />
                </button>
              </CustomButtom>
            ),
            remove: (
              <CustomButtom background={ButtonTheme.background.error} small>
                <button
                  onClick={() => {
                    navigate(`remover/${user.id}`);
                  }}
                >
                  <FaTrash />
                </button>
              </CustomButtom>
            ),
          }))}
        />
      </UsersContainer>
    </>
  );
};

export default Users;
