import styled from 'styled-components';

interface FancyboxContainerProps {
  active: boolean;
}

export const FancyboxContainer = styled.div<FancyboxContainerProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  opacity: ${({ active }) => (active ? '1' : '0')};
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  transition:
    opacity 0.2s ease,
    visibility 0.4s ease;
  z-index: 999;
`;

export const FancyboxContentContainer = styled.div`
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
`;

export const FancyboxFooter = styled.div`
  padding: 12px;
`;
