import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import { FaPen, FaTrash, FaUpload } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { TextareaContainer, TextsContainer } from '../../styles/pages/texts';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useAuth } from '../../hooks/useAuth';
import Fancybox from '../../components/fancybox';
import { Upload, type UploadResponse } from '../../components/upload';
import { FancyboxFooter } from '../../styles/components/fancybox';

export interface TextResponse {
  id: number;
  title: string;
  text: string | null;
  slug: string;
  textImages: {
    id: number;
    textId: number;
    uploadId: number;
    uploads: UploadResponse;
  } | null;
}

export interface TextBody {
  title: string;
  text?: string | null;
}

const bodyDefaultValue = {
  title: '',
  text: '',
};

const Texts: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [texts, setTexts] = useState<TextResponse[]>([]);

  const [uploadFancybox, setUploadFancybox] = useState(false);
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const [textEditorValue, setTextEditorValue] = useState<string>('');
  const [image, setImage] = useState<UploadResponse>();
  const [body, setBody] = useState<TextBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<TextResponse[]>('textos', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setTexts(data);
        setTableLoading(false);
      })
      .catch(() => {});
  }, []);

  const resetFormFields = (): void => {
    setBody(bodyDefaultValue);
  };

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      const { data } = await ApplicationApi.post<TextResponse>(
        'textos',
        { ...body, text: textEditorValue, uploadId: image?.id },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados cadastrados com sucesso!' });
      setTexts(texts => [...texts, data]);

      resetFormFields();
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível cadastrar texto' });
    }

    setFormLoading(false);
  };

  return (
    <>
      <Fancybox active={uploadFancybox}>
        <Upload path="textos" currentUpload={image} stateRef={setImage} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setUploadFancybox(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setUploadFancybox(false);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <TextsContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={8}>
              <CustomInput marginBottom={true} label="Título">
                <input
                  value={body.title}
                  onChange={e => {
                    setBody({ ...body, title: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Foto">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setUploadFancybox(true);
                    }}
                  >
                    <FaUpload /> {image?.filename ?? 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={12}>
              <TextareaContainer>
                <p>Texto</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={body.text}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setTextEditorValue(data);
                  }}
                />
              </TextareaContainer>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button onClick={resetFormFields} type="reset">
                  LIMPAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </TextsContainer>
      <TextsContainer>
        {tableLoading ? <Loading /> : <></>}
        <Datatable
          columns={[
            { field: 'id', title: 'ID', width: 80 },
            { field: 'title', title: 'TÍTULO' },
            { field: 'edit', title: 'EDITAR', width: 80 },
            { field: 'remove', title: 'EXCLUIR', width: 80 },
          ]}
          data={texts.map(text => ({
            id: text.id,
            title: text.title,
            edit: (
              <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
                <button
                  onClick={() => {
                    navigate(`${text.id}`);
                  }}
                >
                  <FaPen />
                </button>
              </CustomButtom>
            ),
            remove: (
              <CustomButtom background={ButtonTheme.background.error} small>
                <button
                  onClick={() => {
                    navigate(`remover/${text.id}`);
                  }}
                >
                  <FaTrash />
                </button>
              </CustomButtom>
            ),
          }))}
        />
      </TextsContainer>
    </>
  );
};

export default Texts;
