// @ts-nocheck
import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import Fancybox from '../../components/fancybox';
import Icon from '../../components/icon';
import { FancyboxFooter } from '../../styles/components/fancybox';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import { FaPen, FaTrash } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { type CategoryResponse } from '../categories/create';
import CustomSelect from '../../components/select';
import { SubcategoriesContainer } from '../../styles/pages/subcategories';
import { useAuth } from '../../hooks/useAuth';
import Select from 'react-select';

export interface SubcategoryResponse {
  id: number;
  name: string;
  icon: string;
  slug: string;
  categoryId: number;
  categories: CategoryResponse;
  description: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface SubcategoryBody {
  categories?: CategoryResponse;
  name?: string;
  description?: string | null;
  icon?: string;
  categoryId?: number;
}

const bodyDefaultValue = {
  name: '',
  description: '',
  icon: '',
};

const Subcategories: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [subcategories, setSubcategories] = useState<SubcategoryResponse[]>([]);

  const [categories, setCategories] = useState<CategoryResponse[]>([]);

  const [fancyboxIcon, setFancyboxIcon] = useState(false);
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const [icon, setIcon] = useState<string>();
  const [body, setBody] = useState<SubcategoryBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<CategoryResponse[]>('categorias', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setCategories(data);
      })
      .catch(() => {});

    ApplicationApi.get<SubcategoryResponse[]>('subcategorias', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setSubcategories(data);
        setTableLoading(false);
      })
      .catch(() => {});
  }, []);

  const resetFormFields = (): void => {
    setBody(bodyDefaultValue);
    setIcon(undefined);
  };

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      const { data } = await ApplicationApi.post<SubcategoryResponse>(
        'subcategorias',
        { ...body, icon },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados cadastrados com sucesso!' });
      setSubcategories(subcategories => [...subcategories, data]);
      resetFormFields();
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível cadastrar subcategoria' });
    }

    setFormLoading(false);
  };

  return (
    <>
      <Fancybox active={fancyboxIcon}>
        <Icon currentIcon={icon} stateRef={setIcon} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setFancyboxIcon(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setFancyboxIcon(false);
                    setIcon(undefined);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <SubcategoriesContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={3}>
              <CustomSelect label="Categoria">
                <Select
                  onChange={e => {
                    setBody({ ...body, categoryId: e?.value ?? undefined });
                  }}
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      border: 0,
                      borderRadius: '6px',
                      overflow: 'hidden',
                      ':focus': { border: 0, outline: 0 },
                      ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                      background: PrimaryTheme.background.third,
                      color: PrimaryTheme.background.fifth,
                      fontSize: '.9rem',
                      height: '42px',
                    }),
                  }}
                  placeholder="Selecione"
                  options={categories.map(category => ({ label: category.name, value: category.id }))}
                />
              </CustomSelect>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Nome">
                <input
                  value={body.name}
                  onChange={e => {
                    setBody({ ...body, name: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Descrição">
                <input
                  value={body?.description ?? ''}
                  onChange={e => {
                    setBody({ ...body, description: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Icone">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setFancyboxIcon(true);
                    }}
                  >
                    {icon ?? 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button onClick={resetFormFields} type="reset">
                  LIMPAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </SubcategoriesContainer>
      <SubcategoriesContainer>
        {tableLoading ? <Loading /> : <></>}
        <Datatable
          columns={[
            { field: 'id', title: 'ID', width: 80 },
            { field: 'category', title: 'Categoria' },
            { field: 'name', title: 'NOME' },
            { field: 'createdAt', title: 'CADASTRO', width: 200 },
            { field: 'edit', title: 'EDITAR', width: 80 },
            { field: 'remove', title: 'EXCLUIR', width: 80 },
          ]}
          data={subcategories.map(subcategory => ({
            id: subcategory.id,
            category: subcategory.categories.name,
            name: subcategory.name,
            createdAt: new Date(subcategory.createdAt).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }),
            edit: (
              <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
                <button
                  onClick={() => {
                    navigate(`${subcategory.id}`);
                  }}
                >
                  <FaPen />
                </button>
              </CustomButtom>
            ),
            remove: (
              <CustomButtom background={ButtonTheme.background.error} small>
                <button
                  onClick={() => {
                    navigate(`remover/${subcategory.id}`);
                  }}
                >
                  <FaTrash />
                </button>
              </CustomButtom>
            ),
          }))}
        />
      </SubcategoriesContainer>
    </>
  );
};

export default Subcategories;
