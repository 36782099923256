import styled from 'styled-components';

interface UploadCardProps {
  background: string;
  active: boolean;
}

export const UploadContainer = styled.div`
  width: calc(100vw - 400px);
  height: calc(100vh - 200px);

  input[type='file'] {
    display: none;
  }
`;

export const UploadHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 66px;

  h1 {
    font-size: 0.9rem;
  }
`;

export const UploadNoContent = styled.div`
  width: 100%;
  height: calc(100% - 46px);
  background: ${({ theme }) => theme.background.third};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.background.fifth};
  font-size: 1rem;
  font-weight: 600;
  position: relative;

  svg {
    font-size: 3rem;
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 8px;
    cursor: pointer;
    text-decoration: underline;
  }

  span {
    margin-bottom: 8px;
  }
`;

export const UploadContent = styled.div`
  width: 100%;
  height: calc(100% - 66px);
  background: ${({ theme }) => theme.background.third};
  position: relative;
  padding: 12px;
  overflow-y: auto;
`;

export const UploadBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const UploadNewCard = styled.div`
  width: 100px;
  height: 110px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.background.fourth};
  transition: filter 0.1s ease;

  &:hover {
    filter: contrast(1.1);
  }

  svg {
    color: ${({ theme }) => theme.background.fifth};
    font-size: 2rem;
  }
`;

export const UploadEANCard = styled.div`
  width: 100px;
  height: 110px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.background.fourth};
  transition: filter 0.1s ease;

  &:hover {
    filter: contrast(1.1);
  }

  svg {
    color: ${({ theme }) => theme.background.fifth};
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  p {
    text-align: center;
    color: ${({ theme }) => theme.background.fifth};
    font-weight: 600;
    font-size: 0.7rem;
  }
`;

export const UploadCard = styled.div<UploadCardProps>`
  width: 100px;
  height: 110px;
  background: url('${({ background }) => background}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;

  div.hover {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${({ active }) => (active ? '1' : '0')};
    transition: opacity 0.1s ease;

    div {
      width: 30px;
      height: 30px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:hover .hover {
    opacity: 1;
  }
`;

export const UploadCardDocument = styled.div<{ active: boolean }>`
  width: 350px;
  background: ${({ theme }) => theme.background.fourth};
  padding: 0 12px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  font-size: 0.8rem;

  div {
    display: flex;
  }

  svg {
    font-size: 1.3rem;
  }
`;

export const UploadCardDocumentView = styled.div`
  svg {
    color: ${({ theme }) => theme.background.fifth};
  }
`;

export const UploadCardDocumentSelect = styled.div<{ active: boolean }>`
  svg {
    font-size: 1.3rem;
    color: ${({ active, theme }) => (active ? '#28a745' : theme.background.fifth)};
    margin-left: 4px;
  }
`;

export const UploadNewCardDocument = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.background.fourth};
  transition: filter 0.1s ease;

  &:hover {
    filter: contrast(1.1);
  }

  svg {
    color: ${({ theme }) => theme.background.fifth};
    font-size: 1.3rem;
  }
`;
