import styled from 'styled-components';

interface ColumnContainerProps {
  xl?: number;
  lg?: number;
  md?: number;
  sm?: number;
}

export const ColumnContainer = styled.div<ColumnContainerProps>`
  padding: 0 8px;

  @media (min-width: 1200px) {
    width: calc((${({ xl }) => xl} * 100%) / 12);
  }

  @media (min-width: 768px) and (max-width: 996px) {
    width: calc((${({ lg, xl }) => lg ?? xl} * 100%) / 12);
  }

  @media (min-width: 420px) and (max-width: 767px) {
    width: calc((${({ md, lg, xl }) => md ?? lg ?? xl} * 100%) / 12);
  }

  @media (max-width: 419px) {
    width: calc((${({ sm, md, lg, xl }) => sm ?? md ?? lg ?? xl} * 100%) / 12);
  }
`;
