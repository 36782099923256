import styled from 'styled-components';

export const IntegrationsContainer = styled.div`
  margin-bottom: 12px;
  position: relative;
  display: flex;
  gap: 12px;
`;

export const IntegrationBox = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 12px;
  position: relative;
  width: 50%;

  h1 {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.color.third};
    font-weight: 300;
    margin-bottom: 12px;
  }
`;

export const IntegrationCompleteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 1.3rem;
    margin-right: 4px;
    color: #025919;
  }

  p {
    font-size: 0.9rem;
    font-weight: 600;
  }
`;
