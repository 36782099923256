export const PrimaryTheme = {
  background: {
    primary: '#295FA6',
    secondary: '#6BA5F2',
    third: '#D5E2F2',
    fourth: '#e9f1fb',
    fifth: '#647fa1',
  },
  color: {
    primary: '#eee',
    secondary: '#111',
    third: '#787878',
  },
};

export const ButtonTheme = {
  background: {
    warning: '#ffc107',
    error: '#dc3545',
  },
  color: {
    primary: '#fff',
    secondary: '#111',
  },
};
