import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomButtom from '../../components/button';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import { FastDeliveriesContainer, FastDeliveriesRemoveLabel } from '../../styles/pages/fastDeliveries';
import { formatMoney } from '../../helpers/numberMethods';
import { useAuth } from '../../hooks/useAuth';
import { type FastDeliveryResponse } from './create';

const DeleteFastDelivery: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [fastDelivery, setFastDelivery] = useState<FastDeliveryResponse>();
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [isRemoved, setIsRemoved] = useState(false);

  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    ApplicationApi.get<FastDeliveryResponse>(`entregas-rapidas/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setFastDelivery(data);
        setFormLoading(false);
      })
      .catch(() => {
        setFormLoading(false);
        setAlerts({ type: AlertTypes.ERROR, message: 'Entrega rápida não encontrado' });
      });
  }, []);

  const submitForm = async (): Promise<void> => {
    setFormLoading(true);

    try {
      await ApplicationApi.delete(`entregas/${id}`, { headers: { Authorization: `Bearer ${token}` } });

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Frete removido com sucesso' });
      setIsRemoved(true);
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível remover frete' });
    }

    setFormLoading(false);
  };

  return (
    <FastDeliveriesContainer>
      {formLoading ? <Loading /> : <></>}
      {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
      {isRemoved ? (
        <>
          <Row>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/entregas-rapidas');
                  }}
                  type="button"
                >
                  VOLTAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </>
      ) : (
        <>
          {fastDelivery !== undefined ? (
            <>
              <FastDeliveriesRemoveLabel>
                <b>ID:</b> {fastDelivery.id}
              </FastDeliveriesRemoveLabel>
              <FastDeliveriesRemoveLabel>
                <b>Nome:</b> {fastDelivery.nick}
              </FastDeliveriesRemoveLabel>
              <FastDeliveriesRemoveLabel>
                <b>Preço:</b> {formatMoney(fastDelivery.price.toString())}
              </FastDeliveriesRemoveLabel>
              <FastDeliveriesRemoveLabel>
                <b>CEP:</b> {fastDelivery.zipCode}
              </FastDeliveriesRemoveLabel>
              <FastDeliveriesRemoveLabel>
                <b>Endereço:</b> {fastDelivery.address}
              </FastDeliveriesRemoveLabel>
              <FastDeliveriesRemoveLabel>
                <b>Bairro:</b> {fastDelivery.district}
              </FastDeliveriesRemoveLabel>
              <FastDeliveriesRemoveLabel>
                <b>Cidade:</b> {fastDelivery.city}
              </FastDeliveriesRemoveLabel>
              <FastDeliveriesRemoveLabel>
                <b>Estado:</b> {fastDelivery.state}
              </FastDeliveriesRemoveLabel>
              <Row>
                <Column xl={2}>
                  <CustomButtom background={PrimaryTheme.background.secondary}>
                    <button
                      onClick={() => {
                        navigate('/entregas-rapidas');
                      }}
                      type="button"
                    >
                      CANCELAR
                    </button>
                  </CustomButtom>
                </Column>
                <Column xl={2}>
                  <CustomButtom background={ButtonTheme.background.error}>
                    <button onClick={submitForm} type="button">
                      CONFIRMAR EXCLUSÃO
                    </button>
                  </CustomButtom>
                </Column>
              </Row>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </FastDeliveriesContainer>
  );
};

export default DeleteFastDelivery;
