import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import CustomTextarea from '../../components/textarea';
import Fancybox from '../../components/fancybox';
import { FancyboxFooter } from '../../styles/components/fancybox';
import { useAuth } from '../../hooks/useAuth';
import { MdOutlineContentCopy } from 'react-icons/md';
import Products from '../../components/productsOffer';
import { type ProductResponse } from '../products/create';
import { type OfferBody, type OfferResponse } from './create';
import { OffersContainer } from '../../styles/pages/offers';
import { formatDateToBR, formatDateToUS } from '../../helpers/dateFormat';
import { AiOutlineCalendar } from 'react-icons/ai';
import InputMask from 'react-input-mask';

const bodyDefaultValue = {
  name: '',
  description: '',
  expirationDate: '',
  products: [],
};

const Offer: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [productsFancybox, setProductsFancybox] = useState(false);
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(true);

  const [products, setProducts] = useState<ProductResponse[]>();
  const [body, setBody] = useState<OfferBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<OfferResponse>(`ofertas/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setBody({
          ...data,
          expirationDate: formatDateToBR(
            new Date(data.expirationDate).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
            }),
          ),
          products: data.offerProducts.map(product => ({
            price: product.price,
            productId: product.productId,
            quantity: product.quantity,
          })),
        });
        setProducts(
          data.offerProducts.map(product => ({
            ...product.products,
            price: product.price,
            quantity: product.quantity,
          })),
        );
        setFormLoading(false);
      })
      .catch(() => {
        setAlerts({ message: 'Não foi possível recuperar oferta', type: AlertTypes.WARNING });
        setFormLoading(false);
      });
  }, [id]);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch<OfferResponse>(
        `ofertas/${id}`,
        {
          name: body.name,
          description: body.description,
          expirationDate: formatDateToUS(body.expirationDate, true),
          products: products?.map(product => ({
            productId: product.id,
            price: product.price,
            quantity: product.quantity,
          })),
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados atualizados com sucesso!' });
    } catch {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar oferta, verifique as informações inseridas e tente novamente.',
      });
    }

    setFormLoading(false);
  };

  return (
    <>
      {products !== undefined ? (
        <Fancybox active={productsFancybox}>
          <Products currentProducts={products} setState={setProducts} />
          <FancyboxFooter>
            <Row>
              <Column xl={3}>
                <CustomButtom>
                  <button
                    onClick={() => {
                      setProductsFancybox(false);
                    }}
                  >
                    SELECIONAR
                  </button>
                </CustomButtom>
              </Column>
              <Column xl={3}>
                <CustomButtom background={PrimaryTheme.background.secondary}>
                  <button
                    onClick={() => {
                      setProductsFancybox(false);
                    }}
                  >
                    CANCELAR
                  </button>
                </CustomButtom>
              </Column>
            </Row>
          </FancyboxFooter>
        </Fancybox>
      ) : (
        <></>
      )}
      <OffersContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Nome">
                <input
                  value={body.name}
                  onChange={e => {
                    setBody({ ...body, name: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Vencimento" icon={<AiOutlineCalendar />}>
                <InputMask
                  onChange={e => {
                    setBody({ ...body, expirationDate: e.target.value });
                  }}
                  placeholder={body.expirationDate}
                  mask="99/99/9999 99:99"
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Produtos">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setProductsFancybox(true);
                    }}
                  >
                    <MdOutlineContentCopy />
                    {products !== undefined && products?.length > 0
                      ? `${products?.length} Produto(s) selecionado(s)`
                      : 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={12}>
              <CustomTextarea marginBottom={true} label="Descrição">
                <textarea
                  value={body.description ?? ''}
                  onChange={e => {
                    setBody({ ...body, description: e.target.value });
                  }}
                />
              </CustomTextarea>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/ofertas');
                  }}
                  type="button"
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </OffersContainer>
    </>
  );
};

export default Offer;
