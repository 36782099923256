import styled, { keyframes } from 'styled-components';

interface LoadingContainerProps {
  background?: string;
  color?: string;
  radius?: number;
}

const spinnerAnimation = keyframes`
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const LoadingContainer = styled.div<LoadingContainerProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ background }) => background ?? 'rgba(255, 255, 255, 0.6)'};
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ radius }) => (radius !== undefined ? `${radius}px` : '0')};

  svg {
    font-size: 1.4rem;
    color: ${({ theme, color }) => color ?? theme.color.third};
    animation: ${spinnerAnimation} 1s linear infinite;
  }
`;
