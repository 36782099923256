import styled from 'styled-components';

interface CustomTextareaContainerProps {
  marginBottom?: boolean;
}

export const CustomTextareaContainer = styled.div<CustomTextareaContainerProps>`
  margin-bottom: ${({ marginBottom }) => (marginBottom ?? false ? '16px' : '0')};

  textarea {
    width: 100%;
    height: 200px;
    resize: none;
    background: ${({ theme }) => theme.background.third};
    border: 0;
    border-radius: 6px;
    padding: 12px;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.background.fifth};
    font-family: Montserrat;
  }

  textarea:focus-visible {
    outline: none;
  }

  textarea[readonly] {
    background-color: ${({ theme }) => theme.background.fourth};
  }
`;

export const CustomTextareaLabel = styled.p`
  font-weight: 300;
  font-size: 0.8rem;
  margin-bottom: 5px;
`;
