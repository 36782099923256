import styled from 'styled-components';

interface DatatableThProps {
  width?: number;
}

export const DatatableTable = styled.table<DatatableThProps>`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8rem;

  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.background.fourth};
  }
`;

export const DatatableHeader = styled.div`
  padding: 8px 0;
`;

export const DatatableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  align-items: center;
  color: ${({ theme }) => theme.background.fifth};

  p {
    font-size: 0.8rem;
  }
`;

export const DatatablePaginationContainer = styled.ul`
  display: flex;
  list-style: none;

  li {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.background.fourth};
    margin-right: 5px;
    border-radius: 3px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background 0.1s ease;
  }

  li:hover:not(.active) {
    background: ${({ theme }) => theme.background.third};
    filter:;
  }

  .active {
    background: ${({ theme }) => theme.background.fifth};
    color: #eee;
  }
`;

export const DatatableTh = styled.th<DatatableThProps>`
  width: ${({ width }) => (width != null ? `${width}px` : 'auto')};
  text-align: left;
  padding: 0 8px;
  height: 48px;
  cursor: pointer;

  svg {
    margin-left: 8px;
  }
`;

export const DatatableTd = styled.td`
  height: 48px;
  padding: 0 8px;
`;

export const DatatableRadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;

  div {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: 4px;
    font-size: 0.7rem;
    font-weight: 600;
  }
`;
