import React, { type ReactElement } from 'react';
import { CustomInputContainer, CustomInputLabel, IconContainer } from '../styles/components/input';

interface CustomInputProps {
  children: ReactElement<HTMLInputElement>;
  icon?: ReactElement;
  label?: string;
  marginBottom?: boolean;
  border?: {
    active: boolean;
    color: string;
  };
}

const CustomInput: React.FC<CustomInputProps> = ({ children, icon, border, label, marginBottom }) => {
  return (
    <>
      {label != null ? <CustomInputLabel>{label}</CustomInputLabel> : <></>}
      <CustomInputContainer icon={icon != null} marginBottom={marginBottom} border={border}>
        {icon != null ? <IconContainer>{icon}</IconContainer> : <></>}
        {children}
      </CustomInputContainer>
    </>
  );
};

export default CustomInput;
