import React, { useState, useEffect } from 'react';
import CustomButtom from '../../components/button';
import { ButtonTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import { FaTrash } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { NewsletterContainer } from '../../styles/pages/newsletter';

export interface NewsletterResponse {
  id: number;
  name: string | null;
  phone: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
}

const Newsletters: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [newsletters, setNewsletters] = useState<NewsletterResponse[]>([]);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    ApplicationApi.get<NewsletterResponse[]>('newsletter', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setNewsletters(data);
        setTableLoading(false);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <NewsletterContainer>
        {tableLoading ? <Loading /> : <></>}
        <Datatable
          exportPath="newsletter"
          columns={[
            { field: 'id', title: 'ID', width: 80 },
            { field: 'name', title: 'NOME' },
            { field: 'phone', title: 'TELEFONE' },
            { field: 'email', title: 'E-MAIL' },
            { field: 'createdAt', title: 'CADASTRO', width: 200 },
            { field: 'remove', title: 'EXCLUIR', width: 80 },
          ]}
          data={newsletters.map(newsletter => ({
            id: newsletter.id,
            name: newsletter.name,
            phone: newsletter.phone,
            email: newsletter.email,
            createdAt: new Date(newsletter.createdAt).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }),
            remove: (
              <CustomButtom background={ButtonTheme.background.error} small>
                <button
                  onClick={() => {
                    navigate(`remover/${newsletter.id}`);
                  }}
                >
                  <FaTrash />
                </button>
              </CustomButtom>
            ),
          }))}
        />
      </NewsletterContainer>
    </>
  );
};

export default Newsletters;
