// @ts-nocheck
import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { type UserPaymentResponse, type UserAddressResponse, type UserResponse } from './list';
import { UsersAddressBox, UsersContainer, UsersPaymentBox, UsersSubtitle } from '../../styles/pages/users';
import InputMask from 'react-input-mask';
import CustomSelect from '../../components/select';
import { useAuth } from '../../hooks/useAuth';
import { formatDateToBR } from '../../helpers/dateFormat';
import Select from 'react-select';

export interface UserBody {
  fullname: string;
  document: string;
  email: string;
  password?: string;
  birthdate: string;
  phone: string;
  statusId: number;
  gender?: string | null;
  workPhone?: string | null;
  homePhone?: string | null;
  userStatus?: UserStatusResponse;
  userAddresses?: UserAddressResponse[];
  userPayments?: UserPaymentResponse[];
}

interface UserStatusResponse {
  id: number;
  name: string;
}

const bodyDefaultValue = {
  fullname: '',
  birthdate: '',
  document: '',
  email: '',
  password: undefined,
  phone: '',
  statusId: 1,
};

const User: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [alerts, setAlerts] = useState<AlertsProps>();

  const [statusValue, setStatusValues] = useState<UserStatusResponse[]>([]);

  const [formLoading, setFormLoading] = useState(true);

  const [body, setBody] = useState<UserBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<UserResponse>(`usuarios/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setBody({
          ...data,
          birthdate: formatDateToBR(data.birthdate, true),
        });
        setFormLoading(false);
      })
      .catch(() => {
        setAlerts({ message: 'Não foi possível recuperar usuário', type: AlertTypes.WARNING });
        setFormLoading(false);
      });
    ApplicationApi.get<UserStatusResponse[]>(`usuarios/status`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setStatusValues(data);
        setFormLoading(false);
      })
      .catch(() => {});
  }, [id]);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch<UserResponse>(
        `usuarios/${id}/status`,
        { statusId: body.statusId, password: body.password ?? undefined },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados atualizados com sucesso!' });
    } catch {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar usuário, verifique as informações inseridas e tente novamente.',
      });
    }

    setFormLoading(false);
  };

  return (
    <>
      <UsersContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Nome completo">
                <input value={body.fullname} readOnly required />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="CPF">
                <InputMask value={body.document} mask="999.999.999-99" readOnly required />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="E-mail">
                <input type="email" value={body.email} readOnly required />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Senha">
                <input
                  type="password"
                  onChange={e => {
                    setBody({ ...body, password: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Data de nascimento">
                <InputMask value={body.birthdate} mask="99/99/9999" readOnly />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Sexo">
                <input value={body.gender ?? ''} readOnly />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Celular">
                <InputMask value={body.phone} mask="(99) 99999-9999" readOnly required />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Telefone Residencial">
                <InputMask value={body.homePhone ?? ''} mask="(99) 99999-9999" readOnly />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Telefone Comercial">
                <InputMask value={body.workPhone ?? ''} mask="(99) 99999-9999" readOnly />
              </CustomInput>
            </Column>
            <Column xl={4}>
              {body.userStatus !== undefined ? (
                <CustomSelect label="Status">
                  <Select
                    onChange={e => {
                      setBody({ ...body, statusId: e?.value ?? 1 });
                    }}
                    defaultValue={{ label: body.userStatus.name, value: body.userStatus.id }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={statusValue.map(status => ({ label: status.name, value: status.id }))}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/usuarios');
                  }}
                  type="button"
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </UsersContainer>
      {body.userAddresses !== undefined && body.userAddresses.length > 0 ? (
        <UsersContainer>
          <UsersSubtitle>Endereços</UsersSubtitle>
          <Row>
            {body.userAddresses.map((address, i) => (
              <Column xl={6} key={i}>
                <UsersAddressBox>
                  <h2>{address.nick}</h2>
                  <p>
                    {address.address}, {address.number} - {address.district}
                  </p>
                  <p>{address.complement}</p>
                  <p>
                    {address.city}/{address.state}
                  </p>
                  <p>CEP: {address.zipCode}</p>
                </UsersAddressBox>
              </Column>
            ))}
          </Row>
        </UsersContainer>
      ) : (
        <></>
      )}
      {body.userPayments !== undefined && body.userPayments.length > 0 ? (
        <UsersContainer>
          <UsersSubtitle>Pagamentos salvos</UsersSubtitle>
          <Row>
            {body.userPayments.map((payment, i) => (
              <Column xl={6} key={i}>
                <UsersPaymentBox>
                  <h2>{payment.nick}</h2>
                  <p>{payment.name}</p>
                  <p>
                    {payment.cardNumber} - {payment.flag}
                  </p>
                  <p>
                    {payment.expirationMonth}/{payment.expirationYear}
                  </p>
                </UsersPaymentBox>
              </Column>
            ))}
          </Row>
        </UsersContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default User;
