import React, { type ReactElement } from 'react';
import { CustomButtonContainer } from '../styles/components/button';

interface CustomButtonProps {
  background?: string;
  color?: string;
  children: ReactElement<HTMLButtonElement>;
  marginBottom?: boolean;
  small?: boolean;
}

const CustomButtom: React.FC<CustomButtonProps> = ({ children, background, color, marginBottom, small }) => {
  return (
    <CustomButtonContainer small={small} marginBottom={marginBottom} background={background} color={color}>
      {children}
    </CustomButtonContainer>
  );
};

export default CustomButtom;
