import React, { type SyntheticEvent, useState, useEffect } from 'react';
import { CategoriesContainer } from '../../styles/pages/categories';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import Fancybox from '../../components/fancybox';
import Icon from '../../components/icon';
import { FancyboxFooter } from '../../styles/components/fancybox';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import { FaPen, FaTrash } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { type SubcategoryResponse } from '../subcategories/create';

export interface CategoryResponse {
  id: number;
  name: string;
  icon: string;
  slug: string;
  description: string;
  subcategories: SubcategoryResponse[];
  createdAt: Date;
  updatedAt: Date;
}

export interface CategoryBody {
  name?: string;
  description?: string;
  icon?: string;
}

const bodyDefaultValue = {
  name: '',
  description: '',
  icon: '',
};

const Categories: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [categories, setCategories] = useState<CategoryResponse[]>([]);
  const [fancyboxIcon, setFancyboxIcon] = useState(false);
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const [icon, setIcon] = useState<string>();
  const [body, setBody] = useState<CategoryBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<CategoryResponse[]>('categorias', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setCategories(data);
        setTableLoading(false);
      })
      .catch(() => {});
  }, []);

  const resetFormFields = (): void => {
    setBody(bodyDefaultValue);
    setIcon(undefined);
  };

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      const { data } = await ApplicationApi.post<CategoryResponse>(
        'categorias',
        { ...body, icon },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados cadastrados com sucesso!' });
      setCategories(categories => [...categories, data]);
      // resetFormFields();
      navigate('/categorias?status=sucesso');
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível cadastrar categoria' });
    }

    setFormLoading(false);
  };

  return (
    <>
      <Fancybox active={fancyboxIcon}>
        <Icon currentIcon={icon} stateRef={setIcon} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setFancyboxIcon(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setFancyboxIcon(false);
                    setIcon(undefined);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <CategoriesContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Nome">
                <input
                  value={body.name}
                  onChange={e => {
                    setBody({ ...body, name: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={6}>
              <CustomInput marginBottom={true} label="Descrição">
                <input
                  value={body?.description}
                  onChange={e => {
                    setBody({ ...body, description: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Icone">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setFancyboxIcon(true);
                    }}
                  >
                    {icon ?? 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button onClick={resetFormFields} type="reset">
                  LIMPAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </CategoriesContainer>
      <CategoriesContainer>
        {tableLoading ? <Loading /> : <></>}
        <Datatable
          columns={[
            { field: 'id', title: 'ID', width: 80 },
            { field: 'name', title: 'NOME' },
            { field: 'createdAt', title: 'CADASTRO', width: 200 },
            { field: 'edit', title: 'EDITAR', width: 80 },
            { field: 'remove', title: 'EXCLUIR', width: 80 },
          ]}
          data={categories.map(category => ({
            id: category.id,
            name: category.name,
            createdAt: new Date(category.createdAt).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }),
            edit: (
              <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
                <button
                  onClick={() => {
                    navigate(`${category.id}`);
                  }}
                >
                  <FaPen />
                </button>
              </CustomButtom>
            ),
            remove: (
              <CustomButtom background={ButtonTheme.background.error} small>
                <button
                  onClick={() => {
                    navigate(`remover/${category.id}`);
                  }}
                >
                  <FaTrash />
                </button>
              </CustomButtom>
            ),
          }))}
        />
      </CategoriesContainer>
    </>
  );
};

export default Categories;
