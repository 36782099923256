import styled from 'styled-components';

export const OrdersContainer = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 12px;
  position: relative;
  min-height: 100px;

  h1 {
    font-size: 1rem;
    color: ${({ theme }) => theme.background.fifth};
    margin-bottom: 12px;
  }
`;

export const OrdersRemoveLabel = styled.p`
  font-size: 0.9rem;
  margin-bottom: 8px;
`;

export const OrderProductContainer = styled.div`
  display: flex;
  margin-bottom: 18px;
`;

export const OrderProductImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 6px;
  width: 80px;
  height: 80px;
  margin-right: 12px;

  img {
    max-width: 80%;
    max-height: 80%;
  }

  svg {
    font-size: 2rem;
  }
`;

export const OrderProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;

  h2 {
    font-size: 1rem;
  }

  h3 {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.color.third};
  }

  h4 {
    font-size: 0.8rem;
  }
`;

export const OrderUserContainer = styled.div`
  margin-bottom: 18px;

  h2 {
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 4px;
  }
`;

export const OrderDeliveryContainer = styled.div`
  margin-bottom: 18px;

  h2 {
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 4px;
  }
`;

export const OrderPaymentContainer = styled.div`
  margin-bottom: 18px;

  h2 {
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 4px;
  }
`;

export const OrderCheckoutContainer = styled.div`
  h2 {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 4px;
  }
`;
