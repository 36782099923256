import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import Fancybox from '../../components/fancybox';
import { FancyboxFooter } from '../../styles/components/fancybox';
import { PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Loading from '../../components/loading';
import { FaUpload } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import { Upload, type UploadResponse } from '../../components/upload';
import { type AdvertisementBody, type AdvertisementResponse } from './create';
import CustomTextarea from '../../components/textarea';
import { AdvertisementsContainer } from '../../styles/pages/advertisements';
import { useAuth } from '../../hooks/useAuth';

const bodyDefaultValue = {
  title: '',
  description: '',
  link: '',
};

const Advertisement: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [uploadFancybox, setUploadFancybox] = useState(false);
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(true);

  const [image, setImage] = useState<UploadResponse>();
  const [body, setBody] = useState<AdvertisementBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<AdvertisementResponse>(`anuncios/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setBody(data);
        setImage(data.advertisementImages?.uploads);
        setFormLoading(false);
      })
      .catch(() => {
        setAlerts({ message: 'Não foi possível recuperar anúncio', type: AlertTypes.WARNING });
        setFormLoading(false);
      });
  }, [id]);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch<AdvertisementResponse>(
        `anuncios/${id}`,
        {
          ...body,
          uploadId: image?.id,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados atualizados com sucesso!' });
    } catch {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar anúncio, verifique as informações inseridas e tente novamente.',
      });
    }

    setFormLoading(false);
  };

  return (
    <>
      <Fancybox active={uploadFancybox}>
        <Upload path="anuncios" currentUpload={image} stateRef={setImage} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setUploadFancybox(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setImage(undefined);
                    setUploadFancybox(false);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <AdvertisementsContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Título">
                <input
                  value={body.title ?? ''}
                  onChange={e => {
                    setBody({ ...body, title: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Link">
                <input
                  type="url"
                  value={body.link}
                  onChange={e => {
                    setBody({ ...body, link: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Foto">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setUploadFancybox(true);
                    }}
                  >
                    <FaUpload /> {image?.filename ?? 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={12}>
              <CustomTextarea marginBottom label="Descrição">
                <textarea
                  value={body.description ?? ''}
                  onChange={e => {
                    setBody({ ...body, description: e.target.value });
                  }}
                />
              </CustomTextarea>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/anuncios');
                  }}
                  type="button"
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </AdvertisementsContainer>
    </>
  );
};

export default Advertisement;
