import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Fancybox from '../../components/fancybox';
import Icon from '../../components/icon';
import { type AlertsProps } from '../../interfaces/global';
import { FancyboxFooter } from '../../styles/components/fancybox';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomButtom from '../../components/button';
import { CategoriesContainer } from '../../styles/pages/categories';
import { type CategoryBody, type CategoryResponse } from './create';
import ApplicationApi from '../../api/config';
import Alert, { AlertTypes } from '../../components/alerts';
import { useNavigate, useParams } from 'react-router-dom';
import CustomInput from '../../components/input';
import Loading from '../../components/loading';
import { PrimaryTheme } from '../../styles/theme';
import { useAuth } from '../../hooks/useAuth';

const bodyDefaultValue = {
  name: '',
  description: '',
  icon: '',
};

const Category: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [fancyboxIcon, setFancyboxIcon] = useState(false);
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(true);

  const [body, setBody] = useState<CategoryBody>(bodyDefaultValue);
  const [icon, setIcon] = useState<string>();

  useEffect(() => {
    ApplicationApi.get<CategoryResponse>(`categorias/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setBody(data);
        setIcon(data.icon);
        setFormLoading(false);
      })
      .catch(() => {
        setAlerts({ message: 'Não foi possível recuperar categoria', type: AlertTypes.WARNING });
        setFormLoading(false);
      });
  }, [id]);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch<CategoryResponse>(
        `categorias/${id}`,
        {
          ...body,
          icon,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados atualizados com sucesso!' });
    } catch {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar categoria, verifique as informações inseridas e tente novamente.',
      });
    }

    setFormLoading(false);
  };

  return (
    <>
      <Fancybox active={fancyboxIcon}>
        <Icon currentIcon={icon} stateRef={setIcon} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setFancyboxIcon(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setFancyboxIcon(false);
                    setIcon(undefined);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <CategoriesContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Nome">
                <input
                  value={body.name}
                  onChange={e => {
                    setBody({ ...body, name: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={6}>
              <CustomInput marginBottom={true} label="Descrição">
                <input
                  value={body.description}
                  onChange={e => {
                    setBody({ ...body, description: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Icone">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setFancyboxIcon(true);
                    }}
                  >
                    {icon ?? 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/categorias');
                  }}
                  type="reset"
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </CategoriesContainer>
    </>
  );
};

export default Category;
