import React, { createContext, useContext, useEffect, useState } from 'react';
import ApplicationApi from '../api/config';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';

interface AuthContextInterface {
  token: string | null;
  user: { id: number; name: string; avatar: string | null } | null;
  setUser: React.Dispatch<React.SetStateAction<{ id: number; name: string; avatar: string | null } | null>>;
  onLogin: (login: string, password: string) => Promise<void>;
  onLogout: () => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextInterface>({
  token: null,
  user: null,
  setUser: () => {},
  onLogin: async () => {},
  onLogout: () => {},
});

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const cookies = new Cookies();
  const tokenExists = cookies.get('APP@TOKEN') ?? null;
  const navigate = useNavigate();

  const [user, setUser] = useState<{ id: number; name: string; avatar: string | null } | null>(null);
  const [token, setToken] = useState<string | null>(tokenExists);

  useEffect(() => {
    if (token !== null) {
      const { id, name, avatar } = jwtDecode(token) as { id: number; name: string; avatar: string | null };

      setUser({ id, name, avatar });
    }
  }, [token]);

  const handleLogin = async (login: string, password: string): Promise<void> => {
    const { data } = await ApplicationApi.post<{ jwt: string }>('administradores/login', {
      login,
      password,
    });

    const date = new Date();
    date.setTime(+date + 2 * 3600000);

    const { id, name, avatar } = jwtDecode(data.jwt) as { id: number; name: string; avatar: string | null };

    setUser({ id, name, avatar });
    setToken(data.jwt);

    cookies.set('APP@TOKEN', data.jwt, { expires: date, secure: true, sameSite: 'none' });

    navigate('/dashboard');
  };

  const handleLogout = (): void => {
    cookies.remove('APP@TOKEN');
    setToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        setUser,
        onLogin: handleLogin,
        onLogout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextInterface => useContext(AuthContext);

export default AuthProvider;
