import React, { useState, type ReactNode, useEffect } from 'react';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import ApplicationApi from '../../api/config';
import { useAuth } from '../../hooks/useAuth';
import CustomButtom from '../../components/button';
import { ButtonTheme } from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaTrash } from 'react-icons/fa6';
import { type ResumeResponse } from '../../interfaces/resume';
import { ResumesContainer } from '../../styles/pages/resumes';

export default function Resumes(): ReactNode {
  const { token } = useAuth();
  const navigate = useNavigate();

  const [tableLoading, setTableLoading] = useState(true);
  const [resumes, setResumes] = useState<ResumeResponse[]>([]);

  useEffect(() => {
    const getResumes = async (): Promise<void> => {
      setTableLoading(true);

      try {
        const { data } = await ApplicationApi.get<ResumeResponse[]>('curriculos', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setResumes(data);
      } catch {}

      setTableLoading(false);
    };

    getResumes().catch(() => {});
  }, []);

  return (
    <ResumesContainer>
      {tableLoading ? <Loading /> : <></>}
      <Datatable
        columns={[
          { field: 'id', title: 'ID', width: 80 },
          { field: 'name', title: 'NOME' },
          { field: 'email', title: 'E-MAIL' },
          { field: 'phone', title: 'TELEFONE' },
          { field: 'createdAt', title: 'CADASTRO', width: 200 },
          { field: 'view', title: 'VISUALIZAR', width: 80 },
          { field: 'remove', title: 'EXCLUIR', width: 80 },
        ]}
        data={resumes.map(resume => ({
          id: resume.id,
          name: resume.name,
          email: resume.email,
          status: resume.phone,
          createdAt: new Date(resume.createdAt).toLocaleDateString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }),
          view: (
            <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
              <button
                onClick={() => {
                  navigate(`${resume.id}`);
                }}
              >
                <FaEye />
              </button>
            </CustomButtom>
          ),
          remove: (
            <CustomButtom background={ButtonTheme.background.error} small>
              <button
                onClick={() => {
                  navigate(`remover/${resume.id}`);
                }}
              >
                <FaTrash />
              </button>
            </CustomButtom>
          ),
        }))}
      />
    </ResumesContainer>
  );
}
