// @ts-nocheck
import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { type ChargebackResponse, type ChargebackBody } from '../../interfaces/chargeback';
import { ChargebacksContainer } from '../../styles/pages/chargebacks';
import CustomSelect from '../../components/select';
import Select from 'react-select';

const bodyDefaultValue = {
  orderId: undefined,
  statusId: undefined,
  reason: '',
};

const Chargeback: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(true);

  const [chargeback, setChargeback] = useState<ChargebackResponse>();
  const [body, setBody] = useState<ChargebackBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<ChargebackResponse>(`estornos/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setBody({ ...data, statusId: data.chargebackStatus.id });
        setChargeback(data);
        setFormLoading(false);
      })
      .catch(() => {
        setAlerts({ message: 'Não foi possível recuperar estorno', type: AlertTypes.WARNING });
        setFormLoading(false);
      });
  }, [id]);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch<ChargebackResponse>(`estornos/${id}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados atualizados com sucesso!' });
    } catch {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar estorno, verifique as informações inseridas e tente novamente.',
      });
    }

    setFormLoading(false);
  };

  return (
    <>
      <ChargebacksContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={4}>
              {chargeback !== undefined ? (
                <CustomSelect marginBottom={true} label="Pedido">
                  <Select
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{
                      value: chargeback.orderId,
                      label: chargeback.orders.code,
                    }}
                    placeholder="Selecione"
                    isDisabled
                  />
                </CustomSelect>
              ) : (
                <CustomSelect marginBottom={true} label="Pedido">
                  <Select
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{ label: 'Carregando...' }}
                  />
                </CustomSelect>
              )}
            </Column>
            <Column xl={4}>
              {chargeback !== undefined ? (
                <CustomSelect label="Status">
                  <Select
                    onChange={e => {
                      setBody({ ...body, statusId: e?.value ?? 1 });
                    }}
                    defaultValue={{ label: chargeback.chargebackStatus.name, value: chargeback.chargebackStatus.id }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={[
                      { label: 'Criado', value: 1 },
                      { label: 'Aprovado', value: 2 },
                    ]}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={4}>
              <CustomInput label="Motivo">
                <input
                  onChange={e => {
                    setBody({ ...body, reason: e.target.value });
                  }}
                  defaultValue={chargeback?.reason}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/estornos');
                  }}
                  type="button"
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </ChargebacksContainer>
    </>
  );
};

export default Chargeback;
