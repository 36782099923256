import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading';
import { type BannerResponse } from './create';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomButtom from '../../components/button';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import { BannersContainer, BannersRemoveLabel } from '../../styles/pages/banners';
import { useAuth } from '../../hooks/useAuth';

const DeleteBanner: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [banner, setBanner] = useState<BannerResponse>();
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [isRemoved, setIsRemoved] = useState(false);

  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    ApplicationApi.get<BannerResponse>(`banners/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setBanner(data);
        setFormLoading(false);
      })
      .catch(() => {
        setFormLoading(false);
        setAlerts({ type: AlertTypes.ERROR, message: 'Banner não encontrado' });
      });
  }, []);

  const submitForm = async (): Promise<void> => {
    setFormLoading(true);

    try {
      await ApplicationApi.delete(`banners/${id}`, { headers: { Authorization: `Bearer ${token}` } });

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Banner removido com sucesso' });
      setIsRemoved(true);
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível remover banner' });
    }

    setFormLoading(false);
  };

  return (
    <BannersContainer>
      {formLoading ? <Loading /> : <></>}
      {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
      {isRemoved ? (
        <>
          <Row>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/banners');
                  }}
                  type="button"
                >
                  VOLTAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </>
      ) : (
        <>
          {banner !== undefined ? (
            <>
              <BannersRemoveLabel>
                <b>ID:</b> {banner.id}
              </BannersRemoveLabel>
              <BannersRemoveLabel>
                <b>Título:</b> {banner.title}
              </BannersRemoveLabel>
              <BannersRemoveLabel>
                <b>Vencimento:</b> {new Date(banner.expirationDate).toLocaleDateString('pt-BR')}
              </BannersRemoveLabel>
              <Row>
                <Column xl={2}>
                  <CustomButtom background={PrimaryTheme.background.secondary}>
                    <button
                      onClick={() => {
                        navigate('/banners');
                      }}
                      type="button"
                    >
                      CANCELAR
                    </button>
                  </CustomButtom>
                </Column>
                <Column xl={2}>
                  <CustomButtom background={ButtonTheme.background.error}>
                    <button onClick={submitForm} type="button">
                      CONFIRMAR EXCLUSÃO
                    </button>
                  </CustomButtom>
                </Column>
              </Row>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </BannersContainer>
  );
};

export default DeleteBanner;
