// @ts-nocheck
import React, { type SyntheticEvent, useEffect, useState } from 'react';
import { AdministratorsContainer } from '../../styles/pages/administrators';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { FaUpload } from 'react-icons/fa6';
import Fancybox from '../../components/fancybox';
import { Upload, type UploadResponse } from '../../components/upload';
import ApplicationApi from '../../api/config';
import Loading from '../../components/loading';
import { FancyboxFooter } from '../../styles/components/fancybox';
import { PrimaryTheme } from '../../styles/theme';
import InputMask from 'react-input-mask';
import CustomSelect from '../../components/select';
import axios from 'axios';
import Alert, { AlertTypes } from '../../components/alerts';
import { type AlertsProps } from '../../interfaces/global';
import { useNavigate, useParams } from 'react-router-dom';
import { type AdministratorBody, type AdministratorResponse } from './create';
import { useAuth } from '../../hooks/useAuth';
import Select from 'react-select';

interface ZipCodeResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface CountryResponse {
  flags: {
    png: string;
    svg: string;
    alt: string;
  };
  name: { common: string; official: string };
}

const bodyDefaultValue = {
  name: '',
  phone: '',
  cellphone: '',
  document: '',
  email: '',
  login: '',
  password: '',
  address: '',
  district: '',
  zipCode: '',
  city: '',
  state: '',
  crf: '',
  facebook: '',
  instagram: '',
  cnpj: '',
  fantasy: '',
  country: 'Brazil',
};

const Administrator: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [uploadFancybox, setUploadFancybox] = useState(false);
  const [countries, setCountries] = useState<CountryResponse[]>();
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(true);

  const [profileImage, setProfileImage] = useState<UploadResponse>();
  const [body, setBody] = useState<AdministratorBody>(bodyDefaultValue);

  useEffect(() => {
    axios
      .get<CountryResponse[]>('https://restcountries.com/v3.1/all?fields=name,flags')
      .then(({ data }) => {
        setCountries(
          data.sort((a, b) => {
            return a.name.common.toLowerCase() < b.name.common.toLowerCase() ? -1 : 1;
          }),
        );
      })
      .catch(() => {});
    ApplicationApi.get<AdministratorResponse>(`administradores/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setProfileImage(data.administratorImages?.uploads);
        setBody(data);
        setFormLoading(false);
      })
      .catch(() => {});
  }, []);

  const searchCep = async (e: React.FocusEvent<HTMLInputElement, Element>): Promise<void> => {
    try {
      setFormLoading(true);
      const zipCode = e.target.value;
      const { data } = await axios.get<ZipCodeResponse>(`https://viacep.com.br/ws/${zipCode}/json`);

      setBody({ ...body, address: data.logradouro, district: data.bairro, city: data.localidade, state: data.uf });
      setFormLoading(false);
    } catch {}
  };

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch<AdministratorResponse>(
        `administradores/${id}`,
        {
          ...body,
          uploadId: profileImage?.id,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados atualizados com sucesso!' });
    } catch (err) {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar administrador, verifique as informações inseridas e tente novamente.',
      });
    }

    setFormLoading(false);
  };

  return (
    <>
      <Fancybox active={uploadFancybox}>
        <Upload path="administradores" currentUpload={profileImage} stateRef={setProfileImage} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setUploadFancybox(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setProfileImage(undefined);
                    setUploadFancybox(false);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <AdministratorsContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Nome completo">
                <input
                  value={body.name}
                  onChange={e => {
                    setBody({ ...body, name: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Telefone">
                <InputMask
                  value={body.phone}
                  type="tel"
                  mask="(99) 9999-9999"
                  onChange={e => {
                    setBody({ ...body, phone: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Celular">
                <InputMask
                  value={body.cellphone}
                  type="tel"
                  mask="(99) 99999-9999"
                  onChange={e => {
                    setBody({ ...body, cellphone: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="CPF">
                <InputMask
                  value={body.document}
                  mask="999.999.999-99"
                  onChange={e => {
                    setBody({ ...body, document: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="E-mail">
                <input
                  value={body.email}
                  type="email"
                  onChange={e => {
                    setBody({ ...body, email: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="CRF">
                <input
                  value={body.crf ?? ''}
                  onChange={e => {
                    setBody({ ...body, crf: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="CNPJ">
                <InputMask
                  value={body.cnpj ?? ''}
                  mask="99.999.999/0001-99"
                  onChange={e => {
                    setBody({ ...body, cnpj: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Nome Fantasia">
                <input
                  value={body.fantasy ?? ''}
                  onChange={e => {
                    setBody({ ...body, fantasy: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Login">
                <input
                  value={body.login}
                  onChange={e => {
                    setBody({ ...body, login: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Senha">
                <input
                  type="password"
                  onChange={e => {
                    setBody({ ...body, password: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="CEP">
                <InputMask
                  value={body.zipCode}
                  mask="99999-999"
                  onChange={e => {
                    setBody({ ...body, zipCode: e.target.value });
                  }}
                  onBlur={searchCep}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Endereço">
                <input
                  value={body?.address}
                  onChange={e => {
                    setBody({ ...body, address: e.target.value });
                  }}
                  required
                  readOnly
                />
              </CustomInput>
            </Column>
            <Column xl={1}>
              <CustomInput marginBottom={true} label="Número">
                <input
                  value={body?.number}
                  onChange={e => {
                    setBody({ ...body, number: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Bairro">
                <input
                  value={body?.district}
                  required
                  readOnly
                  onChange={e => {
                    setBody({ ...body, district: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Cidade">
                <input
                  value={body.city}
                  required
                  readOnly
                  onChange={e => {
                    setBody({ ...body, city: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={1}>
              <CustomInput marginBottom={true} label="Estado">
                <input
                  value={body.state}
                  required
                  readOnly
                  onChange={e => {
                    setBody({ ...body, state: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={1}>
              {body.country !== undefined ? (
                <CustomSelect label="País">
                  <Select
                    onChange={e => {
                      setBody({ ...body, country: e?.value ?? undefined });
                    }}
                    defaultValue={{ label: body.country, value: body.country }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    options={countries?.map(country => ({ label: country.name.common, value: country.name.common }))}
                  />
                </CustomSelect>
              ) : (
                <></>
              )}
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Facebook">
                <input
                  value={body.facebook ?? ''}
                  type="url"
                  onChange={e => {
                    setBody({ ...body, facebook: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Instagram">
                <input
                  value={body.instagram ?? ''}
                  type="url"
                  onChange={e => {
                    setBody({ ...body, instagram: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Foto">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setUploadFancybox(true);
                    }}
                  >
                    <FaUpload /> {profileImage?.filename ?? 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
          </Row>
          <Row>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/administradores');
                  }}
                  type="button"
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </AdministratorsContainer>
    </>
  );
};

export default Administrator;
