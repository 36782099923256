import React, { useState, type ReactNode, useEffect, type SyntheticEvent } from 'react';
import { ReclamationsContainer } from '../../styles/pages/reclamations';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomInput from '../../components/input';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationApi from '../../api/config';
import { type ReclamationBody, type ReclamationResponse } from '../../interfaces/reclamation';
import { useAuth } from '../../hooks/useAuth';
import CustomTextarea from '../../components/textarea';
import { ReclamtionStatus } from '../../enums/reclamation';
import { PrimaryTheme } from '../../styles/theme';
import CustomButtom from '../../components/button';

export default function Reclamation(): ReactNode {
  const { id } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();

  const [reclamation, setReclamation] = useState<ReclamationResponse>();
  const [alerts, setAlerts] = useState<AlertsProps>();
  const [formLoading, setFormLoading] = useState(false);
  const [body, setBody] = useState<ReclamationBody>({ statusId: null });

  useEffect(() => {
    const getReclamation = async (): Promise<void> => {
      setFormLoading(true);

      try {
        const { data } = await ApplicationApi.get<ReclamationResponse>(`reclamacoes/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setReclamation(data);
        setBody({ statusId: reclamation?.statusId ?? ReclamtionStatus.PENDENTE });
      } catch {}

      setFormLoading(false);
    };

    getReclamation().catch(() => {});
  }, [id]);

  const formSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch(`reclamacoes/${id}`, body, { headers: { Authorization: `Bearer ${token}` } });

      setAlerts({
        type: AlertTypes.SUCCESS,
        message: 'Status da reclamação atualizado com sucesso.',
      });
    } catch {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar o status da reclamação, tente novamente.',
      });
    }

    setFormLoading(false);
  };

  return (
    <ReclamationsContainer>
      {formLoading ? <Loading /> : <></>}
      {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
      <form onSubmit={formSubmit}>
        <Row>
          <Column xl={4}>
            <CustomInput label="Nome" marginBottom>
              <input defaultValue={reclamation?.name} readOnly />
            </CustomInput>
          </Column>
          <Column xl={4}>
            <CustomInput label="E-mail" marginBottom>
              <input defaultValue={reclamation?.email} readOnly />
            </CustomInput>
          </Column>
          <Column xl={4}>
            <CustomInput label="Telefone" marginBottom>
              <input defaultValue={reclamation?.phone ?? ''} readOnly />
            </CustomInput>
          </Column>
          <Column xl={10}>
            <CustomInput label="Assunto" marginBottom>
              <input defaultValue={reclamation?.subject} readOnly />
            </CustomInput>
          </Column>
          <Column xl={2}>
            <CustomInput label="Status" marginBottom>
              <select
                defaultValue={reclamation?.statusId}
                onChange={e => {
                  setBody({ statusId: parseInt(e.target.value) });
                }}
              >
                <option
                  value={ReclamtionStatus.PENDENTE}
                  selected={reclamation?.statusId === ReclamtionStatus.PENDENTE}
                >
                  Pendente
                </option>
                <option
                  value={ReclamtionStatus.RESOLVIDA}
                  selected={reclamation?.statusId === ReclamtionStatus.RESOLVIDA}
                >
                  Resolvida
                </option>
                <option
                  value={ReclamtionStatus.NAO_RESOLVIDA}
                  selected={reclamation?.statusId === ReclamtionStatus.NAO_RESOLVIDA}
                >
                  Não Resolvida
                </option>
              </select>
            </CustomInput>
          </Column>
          <Column xl={12}>
            <CustomTextarea marginBottom label="Detalhes">
              <textarea defaultValue={reclamation?.details} readOnly />
            </CustomTextarea>
          </Column>
          <Column xl={2}>
            <CustomButtom background={PrimaryTheme.background.secondary}>
              <button
                onClick={() => {
                  navigate('/reclamacoes');
                }}
              >
                VOLTAR
              </button>
            </CustomButtom>
          </Column>
          <Column xl={2}>
            <CustomButtom background="#28a745">
              <button type="submit">SALVAR</button>
            </CustomButtom>
          </Column>
        </Row>
      </form>
    </ReclamationsContainer>
  );
}
