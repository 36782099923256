import styled from 'styled-components';

interface ImageCardProps {
  background: string;
  active: boolean;
}

export const ImagesContainer = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 12px;
  position: relative;
  min-height: 600px;
  margin-bottom: 12px;

  input {
    display: none;
  }
`;

export const ImagesNoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
  color: ${({ theme }) => theme.background.fifth};
  cursor: pointer;

  svg {
    font-size: 3rem;
    margin-bottom: 8px;
  }

  p {
    font-weight: 600;
  }
`;

export const ImagesContenteContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 800px;
  overflow-y: auto;
`;

export const ImageCard = styled.div<ImageCardProps>`
  width: 140px;
  height: 200px;
  background: url('${({ background }) => background}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;

  div.hover {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${({ active }) => (active ? '1' : '0')};
    transition: opacity 0.1s ease;

    div {
      width: 30px;
      height: 30px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:hover .hover {
    opacity: 1;
  }
`;

export const ImageNewCard = styled.div`
  width: 140px;
  height: 200px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.background.fourth};
  transition: filter 0.1s ease;

  &:hover {
    filter: contrast(1.1);
  }

  svg {
    color: ${({ theme }) => theme.background.fifth};
    font-size: 2rem;
  }
`;
