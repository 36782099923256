import { styled } from 'styled-components';

export const ProductsContainer = styled.div`
  width: calc(100vw - 300px);
  height: 380px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding: 12px;
  background: ${({ theme }) => theme.background.third};
`;

export const ProductsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 66px;

  div {
    display: flex;
  }

  h1 {
    font-size: 0.9rem;
  }
`;

export const ProductItemBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductItem = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.background.fourth};
  border-radius: 6px;
  padding: 0 12px;
  margin-bottom: 8px;
  cursor: pointer;

  svg {
    font-size: 1rem;
    color: ${({ theme }) => theme.color.third};
  }

  div {
    h1 {
      font-size: 0.7rem;
    }
    h2 {
      font-size: 0.7rem;
      color: ${({ theme }) => theme.color.third};
      font-weight: 400;
    }
  }
`;

export const ProductPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.background.fourth};
  border-radius: 6px;
  margin-bottom: 8px;

  p {
    font-size: 0.7rem;
    font-weight: 600;
    color: ${({ theme }) => theme.color.third};
  }
`;

export const ProductSortContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.color.third};

  svg {
    cursor: pointer;
  }
`;
