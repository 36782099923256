import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import { FaPen, FaTrash } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { FastDeliveriesContainer } from '../../styles/pages/fastDeliveries';
import CurrencyInput from 'react-currency-input';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { formatDecimal, formatMoney } from '../../helpers/numberMethods';
import { useAuth } from '../../hooks/useAuth';
import { BsCurrencyDollar } from 'react-icons/bs';

export interface FastDeliveryResponse {
  id: number;
  nick: string | null;
  price: string;
  deliveryTime: number;
  zipCode: string;
  address: string;
  district: string;
  city: string;
  state: string;
  updatedAt: Date;
  createdAt: Date;
}

export interface FastDeliveryBody {
  nick?: string | null;
  price: string;
  deliveryTime: number;
  zipCode: string;
  address: string;
  district: string;
  city: string;
  state: string;
}

export interface ZipCodeResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

const bodyDefaultValue = {
  nick: '',
  price: '',
  zipCode: '',
  address: '',
  deliveryTime: 0,
  district: '',
  city: '',
  state: '',
};

const FastDeliveries: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [fastDeliveries, setFastDeliveries] = useState<FastDeliveryResponse[]>([]);

  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const [body, setBody] = useState<FastDeliveryBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<FastDeliveryResponse[]>('entregas-rapidas', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setFastDeliveries(data);
        setTableLoading(false);
      })
      .catch(() => {});
  }, []);

  const searchCep = async (e: React.FocusEvent<HTMLInputElement, Element>): Promise<void> => {
    try {
      setFormLoading(true);
      const zipCode = e.target.value;
      const { data } = await axios.get<ZipCodeResponse>(`https://viacep.com.br/ws/${zipCode}/json`);

      setBody({ ...body, address: data.logradouro, district: data.bairro, city: data.localidade, state: data.uf });
      setFormLoading(false);
    } catch {}
  };

  const resetFormFields = (): void => {
    setBody(bodyDefaultValue);
  };

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      const { data } = await ApplicationApi.post<FastDeliveryResponse>(
        'entregas-rapidas',
        {
          ...body,
          price: formatDecimal(body.price),
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados cadastrados com sucesso!' });
      setFastDeliveries(fastDelivery => [...fastDelivery, data]);
      resetFormFields();
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível cadastrar entrega rápida' });
    }

    setFormLoading(false);
  };

  return (
    <>
      <FastDeliveriesContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Nome">
                <input
                  value={body.nick ?? ''}
                  onChange={e => {
                    setBody({ ...body, nick: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Prazo em minutos">
                <InputMask
                  value={body.deliveryTime}
                  mask="999"
                  onChange={e => {
                    setBody({ ...body, deliveryTime: parseInt(e.target.value) });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={1}>
              <CustomInput marginBottom={true} label="Preço" icon={<BsCurrencyDollar />}>
                <CurrencyInput
                  onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBody({ ...body, price: e.target.value });
                  }}
                  value={body.price}
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="CEP">
                <InputMask
                  value={body.zipCode}
                  mask="99999-999"
                  onChange={e => {
                    setBody({ ...body, zipCode: e.target.value });
                  }}
                  onBlur={searchCep}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Endereço">
                <input
                  value={body?.address}
                  onChange={e => {
                    setBody({ ...body, address: e.target.value });
                  }}
                  required
                  readOnly
                />
              </CustomInput>
            </Column>
            <Column xl={5}>
              <CustomInput marginBottom={true} label="Bairro">
                <input
                  value={body?.district}
                  required
                  readOnly
                  onChange={e => {
                    setBody({ ...body, district: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Cidade">
                <input
                  value={body?.city}
                  required
                  readOnly
                  onChange={e => {
                    setBody({ ...body, city: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Estado">
                <input
                  value={body?.state}
                  required
                  readOnly
                  onChange={e => {
                    setBody({ ...body, state: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button onClick={resetFormFields} type="reset">
                  LIMPAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </FastDeliveriesContainer>
      <FastDeliveriesContainer>
        {tableLoading ? <Loading /> : <></>}
        <Datatable
          columns={[
            { field: 'id', title: 'ID', width: 80 },
            { field: 'name', title: 'NOME' },
            { field: 'zipCode', title: 'CEP' },
            { field: 'price', title: 'PREÇO' },
            { field: 'createdAt', title: 'CADASTRO', width: 200 },
            { field: 'edit', title: 'EDITAR', width: 80 },
            { field: 'remove', title: 'EXCLUIR', width: 80 },
          ]}
          data={fastDeliveries.map(fastDelivery => ({
            id: fastDelivery.id,
            name: fastDelivery.nick,
            zipCode: fastDelivery.zipCode,
            price: formatMoney(fastDelivery.price.toString()),
            createdAt: new Date(fastDelivery.createdAt).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }),
            edit: (
              <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
                <button
                  onClick={() => {
                    navigate(`${fastDelivery.id}`);
                  }}
                >
                  <FaPen />
                </button>
              </CustomButtom>
            ),
            remove: (
              <CustomButtom background={ButtonTheme.background.error} small>
                <button
                  onClick={() => {
                    navigate(`remover/${fastDelivery.id}`);
                  }}
                >
                  <FaTrash />
                </button>
              </CustomButtom>
            ),
          }))}
        />
      </FastDeliveriesContainer>
    </>
  );
};

export default FastDeliveries;
