// @ts-nocheck
import React, { type SyntheticEvent, useState, useEffect, useRef } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import { FaPen, FaTrash } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { ProductsContainer, TextareaContainer } from '../../styles/pages/products';
import CustomSelect from '../../components/select';
import CurrencyInput from 'react-currency-input';
import { type CategoryResponse } from '../categories/create';
import InputMask from 'react-input-mask';
import { UploadDocument, UploadMultiple, type UploadResponse } from '../../components/upload';
import Fancybox from '../../components/fancybox';
import { FancyboxFooter } from '../../styles/components/fancybox';
import { type BrandResponse } from '../brands/create';
import { BsCurrencyDollar } from 'react-icons/bs';
import { formatDecimal } from '../../helpers/numberMethods';
import { useAuth } from '../../hooks/useAuth';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { type SubcategoryResponse } from '../subcategories/create';
import { DatatableFooter, DatatablePaginationContainer } from '../../styles/components/datatable';
import { FaSearch } from 'react-icons/fa';
import Select from 'react-select';

export interface ProductResponse {
  id: number;
  name: string;
  code: string;
  ean: string;
  price: string;
  newPrice: string | null;
  recipeId: number;
  description: string;
  activePrinciple: string;
  ms: string;
  especifications: string;
  slug: string;
  statusId: number;
  productDocuments: { uploads: UploadResponse } | null;
  productImages: Array<{ uploads: UploadResponse }>;
  categories: CategoryResponse | null;
  subcategories: SubcategoryResponse | null;
  productRoutes: ProductRoutesOfAdministrationResponse | null;
  productStrips: ProductStripsResponse | null;
  productRecipes: ProductRecipesResponse | null;
  productTypes: ProductTypesResponse | null;
  productClasses: ProductClassesResponse | null;
  productStatus: ProductStatusResponse;
  brands: { id: number; name: string } | null;
  categoryId: number | null;
  subcategoryId: number | null;
  stock: number;
  createdAt: Date;
  updatedAt: Date;
  quantity?: number;
  promotionProducts: Array<{
    id: number;
    promotionId: number;
    productId: number;
    price: string;
  }>;
}

export interface ProductPaginationResponse {
  data: ProductResponse[];
  currentPage: number;
  pagesCount: number;
  totalItems: number;
}

export interface ProductBody {
  name: string;
  price: string;
  newPrice?: string;
  recipeId: number;
  ean?: string;
  description?: string;
  especifications?: string;
  activePrinciple?: string;
  routeId?: number;
  stripId?: number;
  typeId?: number;
  classId?: number;
  ms?: string;
  width?: number;
  height?: number;
  length?: number;
  weight?: number;
  brandId?: number;
  imagesIds?: number[];
  categoryId?: number | null;
  subcategoryId?: number;
  stock?: number;
  statusId: number;
  categories?: CategoryResponse | null;
  subcategories?: SubcategoryResponse | null;
  productRoutes?: ProductRoutesOfAdministrationResponse | null;
  productStrips?: ProductStripsResponse | null;
  productRecipes?: ProductRecipesResponse | null;
  productTypes?: ProductTypesResponse | null;
  productClasses?: ProductClassesResponse | null;
  productStatus?: ProductStatusResponse;
  brands?: { id: number; name: string } | null;
  slug?: string;
  promotionProducts?: Array<{
    id: number;
    promotionId: number;
    productId: number;
    price: string;
  }>;
}

export interface ProductRecipesResponse {
  id: number;
  name: string;
  description: string | null;
}

export interface ProductRoutesOfAdministrationResponse extends ProductRecipesResponse {}
export interface ProductClassesResponse extends ProductRecipesResponse {}
export interface ProductStripsResponse extends ProductRecipesResponse {}
export interface ProductTypesResponse extends ProductRecipesResponse {}
export interface ProductStatusResponse extends ProductRecipesResponse {}

const bodyDefaultValue = {
  name: '',
  price: '0,00',
  newPrice: '0,00',
  recipeId: 1,
  typeId: undefined,
  brandId: undefined,
  classId: undefined,
  stripId: undefined,
  routeId: undefined,
  categoryId: undefined,
  subcategoryId: undefined,
  especifications: undefined,
  ms: '',
  activePrinciple: '',
  description: undefined,
  ean: '',
  stock: 0,
  statusId: 1,
};

const Products: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [alerts, setAlerts] = useState<AlertsProps>();
  const [uploadFancybox, setUploadFancybox] = useState(false);
  const [documentUploadFancybox, setDocumentUploadFancybox] = useState(false);

  const [products, setProducts] = useState<ProductResponse[]>([]);
  const [recipes, setRecipes] = useState<ProductRecipesResponse[]>([]);
  const [routes, setRoutes] = useState<ProductRoutesOfAdministrationResponse[]>([]);
  const [classes, setClasses] = useState<ProductClassesResponse[]>([]);
  const [strips, setStrips] = useState<ProductStripsResponse[]>([]);
  const [types, setTypes] = useState<ProductTypesResponse[]>([]);
  const [status, setStatus] = useState<ProductStatusResponse[]>([]);
  const [categories, setCategoris] = useState<CategoryResponse[]>([]);
  const [brands, setBrands] = useState<BrandResponse[]>([]);

  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const [productImages, setProductImages] = useState<UploadResponse[]>();
  const [productDocument, setProductDocument] = useState<UploadResponse>();
  const [description, setDescription] = useState<string>('');
  const [especifications, setEspecifications] = useState<string>('');
  const [body, setBody] = useState<ProductBody>(bodyDefaultValue);

  const [limit] = useState(15);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [initPage, setInitPage] = useState(1);
  const [endPage, setEndPage] = useState(15);

  const [inputSearch, setInputSearch] = useState<string>();

  const [checkIds, setCheckIds] = useState<Array<{ id: number }>>([]);
  const [refreshData, setRefreshData] = useState(0);

  const [filterStatus, setFilterStatus] = useState<string>('');

  useEffect(() => {
    ApplicationApi.get<ProductRecipesResponse[]>('produtos/receitas', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setRecipes(data);
      })
      .catch(() => {});

    ApplicationApi.get<ProductRoutesOfAdministrationResponse[]>('produtos/administracao', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setRoutes(data);
      })
      .catch(() => {});

    ApplicationApi.get<ProductClassesResponse[]>('produtos/classes', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setClasses(data);
      })
      .catch(() => {});

    ApplicationApi.get<ProductStatusResponse[]>('produtos/status', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setStatus(data);
      })
      .catch(() => {});

    ApplicationApi.get<ProductClassesResponse[]>('produtos/tarjas', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setStrips(data);
      })
      .catch(() => {});

    ApplicationApi.get<ProductClassesResponse[]>('produtos/tipos', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setTypes(data);
      })
      .catch(() => {});

    ApplicationApi.get<CategoryResponse[]>('categorias', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setCategoris(data);
      })
      .catch(() => {});

    ApplicationApi.get<BrandResponse[]>('marcas', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setBrands(data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    setTableLoading(true);
    if (inputSearch === undefined) {
      ApplicationApi.get<ProductPaginationResponse>(
        `produtos/administracao/paginacao?limite=${limit}&pagina=${page}&status=${filterStatus}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
        .then(({ data }) => {
          setProducts(data.data);
          setPages(data.pagesCount);
          setTotalItems(data.totalItems);
          setTableLoading(false);
        })
        .catch(() => {});
    } else {
      void searchKeyword();
    }
  }, [page, refreshData]);

  useEffect(() => {
    if (page >= endPage - 1) {
      if (page + 1 >= endPage && page + 1 < pages) {
        setEndPage(endPage + 1);
        setInitPage(initPage + 1);
      }
    } else {
      if (page > 15) {
        setInitPage(initPage - 1);
        setEndPage(endPage - 1);
      } else {
        setInitPage(1);
        setEndPage(15);
      }
    }
  }, [page, refreshData]);

  const resetFormFields = (): void => {
    setBody(bodyDefaultValue);
    setProductImages(undefined);

    if (editorDescriptionRef.current.editor !== undefined) {
      editorDescriptionRef.current.editor.setData('');
    }
    if (editorEspecificationRef.current.editor !== undefined) {
      editorEspecificationRef.current.editor.setData('');
    }
  };

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      const { data } = await ApplicationApi.post<ProductResponse>(
        'produtos',
        {
          ...body,
          description,
          especifications,
          price: formatDecimal(body.price),
          newPrice: body.newPrice !== undefined && body.newPrice !== '' ? formatDecimal(body.newPrice) : null,
          imagesIds: productImages?.map(value => value.id),
          documentId: productDocument?.id,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados cadastrados com sucesso!' });
      setProducts(products => [...products, data]);
      resetFormFields();
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível cadastrar produto' });
    }

    setFormLoading(false);
  };

  const searchKeyword = async (e?: SyntheticEvent): Promise<void> => {
    if (e !== undefined) {
      e.preventDefault();
    }

    if (inputSearch !== undefined) {
      setTableLoading(true);

      try {
        const { data } = await ApplicationApi.get<ProductPaginationResponse>(
          inputSearch === ''
            ? `produtos/administracao/paginacao?limite=${limit}&pagina=${page}&status=${filterStatus}`
            : `produtos/busca?keyword=${inputSearch}&limite=${limit}&pagina=${page}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (page > data.pagesCount) {
          setPage(1);
        }

        setProducts(data.data);
        setPages(data.pagesCount);
        setTotalItems(data.totalItems);
        setTableLoading(false);
      } catch {}

      setTableLoading(false);
    } else {
      setPage(1);
    }
  };

  const excludeSelecteds = async (): Promise<void> => {
    setTableLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-for-in-array
      for (const x in checkIds) {
        try {
          await ApplicationApi.delete(`produtos/${checkIds[x].id}`, { headers: { Authorization: `Bearer ${token}` } });
        } catch {}
      }
      setRefreshData(refreshData + 1);
    } catch {}
    setTableLoading(false);
  };

  const approveErpProducts = async (): Promise<void> => {
    setTableLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-for-in-array
      for (const x in checkIds) {
        try {
          await ApplicationApi.patch(
            `produtos/${checkIds[x].id}/aprovar`,
            {},
            { headers: { Authorization: `Bearer ${token}` } },
          );
        } catch {}
        setRefreshData(refreshData + 1);
      }
    } catch {}
    setTableLoading(false);
  };

  const filterByStatus = (status: string): void => {
    setFilterStatus(status);
    setRefreshData(refreshData + 1);
  };

  const toggleAllCheckbox = (checked: boolean): void => {
    if (checked) {
      setCheckIds(products.map(product => ({ id: product.id })));
    } else {
      setCheckIds([]);
    }
  };

  const editorDescriptionRef = useRef<any>(null);
  const editorEspecificationRef = useRef<any>(null);

  return (
    <>
      <Fancybox active={documentUploadFancybox}>
        <UploadDocument currentUpload={productDocument} stateRef={setProductDocument} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setDocumentUploadFancybox(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setProductDocument(undefined);
                    setDocumentUploadFancybox(false);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <Fancybox active={uploadFancybox}>
        <UploadMultiple
          ean={body.ean !== '' ? body.ean : undefined}
          path="produtos"
          currentUpload={productImages}
          stateRef={setProductImages}
        />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setUploadFancybox(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setProductImages(uploads => []);
                    setUploadFancybox(false);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <ProductsContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Nome">
                <input
                  value={body.name}
                  onChange={e => {
                    setBody({ ...body, name: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={1}>
              <CustomInput marginBottom={true} label="Preço" icon={<BsCurrencyDollar />}>
                <CurrencyInput
                  onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBody({ ...body, price: e.target.value });
                  }}
                  value={body.price}
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </CustomInput>
            </Column>
            <Column xl={1}>
              <CustomInput marginBottom={true} label="Promoção" icon={<BsCurrencyDollar />}>
                <CurrencyInput
                  onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBody({ ...body, newPrice: e.target.value });
                  }}
                  value={body.newPrice}
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="EAN">
                <input
                  value={body.ean}
                  onChange={e => {
                    setBody({ ...body, ean: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Princípio ativo">
                <input
                  value={body.activePrinciple}
                  onChange={e => {
                    setBody({ ...body, activePrinciple: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={3}>
              {routes.length > 0 ? (
                <CustomSelect label="Forma de administração">
                  <Select
                    onChange={e => {
                      setBody({ ...body, routeId: e?.value ?? undefined });
                    }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{
                      value: body.routeId,
                      label: routes.filter(route => route.id === body.routeId)[0]?.name ?? 'Selecione',
                    }}
                    placeholder="Selecione"
                    options={routes.map(route => ({ label: route.name, value: route.id }))}
                  />
                </CustomSelect>
              ) : (
                <CustomSelect label="Forma de administração">
                  <Select
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{ label: 'Selecione' }}
                  />
                </CustomSelect>
              )}
            </Column>
            <Column xl={2}>
              {strips.length > 0 ? (
                <CustomSelect label="Classificação">
                  <Select
                    onChange={e => {
                      setBody({ ...body, stripId: e?.value ?? undefined });
                    }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    value={{
                      value: body.stripId,
                      label: strips.filter(strip => strip.id === body.stripId)[0]?.name ?? 'Selecione',
                    }}
                    options={strips.map(strip => ({ label: strip.name, value: strip.id }))}
                  />
                </CustomSelect>
              ) : (
                <CustomSelect label="Classificação">
                  <Select
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{ label: 'Selecione' }}
                  />
                </CustomSelect>
              )}
            </Column>
            <Column xl={2}>
              {classes.length > 0 ? (
                <CustomSelect label="Classe de medicamento">
                  <Select
                    onChange={e => {
                      setBody({ ...body, classId: e?.value ?? undefined });
                    }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{
                      value: body.classId,
                      label: classes.filter(clas => clas.id === body.classId)[0]?.name ?? 'Selecione',
                    }}
                    placeholder="Selecione"
                    options={classes.map(clas => ({ label: clas.name, value: clas.id }))}
                  />
                </CustomSelect>
              ) : (
                <CustomSelect label="Classe de medicamento">
                  <Select
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{ label: 'Selecione' }}
                  />
                </CustomSelect>
              )}
            </Column>
            <Column xl={2}>
              <CustomSelect label="Prescrição médica">
                {recipes.length > 0 ? (
                  <Select
                    onChange={e => {
                      setBody({ ...body, recipeId: e?.value ?? 1 });
                    }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    value={{
                      value: recipes.filter(recipe => recipe.id === body.recipeId)[0].id,
                      label: recipes.filter(recipe => recipe.id === body.recipeId)[0].name,
                    }}
                    options={recipes.map(recipe => ({ label: recipe.name, value: recipe.id }))}
                  />
                ) : (
                  <></>
                )}
              </CustomSelect>
            </Column>
            <Column xl={2}>
              {types.length > 0 ? (
                <CustomSelect label="Tipo de medicamento">
                  <Select
                    onChange={e => {
                      setBody({ ...body, typeId: e?.value ?? undefined });
                    }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{
                      value: body.typeId,
                      label: types.filter(type => type.id === body.typeId)[0]?.name ?? 'Selecione',
                    }}
                    placeholder="Selecione"
                    options={types.map(type => ({ label: type.name, value: type.id }))}
                  />
                </CustomSelect>
              ) : (
                <CustomSelect label="Tipo de medicamento">
                  <Select
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{ label: 'Selecione' }}
                  />
                </CustomSelect>
              )}
            </Column>
            <Column xl={2}>
              {brands.length > 0 ? (
                <CustomSelect label="Marca">
                  <Select
                    onChange={e => {
                      setBody({ ...body, brandId: e?.value ?? undefined });
                    }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    value={{
                      value: body.brandId,
                      label: brands.filter(brand => brand.id === body.brandId)[0]?.name ?? 'Selecione',
                    }}
                    options={brands.map(brand => ({ label: brand.name, value: brand.id }))}
                  />
                </CustomSelect>
              ) : (
                <CustomSelect label="Marca">
                  <Select
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{ label: 'Selecione' }}
                  />
                </CustomSelect>
              )}
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Registro MS">
                <input
                  value={body.ms}
                  onChange={e => {
                    setBody({ ...body, ms: e.target.value });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Largura">
                <InputMask
                  value={body.width}
                  mask="999"
                  onChange={e => {
                    setBody({ ...body, width: parseInt(e.target.value) });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Altura">
                <InputMask
                  value={body.height}
                  mask="999"
                  onChange={e => {
                    setBody({ ...body, height: parseInt(e.target.value) });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Comprimento">
                <InputMask
                  value={body.length}
                  mask="999"
                  onChange={e => {
                    setBody({ ...body, length: parseInt(e.target.value) });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Peso">
                <InputMask
                  value={body.weight}
                  mask="999"
                  onChange={e => {
                    setBody({ ...body, weight: parseInt(e.target.value) });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              {categories.length > 0 ? (
                <CustomSelect label="Categoria">
                  <Select
                    onChange={e => {
                      setBody({ ...body, categoryId: e?.value ?? undefined });
                    }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    value={{
                      value: body.categoryId,
                      label: categories.filter(category => category.id === body.categoryId)[0]?.name ?? 'Selecione',
                    }}
                    options={categories.map(category => ({ label: category.name, value: category.id }))}
                  />
                </CustomSelect>
              ) : (
                <CustomSelect label="Categoria">
                  <Select
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{ label: 'Selecione' }}
                  />
                </CustomSelect>
              )}
            </Column>
            <Column xl={4}>
              {categories.length > 0 ? (
                <CustomSelect label="Subcategoria">
                  <Select
                    onChange={e => {
                      setBody({ ...body, subcategoryId: e?.value ?? undefined });
                    }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    placeholder="Selecione"
                    value={{
                      value: body.subcategoryId,
                      label:
                        categories
                          .filter(item => item.id === body.categoryId)[0]
                          ?.subcategories.filter(sub => sub.id === body.subcategoryId)[0]?.name ?? 'Selecione',
                    }}
                    options={categories
                      .filter(item => item.id === body.categoryId)[0]
                      ?.subcategories.map((subcategory, i) => ({ label: subcategory.name, value: subcategory.id }))}
                  />
                </CustomSelect>
              ) : (
                <CustomSelect label="Subcategoria">
                  <Select
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{ label: 'Selecione' }}
                  />
                </CustomSelect>
              )}
            </Column>
            <Column xl={1}>
              <CustomInput marginBottom={true} label="Estoque">
                <input
                  value={body.stock}
                  onChange={e => {
                    setBody({ ...body, stock: parseInt(e.target.value) });
                  }}
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              {status.length > 0 ? (
                <CustomSelect label="Status">
                  <Select
                    onChange={e => {
                      setBody({ ...body, statusId: e?.value ?? 1 });
                    }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{
                      value: body.statusId,
                      label: status.filter(stat => stat.id === body.statusId)[0]?.name ?? 'Selecione',
                    }}
                    placeholder="Selecione"
                    options={status.map(stat => ({ label: stat.name, value: stat.id }))}
                  />
                </CustomSelect>
              ) : (
                <CustomSelect label="Status">
                  <Select
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{ label: 'Selecione' }}
                  />
                </CustomSelect>
              )}
            </Column>
            <Column xl={2}>
              <CustomInput marginBottom={true} label="Bula">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setDocumentUploadFancybox(true);
                    }}
                  >
                    {productDocument !== undefined ? `${productDocument.filename}` : 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={3}>
              <CustomInput marginBottom={true} label="Fotos">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setUploadFancybox(true);
                    }}
                  >
                    {productImages !== undefined && productImages.length > 0
                      ? `${productImages.length} SELECIONADO(S)`
                      : 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={6}>
              <TextareaContainer>
                <p>Descrição</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={body.description}
                  ref={editorDescriptionRef}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setDescription(data);
                  }}
                />
              </TextareaContainer>
            </Column>
            <Column xl={6}>
              <TextareaContainer>
                <p>Especificações</p>
                <CKEditor
                  editor={ClassicEditor}
                  ref={editorEspecificationRef}
                  data={body.especifications}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setEspecifications(data);
                  }}
                />
              </TextareaContainer>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button onClick={resetFormFields} type="reset">
                  LIMPAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </ProductsContainer>
      <ProductsContainer>
        {tableLoading ? <Loading /> : <></>}
        <Datatable
          searchElement={
            <form onSubmit={searchKeyword}>
              <Row>
                <Column xl={11}>
                  <CustomInput>
                    <input
                      onChange={e => {
                        setInputSearch(e.target.value);
                      }}
                      type="search"
                      placeholder="Pesquisar..."
                    />
                  </CustomInput>
                </Column>
                <Column xl={1}>
                  <CustomButtom>
                    <button type="submit">
                      <FaSearch />
                    </button>
                  </CustomButtom>
                </Column>
              </Row>
            </form>
          }
          pagination={false}
          exportPath="produtos"
          columns={[
            { field: 'select', title: '', width: 40 },
            { field: 'id', title: 'ID', width: 80 },
            { field: 'name', title: 'NOME' },
            { field: 'stock', title: 'ESTOQUE' },
            { field: 'status', title: 'STATUS' },
            { field: 'createdAt', title: 'CADASTRO', width: 200 },
            { field: 'edit', title: 'EDITAR', width: 80 },
            { field: 'remove', title: 'EXCLUIR', width: 80 },
          ]}
          data={products.map(product => ({
            select: (
              <input
                type="checkbox"
                checked={checkIds.some(ids => ids.id === product.id)}
                onChange={e => {
                  e.target.checked
                    ? setCheckIds([...checkIds, { id: product.id }])
                    : setCheckIds(checkIds.filter(ids => ids.id !== product.id));
                }}
              />
            ),
            id: product.id,
            name: product.name,
            stock: product.stock,
            status: product.productStatus.name,
            createdAt: new Date(product.createdAt).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }),
            edit: (
              <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
                <button
                  onClick={() => {
                    navigate(`${product.id}`);
                  }}
                >
                  <FaPen />
                </button>
              </CustomButtom>
            ),
            remove: (
              <CustomButtom background={ButtonTheme.background.error} small>
                <button
                  onClick={() => {
                    navigate(`remover/${product.id}`);
                  }}
                >
                  <FaTrash />
                </button>
              </CustomButtom>
            ),
          }))}
          clearSelecteds={() => {
            setCheckIds([]);
          }}
          excludeSelecteds={excludeSelecteds}
          approveErpProducts={approveErpProducts}
          filterByStatus={filterByStatus}
          currentFilterStatus={filterStatus}
          toggleCheckbox={toggleAllCheckbox}
        >
          <DatatableFooter>
            <DatatablePaginationContainer>
              {initPage > 1 ? (
                <>
                  <li
                    onClick={() => {
                      setPage(1);
                    }}
                    className={page === initPage ? 'active' : ''}
                  >
                    {1}
                  </li>
                  <li>...</li>
                </>
              ) : (
                <></>
              )}
              <li
                onClick={() => {
                  setPage(initPage);
                }}
                className={page === initPage ? 'active' : ''}
              >
                {initPage}
              </li>
              {Array.apply(null, Array(pages))
                .slice(initPage - 1, endPage)
                .map((_, i) => {
                  const pg = i + initPage;

                  if (![initPage, endPage].includes(pg)) {
                    return (
                      <li
                        onClick={() => {
                          setPage(pg);
                        }}
                        className={page === pg ? 'active' : ''}
                        key={i}
                      >
                        {pg}
                      </li>
                    );
                  }

                  return <></>;
                })}
              {pages >= endPage ? (
                <li
                  onClick={() => {
                    setPage(endPage);
                  }}
                  className={page === endPage ? 'active' : ''}
                >
                  {endPage}
                </li>
              ) : (
                <></>
              )}
              {pages > endPage ? (
                <>
                  <li>...</li>
                  <li
                    onClick={() => {
                      setPage(pages);
                      setInitPage(pages - 15);
                      setEndPage(pages);
                    }}
                    className={page === pages ? 'active' : ''}
                  >
                    {pages}
                  </li>
                </>
              ) : (
                <></>
              )}
            </DatatablePaginationContainer>

            <p>
              Mostrando {limit >= products.length ? products.length : limit} de {totalItems} registro(s)
            </p>
          </DatatableFooter>
        </Datatable>
      </ProductsContainer>
    </>
  );
};

export default Products;
