import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { type CuponBody, type CuponResponse } from './create';
import { CuponsContainer } from '../../styles/pages/cupons';
import InputMask from 'react-input-mask';
import CustomTextarea from '../../components/textarea';
import { formatDateToBR, formatDateToUS } from '../../helpers/dateFormat';
import { useAuth } from '../../hooks/useAuth';
import { AiOutlineCalendar, AiOutlinePercentage } from 'react-icons/ai';

const bodyDefaultValue = {
  name: '',
  description: '',
  expirationDate: '',
  discount: 0,
};

const Cupon: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(true);

  const [body, setBody] = useState<CuponBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<CuponResponse>(`cupons/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setBody({
          ...data,
          expirationDate: formatDateToBR(
            new Date(data.expirationDate).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
            }),
          ),
        });
        setFormLoading(false);
      })
      .catch(() => {
        setAlerts({ message: 'Não foi possível recuperar cupom', type: AlertTypes.WARNING });
        setFormLoading(false);
      });
  }, [id]);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch<CuponResponse>(
        `cupons/${id}`,
        { ...body, expirationDate: formatDateToUS(body.expirationDate, true) },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados atualizados com sucesso!' });
    } catch {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar cupom, verifique as informações inseridas e tente novamente.',
      });
    }

    setFormLoading(false);
  };

  return (
    <>
      <CuponsContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Nome">
                <input
                  value={body.name}
                  onChange={e => {
                    setBody({ ...body, name: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Vencimento" icon={<AiOutlineCalendar />}>
                <InputMask
                  onChange={e => {
                    setBody({ ...body, expirationDate: e.target.value });
                  }}
                  placeholder={body.expirationDate}
                  mask="99/99/9999 99:99"
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Desconto" icon={<AiOutlinePercentage />}>
                <input
                  value={body.discount}
                  onChange={e => {
                    setBody({ ...body, discount: parseInt(e.target.value) });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={12}>
              <CustomTextarea marginBottom label="Descrição">
                <textarea
                  value={body.description ?? ''}
                  onChange={e => {
                    setBody({ ...body, description: e.target.value });
                  }}
                />
              </CustomTextarea>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/cupons');
                  }}
                  type="button"
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </CuponsContainer>
    </>
  );
};

export default Cupon;
