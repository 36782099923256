import React, { useState, type ReactNode, useEffect } from 'react';
import Row from '../../components/row';
import Column from '../../components/column';
import CustomInput from '../../components/input';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationApi from '../../api/config';
import { useAuth } from '../../hooks/useAuth';
import { PrimaryTheme } from '../../styles/theme';
import CustomButtom from '../../components/button';
import { type ResumeResponse } from '../../interfaces/resume';
import { ResumesContainer } from '../../styles/pages/resumes';
import { FaDownload } from 'react-icons/fa6';

export default function Resume(): ReactNode {
  const { id } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();

  const [resume, setResume] = useState<ResumeResponse>();
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    const getResume = async (): Promise<void> => {
      setFormLoading(true);

      try {
        const { data } = await ApplicationApi.get<ResumeResponse>(`curriculos/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setResume(data);
      } catch {}

      setFormLoading(false);
    };

    getResume().catch(() => {});
  }, [id]);

  return (
    <ResumesContainer>
      {formLoading ? <Loading /> : <></>}
      <form>
        <Row>
          <Column xl={4}>
            <CustomInput label="Nome" marginBottom>
              <input defaultValue={resume?.name} readOnly />
            </CustomInput>
          </Column>
          <Column xl={4}>
            <CustomInput label="E-mail" marginBottom>
              <input defaultValue={resume?.email} readOnly />
            </CustomInput>
          </Column>
          <Column xl={4}>
            <CustomInput label="Telefone" marginBottom>
              <input defaultValue={resume?.phone ?? ''} readOnly />
            </CustomInput>
          </Column>
          <Column xl={9}>
            <CustomInput label="Cargo" marginBottom>
              <input defaultValue={resume?.role} readOnly />
            </CustomInput>
          </Column>
          <Column xl={3}>
            <CustomInput label="Currículo" marginBottom>
              <CustomButtom>
                <button type="button" onClick={() => window.open(resume?.resumeDocuments?.uploads.origin, '_blank')}>
                  <FaDownload /> Baixar currículo ({resume?.resumeDocuments?.uploads.filename})
                </button>
              </CustomButtom>
            </CustomInput>
          </Column>
          <Column xl={2}>
            <CustomButtom background={PrimaryTheme.background.secondary}>
              <button
                onClick={() => {
                  navigate('/curriculos');
                }}
              >
                VOLTAR
              </button>
            </CustomButtom>
          </Column>
        </Row>
      </form>
    </ResumesContainer>
  );
}
