import React, { type SyntheticEvent, useEffect, useState } from 'react';
import { IntegrationBox, IntegrationCompleteContainer, IntegrationsContainer } from '../styles/pages/integrations';
import { useAuth } from '../hooks/useAuth';
import ApplicationApi from '../api/config';
import { type IntegrationBody, type IntegrationResponse } from '../interfaces/integration';
import { type AlertsProps } from '../interfaces/global';
import Alert, { AlertTypes } from '../components/alerts';
import Loading from '../components/loading';
import Row from '../components/row';
import Column from '../components/column';
import CustomInput from '../components/input';
import CustomButtom from '../components/button';
import { IntegrationValues } from '../enums/integration';
import { type ApiTokenResponse } from '../interfaces/token';
import { BsCheckCircleFill } from 'react-icons/bs';

const bodyDefaultValueIntegration = {
  name: '',
  description: null,
  clientId: null,
  secretKey: null,
  code: null,
  token: null,
  refreshToken: null,
  tokenExpireDate: null,
  apiUrl: null,
  redirectUrl: null,
};

const Integrations: React.FC = () => {
  const { token } = useAuth();

  const [apiToken, setApiToken] = useState<string>();

  const [alerts, setAlerts] = useState<AlertsProps>();
  const [melhorEnvioAlerts, setMelhorEnvioAlerts] = useState<AlertsProps>();
  const [googleAlerts, setGoogleAlerts] = useState<AlertsProps>();

  const [loading, setLoading] = useState<boolean>(true);
  const [melhorEnvioLoading, setMelhorEnvioLoading] = useState<boolean>(false);
  const [googleLoading, setGoogleLoading] = useState<boolean>(false);

  const [integrations, setIntegrations] = useState<IntegrationResponse[]>([]);

  const [integrationMelhorEnvio, setIntegrationMelhorEnvio] = useState<IntegrationResponse>();
  const [integrationGoogle, setIntegrationGoogle] = useState<IntegrationResponse>();

  const [integrationMelhorEnvioBody, setIntegrationMelhorEnvioBody] =
    useState<IntegrationBody>(bodyDefaultValueIntegration);

  const [integrationGoogleBody, setIntegrationGoogleBody] = useState<IntegrationBody>(bodyDefaultValueIntegration);

  let melhorEnvioWindow: Window | null;
  let melhorEnvioInterval: NodeJS.Timer;

  useEffect(() => {
    ApplicationApi.get<IntegrationResponse[]>('integracoes', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setIntegrations(data);
        setLoading(false);
      })
      .catch(() => {
        setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível recuperar Integrações' });
        setLoading(false);
      });
    ApplicationApi.get<ApiTokenResponse>('integracoes/api/token', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setApiToken(data.token);
        setLoading(true);
      })
      .catch(() => {
        setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível recuperar token de autenticação' });
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const melhorEnvio = integrations.filter(integration => integration.id === IntegrationValues.MELHOR_ENVIO)[0];
    const google = integrations.filter(integration => integration.id === IntegrationValues.GOOGLE)[0];

    setIntegrationMelhorEnvio(melhorEnvio);
    setIntegrationMelhorEnvioBody(melhorEnvio);

    setIntegrationGoogle(google);
    setIntegrationGoogleBody(google);
  }, [integrations]);

  const checkMelhorEnvioReturn = async (): Promise<void> => {
    const { data } = await ApplicationApi.get<IntegrationResponse>('integracoes/melhorenvio/token');

    if (data.token !== null) {
      melhorEnvioWindow?.close();
      clearInterval(melhorEnvioInterval);

      setMelhorEnvioAlerts({ type: AlertTypes.SUCCESS, message: 'Integração feita com sucesso.' });
      setMelhorEnvioLoading(false);
    }
  };

  const oAuthMelhorEnvio = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setMelhorEnvioLoading(true);

    try {
      if (integrationMelhorEnvio !== undefined) {
        const left = screen.width / 2 - 500 / 2;
        const top = screen.height / 2 - 600 / 2;

        melhorEnvioWindow = window.open(
          `${integrationMelhorEnvio.apiUrl}/oauth/authorize?client_id=${integrationMelhorEnvioBody.clientId}&redirect_uri=${integrationMelhorEnvioBody.redirectUrl}&state=${apiToken}&response_type=code&scope=cart-read cart-write companies-read companies-write coupons-read coupons-write notifications-read orders-read products-read products-write purchases-read shipping-calculate shipping-cancel shipping-checkout shipping-companies shipping-generate shipping-preview shipping-print shipping-share shipping-tracking ecommerce-shipping transactions-read users-read users-write`,
          'Autenticação Melhor Envio',
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=500, height=600, top=${top}, left=${left}, popup=true`,
        );

        melhorEnvioInterval = setInterval(async () => {
          await checkMelhorEnvioReturn();
        }, 800);
      }
    } catch {}
  };

  const authGoogle = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    setGoogleLoading(true);

    try {
      const { data } = await ApplicationApi.patch<IntegrationResponse>(
        `integracoes/${integrationGoogle?.id}`,
        integrationGoogleBody,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setIntegrationGoogle(data);
      setIntegrationGoogleBody(data);

      setGoogleAlerts({ type: AlertTypes.SUCCESS, message: 'Integração feita com sucesso.' });
    } catch {
      setGoogleAlerts({ type: AlertTypes.ERROR, message: 'Ocorreu um erro ao salvar informações em banco.' });
    }

    setGoogleLoading(false);
  };

  return (
    <IntegrationsContainer>
      {loading ? <Loading /> : <></>}
      {alerts !== undefined ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
      {integrationMelhorEnvio !== undefined ? (
        <IntegrationBox>
          {melhorEnvioLoading ? <Loading /> : <></>}
          {melhorEnvioAlerts !== undefined ? (
            <Alert type={melhorEnvioAlerts.type}>{melhorEnvioAlerts.message}</Alert>
          ) : (
            <></>
          )}
          <h1>{integrationMelhorEnvio.name}</h1>
          <h2>{integrationMelhorEnvio.description}</h2>
          {integrationMelhorEnvio.token !== null ? (
            <IntegrationCompleteContainer>
              <BsCheckCircleFill />
              <p>Integração concluída</p>
            </IntegrationCompleteContainer>
          ) : (
            <form onSubmit={oAuthMelhorEnvio}>
              <Row>
                <Column xl={3}>
                  <CustomInput>
                    <input
                      defaultValue={integrationMelhorEnvioBody.clientId ?? ''}
                      placeholder="ClientID"
                      onChange={e => {
                        setIntegrationMelhorEnvioBody({ ...integrationMelhorEnvioBody, clientId: e.target.value });
                      }}
                      required
                    />
                  </CustomInput>
                </Column>
                <Column xl={7}>
                  <CustomInput>
                    <input
                      defaultValue={integrationMelhorEnvioBody.secretKey ?? ''}
                      placeholder="SecretKey"
                      onChange={e => {
                        setIntegrationMelhorEnvioBody({ ...integrationMelhorEnvioBody, secretKey: e.target.value });
                      }}
                      required
                    />
                  </CustomInput>
                </Column>
                <Column xl={2}>
                  <CustomButtom>
                    <button type="submit">Gerar Token</button>
                  </CustomButtom>
                </Column>
              </Row>
            </form>
          )}
        </IntegrationBox>
      ) : (
        <></>
      )}
      {integrationGoogle !== undefined ? (
        <IntegrationBox>
          {googleLoading ? <Loading /> : <></>}
          {googleAlerts !== undefined ? <Alert type={googleAlerts.type}>{googleAlerts.message}</Alert> : <></>}
          <h1>{integrationGoogle.name}</h1>
          <h2>{integrationGoogle.description}</h2>
          <form onSubmit={authGoogle}>
            <Row>
              <Column xl={3}>
                <CustomInput>
                  <input
                    defaultValue={integrationGoogle.clientId ?? ''}
                    placeholder="ClientID"
                    onChange={e => {
                      setIntegrationGoogleBody({ ...integrationGoogleBody, clientId: e.target.value });
                    }}
                    required
                  />
                </CustomInput>
              </Column>
              <Column xl={7}>
                <CustomInput>
                  <input
                    defaultValue={integrationGoogle.secretKey ?? ''}
                    placeholder="SecretKey"
                    onChange={e => {
                      setIntegrationGoogleBody({ ...integrationGoogleBody, secretKey: e.target.value });
                    }}
                    required
                  />
                </CustomInput>
              </Column>
              <Column xl={2}>
                <CustomButtom>
                  <button type="submit">Cadastrar</button>
                </CustomButtom>
              </Column>
            </Row>
          </form>
        </IntegrationBox>
      ) : (
        <></>
      )}
    </IntegrationsContainer>
  );
};

export default Integrations;
