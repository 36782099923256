import React, { type ReactElement } from 'react';
import { CustomSelectContainer, CustomSelectLabel } from '../styles/components/select';

interface CustomSelectProps {
  children: ReactElement;
  marginBottom?: boolean;
  label?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ children, marginBottom, label }) => {
  return (
    <>
      {label !== undefined ? <CustomSelectLabel>{label}</CustomSelectLabel> : <></>}
      <CustomSelectContainer marginBottom={marginBottom}>{children}</CustomSelectContainer>
    </>
  );
};

export default CustomSelect;
