import React from 'react';
import { LoadingContainer } from '../styles/components/loading';
import { FaC } from 'react-icons/fa6';

interface LoadingProps {
  background?: string;
  color?: string;
  radius?: number;
}

const Loading: React.FC<LoadingProps> = ({ background, color, radius }) => {
  return (
    <LoadingContainer radius={radius} background={background} color={color}>
      <FaC />
    </LoadingContainer>
  );
};

export default Loading;
