import styled from 'styled-components';

export const AdministratorsContainer = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 12px;
  position: relative;
  margin-bottom: 12px;
`;

export const AdministratorRemoveLabel = styled.p`
  font-size: 0.9rem;
  margin-bottom: 8px;
`;
