import React, { useEffect, useState } from 'react';
import {
  ProductsHeader,
  ProductsContainer,
  ProductItem,
  ProductItemBox,
  ProductPriceContainer,
  ProductSortContainer,
} from '../styles/components/products';
import CustomInput from './input';
import { type ProductResponse } from '../pages/products/create';
import ApplicationApi from '../api/config';
import Loading from './loading';
import Alert, { AlertTypes } from './alerts';
import Row from './row';
import Column from './column';
import { FaRegCircle } from 'react-icons/fa6';
import { formatDecimal, formatMoney } from '../helpers/numberMethods';
import { FaCheckCircle, FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa';
import CurrencyInput from 'react-currency-input';
import { type CategoryResponse } from '../pages/categories/create';
import { type SubcategoryResponse } from '../pages/subcategories/create';

interface ProductsProps {
  setState: React.Dispatch<React.SetStateAction<ProductResponse[] | undefined>>;
  currentProducts?: ProductResponse[];
  price?: boolean;
  quantity?: boolean;
}

const Products: React.FC<ProductsProps> = ({ setState, currentProducts }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [sortedBy, setSortedBy] = useState('ASC');
  const [category, setCategory] = useState<CategoryResponse>();
  const [subcategory, setSubcategory] = useState<SubcategoryResponse>();

  const [products, setProducts] = useState<ProductResponse[]>([]);
  const [categories, setCategories] = useState<CategoryResponse[]>();
  const [subcategories, setSubcategories] = useState<SubcategoryResponse[]>();
  const [searchedProducts, setSearchedProducts] = useState<ProductResponse[]>([]);

  const [selectedProducts, setSelectedProducts] = useState<ProductResponse[]>(currentProducts ?? []);

  const [productsCustomPrice, setProductsCustomPrice] = useState<Array<{ productId: number; newPrice: string }>>(
    currentProducts?.map(product => ({ newPrice: product.price, productId: product.id })) ?? [],
  );

  useEffect(() => {
    ApplicationApi.get<ProductResponse[]>('produtos/home')
      .then(({ data }) => {
        setProducts(data);
        setSearchedProducts(
          data.sort((a, b) => {
            if (sortedBy === 'ASC') {
              return a.name > b.name ? 1 : -1;
            } else {
              return a.name < b.name ? 1 : -1;
            }
          }),
        );
        setLoading(false);
      })
      .catch(() => {});

    ApplicationApi.get<CategoryResponse[]>('categorias')
      .then(({ data }) => {
        setCategories(data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    setSearchedProducts(data =>
      data.sort((a, b) => {
        if (sortedBy === 'ASC') {
          return a.name > b.name ? 1 : -1;
        } else {
          return a.name < b.name ? 1 : -1;
        }
      }),
    );
  }, [sortedBy]);

  useEffect(() => {
    if (category !== undefined) {
      console.log(category);
      setSubcategories(category.subcategories);
      setSearchedProducts(products.filter(prod => prod.categoryId === category?.id));
    } else {
      setSearchedProducts(products);
    }
  }, [category]);

  useEffect(() => {
    if (subcategory !== undefined) {
      setSearchedProducts(products.filter(prod => prod.subcategoryId === subcategory?.id));
    }
  }, [subcategory]);

  useEffect(() => {
    if (productsCustomPrice.length > 0) {
      const array: ProductResponse[] = [];

      selectedProducts.forEach(item => {
        if (productsCustomPrice.some(product => product.productId === item.id)) {
          array.push({
            ...item,
            price: productsCustomPrice.filter(data => data.productId === item.id)[0].newPrice,
          });
        } else {
          array.push({
            ...item,
            price: item.price,
          });
        }
      });

      setState(array);
    } else {
      setState(selectedProducts);
    }
  }, [selectedProducts, productsCustomPrice]);

  const searchProducts = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value !== null) {
      const result = products.filter(o =>
        Object.keys(o).some(k =>
          String(o[k as keyof typeof o])
            .toLowerCase()
            .includes(e.target.value.toLowerCase()),
        ),
      );

      setSearchedProducts(result);
    }
  };

  const addCustomPrice = (productId: number, newPrice: string): void => {
    const countProductsWithCustomPrice = productsCustomPrice.some(item => item.productId === productId);

    if (countProductsWithCustomPrice) {
      const arrayWithoutProduct = productsCustomPrice.filter(item => item.productId !== productId);

      setProductsCustomPrice([
        ...arrayWithoutProduct,
        { productId, newPrice: newPrice !== '' ? formatDecimal(newPrice).toFixed(2) : '0.00' },
      ]);
    } else {
      setProductsCustomPrice([
        ...productsCustomPrice,
        { productId, newPrice: newPrice !== '' ? formatDecimal(newPrice).toFixed(2) : '0.00' },
      ]);
    }
  };

  return (
    <>
      <ProductsHeader>
        <h1>PRODUTOS</h1>
        <CustomInput>
          <select
            onChange={e => {
              setCategory(categories?.filter(cat => cat.id === parseInt(e.target.value))[0]);
            }}
          >
            <option>{category !== undefined ? 'Limpar' : 'Categoria'}</option>
            {categories?.map((category, i) => (
              <option key={i} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </CustomInput>
        <CustomInput>
          <select
            onChange={e => {
              setSubcategory(subcategories?.filter(sub => sub.id === parseInt(e.target.value))[0]);
            }}
          >
            <option>Subcategoria</option>
            {subcategories?.map((subcategory, i) => (
              <option key={i} value={subcategory.id}>
                {subcategory.name}
              </option>
            ))}
          </select>
        </CustomInput>
        <CustomInput>
          <input placeholder="Pesquisar produtos" onChange={searchProducts} />
        </CustomInput>
      </ProductsHeader>
      <ProductsContainer>
        {loading ? <Loading /> : <></>}
        {searchedProducts.length > 0 ? (
          <Row>
            <>
              <Column xl={12}>
                <ProductSortContainer>
                  {sortedBy === 'DESC' ? (
                    <FaSortAlphaDown
                      onClick={() => {
                        setSortedBy('ASC');
                      }}
                    />
                  ) : (
                    <FaSortAlphaDownAlt
                      onClick={() => {
                        setSortedBy('DESC');
                      }}
                    />
                  )}
                </ProductSortContainer>
              </Column>
              {searchedProducts.map((product, i) => (
                <>
                  {selectedProducts.some(item => item.id === product.id) ? (
                    <Column xl={4} key={i}>
                      <ProductItemBox>
                        <ProductItem
                          onClick={() => {
                            setSelectedProducts(products => products.filter(item => product.id !== item.id));
                          }}
                        >
                          <div>
                            <h1>{product.name.substring(0, 30)}...</h1>
                            <h2>R$ {formatMoney(product.price)}</h2>
                          </div>
                          <FaCheckCircle />
                        </ProductItem>
                        <ProductPriceContainer>
                          <p>Preço</p>
                          <CustomInput>
                            <CurrencyInput
                              onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
                                addCustomPrice(product.id, e.target.value);
                              }}
                              value={
                                productsCustomPrice.some(item => item.productId === product.id)
                                  ? formatMoney(
                                      productsCustomPrice.filter(item => item.productId === product.id)[0].newPrice,
                                    )
                                  : formatMoney(product.price)
                              }
                              decimalSeparator=","
                              thousandSeparator="."
                            />
                          </CustomInput>
                        </ProductPriceContainer>
                      </ProductItemBox>
                    </Column>
                  ) : (
                    <Column xl={4} key={i}>
                      <ProductItem
                        onClick={() => {
                          setSelectedProducts(products => [...products, product]);
                        }}
                      >
                        <div>
                          <h1>{product.name.substring(0, 30)}...</h1>
                          <h2>R$ {formatMoney(product.price)}</h2>
                        </div>
                        <FaRegCircle />
                      </ProductItem>
                    </Column>
                  )}
                </>
              ))}
            </>
          </Row>
        ) : (
          <Alert type={AlertTypes.WARNING}>Nenhum produto encontrado</Alert>
        )}
      </ProductsContainer>
    </>
  );
};

export default Products;
