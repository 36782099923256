import styled from 'styled-components';

interface NavbarProps {
  isActive: boolean;
}

interface NavbarImageProps {
  background: string;
}

export const NavbarContainer = styled.div<NavbarProps>`
  width: 250px;
  height: 100%;
  background: ${({ theme }) => theme.background.primary};
  margin-left: ${({ isActive }) => (isActive ? '0' : '-250px')};
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  color: ${({ theme }) => theme.color.primary};
  overflow-y: auto;
  transition:
    margin-left 0.3s ease,
    visibility 0.6s ease;
`;

export const NavbarImage = styled.div<NavbarImageProps>`
  background: url('${({ background }) => background}');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 160px;
  height: 60px;
  margin: 18px auto 18px auto;
`;

export const NavbarList = styled.ul`
  list-style: none;
`;

export const NavbarListItem = styled.li`
  height: 48px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  font-size: 0.6rem;
  background: ${({ theme }) => theme.background.primary};
  transition: background 0.2s ease;
  cursor: pointer;

  &: hover {
    background: #144380;
  }

  svg {
    margin-right: 4px;
    font-size: 0.9rem;
  }
`;
