// @ts-nocheck
import React, { type SyntheticEvent, useState, useEffect } from 'react';
import { ChargebacksContainer } from '../../styles/pages/chargebacks';
import { useAuth } from '../../hooks/useAuth';
import { type ChargebackBody, type ChargebackResponse } from '../../interfaces/chargeback';
import { type AlertsProps } from '../../interfaces/global';
import Loading from '../../components/loading';
import Alert, { AlertTypes } from '../../components/alerts';
import ApplicationApi from '../../api/config';
import Row from '../../components/row';
import Column from '../../components/column';
import { ButtonTheme, PrimaryTheme } from '../../styles/theme';
import CustomButtom from '../../components/button';
import { type OrderResponse } from '../orders/list';
import CustomSelect from '../../components/select';
import Datatable from '../../components/datatable';
import Select from 'react-select';
import { FaEye } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import CustomInput from '../../components/input';

const bodyDefaultValue = {
  orderId: undefined,
  statusId: 2,
  reason: '',
};

const Chargebacks: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [chargebacks, setChargebacks] = useState<ChargebackResponse[]>([]);
  const [orders, setOrders] = useState<OrderResponse[]>([]);

  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const [body, setBody] = useState<ChargebackBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<ChargebackResponse[]>('estornos', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setChargebacks(data);
        setTableLoading(false);
      })
      .catch(() => {});
    ApplicationApi.get<OrderResponse[]>('pedidos/estorno', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setOrders(data);
        setTableLoading(false);
      })
      .catch(() => {});
  }, []);

  const resetFormFields = (): void => {
    setBody(bodyDefaultValue);
  };

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      const { data } = await ApplicationApi.post<ChargebackResponse>('estornos/administrador', body, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados cadastrados com sucesso!' });
      setChargebacks(chargeback => [...chargeback, data]);
      resetFormFields();
    } catch {
      setAlerts({ type: AlertTypes.ERROR, message: 'Não foi possível prosseguir com estorno' });
    }

    setFormLoading(false);
  };

  return (
    <>
      <ChargebacksContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={4}>
              {orders.length > 0 ? (
                <CustomSelect marginBottom={true} label="Pedido">
                  <Select
                    onChange={e => {
                      setBody({ ...body, orderId: e?.value ?? undefined });
                    }}
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{
                      value: body?.orderId,
                      label: orders.filter(order => order.id === body?.orderId)[0]?.code ?? 'Selecione',
                    }}
                    placeholder="Selecione"
                    options={orders.map(order => ({ label: order.code, value: order.id }))}
                  />
                </CustomSelect>
              ) : (
                <CustomSelect marginBottom={true} label="Pedido">
                  <Select
                    styles={{
                      control: baseStyles => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        ':focus': { border: 0, outline: 0 },
                        ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                        background: PrimaryTheme.background.third,
                        color: PrimaryTheme.background.fifth,
                        fontSize: '.9rem',
                        height: '42px',
                      }),
                    }}
                    value={{ label: 'Selecione' }}
                  />
                </CustomSelect>
              )}
            </Column>
            <Column xl={4}>
              <CustomSelect marginBottom={true} label="Status">
                <Select
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      border: 0,
                      borderRadius: '6px',
                      overflow: 'hidden',
                      ':focus': { border: 0, outline: 0 },
                      ':focus-visible': { border: 0, outline: 0, borderColor: '#fff' },
                      background: PrimaryTheme.background.third,
                      color: PrimaryTheme.background.fifth,
                      fontSize: '.9rem',
                      height: '42px',
                    }),
                  }}
                  value={{
                    value: 2,
                    label: 'Aprovado',
                  }}
                  placeholder="Selecione"
                  isDisabled
                />
              </CustomSelect>
            </Column>
            <Column xl={4}>
              <CustomInput label="Motivo">
                <input
                  onChange={e => {
                    setBody({ ...body, reason: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button onClick={resetFormFields} type="reset">
                  LIMPAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </ChargebacksContainer>
      <ChargebacksContainer>
        {tableLoading ? <Loading /> : <></>}
        <Datatable
          columns={[
            { field: 'id', title: 'ID', width: 80 },
            { field: 'order', title: 'PEDIDO' },
            { field: 'status', title: 'STATUS' },
            { field: 'createdAt', title: 'CADASTRO', width: 200 },
            { field: 'view', title: 'VISUALIZAR', width: 80 },
          ]}
          data={chargebacks.map(chargeback => ({
            id: chargeback.id,
            order: chargeback.orders.code,
            status: chargeback.chargebackStatus.name,
            createdAt: new Date(chargeback.createdAt).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }),
            view: (
              <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
                <button
                  onClick={() => {
                    navigate(`${chargeback.id}`);
                  }}
                >
                  <FaEye />
                </button>
              </CustomButtom>
            ),
          }))}
        />
      </ChargebacksContainer>
    </>
  );
};

export default Chargebacks;
