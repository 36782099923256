import React, { type SyntheticEvent, useState, useEffect } from 'react';
import Column from '../../components/column';
import Row from '../../components/row';
import CustomInput from '../../components/input';
import CustomButtom from '../../components/button';
import { PrimaryTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import { type AlertsProps } from '../../interfaces/global';
import Alert, { AlertTypes } from '../../components/alerts';
import Loading from '../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { type TextBody, type TextResponse } from './create';
import { TextareaContainer, TextsContainer } from '../../styles/pages/texts';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useAuth } from '../../hooks/useAuth';
import Fancybox from '../../components/fancybox';
import { Upload, type UploadResponse } from '../../components/upload';
import { FancyboxFooter } from '../../styles/components/fancybox';
import { FaUpload } from 'react-icons/fa6';

const bodyDefaultValue = {
  title: '',
  text: '',
};

const Text: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useAuth();

  const [uploadFancybox, setUploadFancybox] = useState(false);
  const [alerts, setAlerts] = useState<AlertsProps>();

  const [formLoading, setFormLoading] = useState(true);

  const [textEditorValue, setTextEditorValue] = useState<string>('');
  const [image, setImage] = useState<UploadResponse>();
  const [body, setBody] = useState<TextBody>(bodyDefaultValue);

  useEffect(() => {
    ApplicationApi.get<TextResponse>(`textos/id/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setBody(data);
        setImage(data.textImages?.uploads);
        setFormLoading(false);
      })
      .catch(() => {
        setAlerts({ message: 'Não foi possível recuperar texto', type: AlertTypes.WARNING });
        setFormLoading(false);
      });
  }, [id]);

  const submitForm = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    setFormLoading(true);

    try {
      await ApplicationApi.patch<TextResponse>(
        `textos/${id}`,
        { ...body, text: textEditorValue, uploadId: image?.id },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setAlerts({ type: AlertTypes.SUCCESS, message: 'Dados atualizados com sucesso!' });
    } catch {
      setAlerts({
        type: AlertTypes.ERROR,
        message: 'Não foi possível atualizar texto, verifique as informações inseridas e tente novamente.',
      });
    }

    setFormLoading(false);
  };

  return (
    <>
      <Fancybox active={uploadFancybox}>
        <Upload path="textos" currentUpload={image} stateRef={setImage} />
        <FancyboxFooter>
          <Row>
            <Column xl={3}>
              <CustomButtom>
                <button
                  onClick={() => {
                    setUploadFancybox(false);
                  }}
                >
                  SELECIONAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={3}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    setUploadFancybox(false);
                  }}
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
          </Row>
        </FancyboxFooter>
      </Fancybox>
      <TextsContainer>
        {formLoading ? <Loading /> : <></>}
        {alerts != null ? <Alert type={alerts.type}>{alerts.message}</Alert> : <></>}
        <form onSubmit={submitForm}>
          <Row>
            <Column xl={8}>
              <CustomInput marginBottom={true} label="Título">
                <input
                  value={body.title}
                  onChange={e => {
                    setBody({ ...body, title: e.target.value });
                  }}
                  required
                />
              </CustomInput>
            </Column>
            <Column xl={4}>
              <CustomInput marginBottom={true} label="Foto">
                <CustomButtom>
                  <button
                    type="button"
                    onClick={() => {
                      setUploadFancybox(true);
                    }}
                  >
                    <FaUpload /> {image?.filename ?? 'SELECIONAR'}
                  </button>
                </CustomButtom>
              </CustomInput>
            </Column>
            <Column xl={12}>
              <TextareaContainer>
                <p>Texto</p>
                <CKEditor
                  editor={ClassicEditor}
                  data={body.text}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setTextEditorValue(data);
                  }}
                />
              </TextareaContainer>
            </Column>
            <Column xl={2}>
              <CustomButtom background={PrimaryTheme.background.secondary}>
                <button
                  onClick={() => {
                    navigate('/textos');
                  }}
                  type="button"
                >
                  CANCELAR
                </button>
              </CustomButtom>
            </Column>
            <Column xl={2}>
              <CustomButtom background="#28a745">
                <button type="submit">SALVAR</button>
              </CustomButtom>
            </Column>
          </Row>
        </form>
      </TextsContainer>
    </>
  );
};

export default Text;
