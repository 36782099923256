import React from 'react';
import { NavbarContainer, NavbarImage, NavbarList, NavbarListItem } from '../../styles/pages/includes/navbar';
import Accordion from '../../components/accordion';
import { FaImages, FaList, FaMoneyBillTransfer, FaRegNewspaper, FaTicket } from 'react-icons/fa6';
import { BiSolidOffer, BiText } from 'react-icons/bi';
import { RiLogoutBoxLine, RiFolderImageLine, RiDashboardLine, RiAdvertisementLine } from 'react-icons/ri';
import { FaCogs, FaShoppingBasket, FaUsers } from 'react-icons/fa';
import { MdOutlineContentCopy, MdCloudUpload, MdAdminPanelSettings } from 'react-icons/md';
import { GrContactInfo } from 'react-icons/gr';
import { IoPricetagsOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { TbMessageReport, TbTruckDelivery } from 'react-icons/tb';
import { useAuth } from '../../hooks/useAuth';

interface NavbarProps {
  isActive: boolean;
  currentPath: string;
}

const Navbar: React.FC<NavbarProps> = ({ isActive }) => {
  const navigate = useNavigate();
  const { onLogout } = useAuth();

  return (
    <>
      <NavbarContainer isActive={isActive}>
        <NavbarImage background="/images/admin/logotipo.png" />
        <NavbarList>
          <Accordion itemsLength={6} title="ADMINISTRAÇÃO">
            <li
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              <RiDashboardLine /> DASHBOARD
            </li>
            <li
              onClick={() => {
                navigate('/administradores');
              }}
            >
              <MdAdminPanelSettings /> ADMINISTRADORES
            </li>
            <li
              onClick={() => {
                navigate('/usuarios');
              }}
            >
              <FaUsers /> USUÁRIOS
            </li>
            <li
              onClick={() => {
                navigate('/newsletter');
              }}
            >
              <FaRegNewspaper /> NEWSLETTER
            </li>
            <li
              onClick={() => {
                navigate('/reclamacoes');
              }}
            >
              <TbMessageReport /> RECLAMAÇÕES
            </li>
            <li
              onClick={() => {
                navigate('/curriculos');
              }}
            >
              <GrContactInfo /> CURRÍCULOS
            </li>
          </Accordion>
          <Accordion itemsLength={14} title="CONTEÚDO">
            <li
              onClick={() => {
                navigate('/banners');
              }}
            >
              <FaImages /> BANNERS
            </li>
            <li
              onClick={() => {
                navigate('/textos');
              }}
            >
              <BiText /> TEXTOS
            </li>
            <li
              onClick={() => {
                navigate('/marcas');
              }}
            >
              <RiFolderImageLine /> MARCAS
            </li>
            <li
              onClick={() => {
                navigate('/categorias');
              }}
            >
              <FaList /> CATEGORIAS
            </li>
            <li
              onClick={() => {
                navigate('/subcategorias');
              }}
            >
              <FaList /> SUBCATEGORIAS
            </li>
            <li
              onClick={() => {
                navigate('/produtos');
              }}
            >
              <MdOutlineContentCopy /> PRODUTOS
            </li>
            <li
              onClick={() => {
                navigate('/pedidos');
              }}
            >
              <FaShoppingBasket /> PEDIDOS
            </li>
            <li
              onClick={() => {
                navigate('/estornos');
              }}
            >
              <FaMoneyBillTransfer /> ESTORNOS
            </li>
            <li
              onClick={() => {
                navigate('/imagens');
              }}
            >
              <MdCloudUpload /> IMAGENS
            </li>
            <li
              onClick={() => {
                navigate('/cupons');
              }}
            >
              <FaTicket /> CUPONS
            </li>
            <li
              onClick={() => {
                navigate('/entregas-rapidas');
              }}
            >
              <TbTruckDelivery /> FRETES
            </li>
            <li
              onClick={() => {
                navigate('/anuncios');
              }}
            >
              <RiAdvertisementLine /> ANÚNCIOS
            </li>
            <li
              onClick={() => {
                navigate('/promocoes');
              }}
            >
              <IoPricetagsOutline /> PROMOÇÕES
            </li>
            <li
              onClick={() => {
                navigate('/ofertas');
              }}
            >
              <BiSolidOffer /> OFERTAS
            </li>
          </Accordion>
          <NavbarListItem
            onClick={() => {
              navigate('/integracoes');
            }}
          >
            <FaCogs />
            <span>INTEGRAÇÕES</span>
          </NavbarListItem>
          <NavbarListItem onClick={onLogout}>
            <RiLogoutBoxLine />
            <span>SAIR</span>
          </NavbarListItem>
        </NavbarList>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
