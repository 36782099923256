import styled from 'styled-components';

export const DashboardCardSmall = styled.div`
  width: 100%;
  height: 100px;
  background: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
  position: relative;
`;

export const DashboardCardLarge = styled.div`
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
  position: relative;
  padding: 28px;

  h4 {
    margin-bottom: 8px;
  }
`;

export const DashboardCardContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 28px;
  color: ${({ theme }) => theme.background.primary};

  h1 {
    font-size: 2.7rem;
  }
`;

export const DashboardCardContentTitle = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.2rem;
    font-weight: 200;
  }

  h2 {
    font-size: 1.5rem;
  }
`;

export const NotificationContainer = styled.div`
  overflow-y: auto;
  height: 310px;
`;

export const NotificationBox = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 6px;
  margin-bottom: 8px;
  background: ${({ theme }) => theme.background.fourth};
  padding: 0 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: filter 0.2s ease;

  &:hover {
    filter: contrast(0.9);
  }

  svg {
    color: ${({ theme }) => theme.background.fifth};
    font-size: 1.5rem;
    margin-right: 8px;
  }

  div {
    h1 {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.background.fifth};
    }

    p {
      font-size: 0.65rem;
    }
  }
`;
