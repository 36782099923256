import React, { useEffect, useState } from 'react';
import {
  ExportContainer,
  ExportContentContainer,
  ExportHeaderContainer,
  ExportInputContainer,
} from '../styles/components/export';
import Loading from './loading';
import ApplicationApi from '../api/config';
import { FancyboxFooter } from '../styles/components/fancybox';
import Row from './row';
import Column from './column';
import CustomButtom from './button';
import { PrimaryTheme } from '../styles/theme';
import { useAuth } from '../hooks/useAuth';

interface ExportProps {
  path: string;
  fancyboxSetState: React.Dispatch<React.SetStateAction<boolean>>;
}

const Export: React.FC<ExportProps> = ({ path, fancyboxSetState }) => {
  const { token } = useAuth();

  const [columns, setColumns] = useState<string[]>();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [data, setData] = useState<any[]>([]);

  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    ApplicationApi.get<any[]>(path, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setData(data);
        setColumns(Object.keys(data[0]));
        setFormLoading(false);
      })
      .catch(() => {});
  }, [path]);

  const makeCsv = async (rows: any[], columns: string[], filename: string): Promise<void> => {
    const separator: string = ';';
    const keys: string[] = columns;

    const csvContent = `${keys.join(separator)}\n${rows
      .map(row =>
        keys
          .map(k => {
            let cell = row[k] ?? '';

            cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');

            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          })
          .join(separator),
      )
      .join('\n')}`;

    const blob = new Blob([csvContent], { type: 'text/csv;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <ExportContainer>
      <ExportHeaderContainer>
        <h1>Colunas disponíveis</h1>
      </ExportHeaderContainer>
      {formLoading ? (
        <Loading />
      ) : (
        <ExportContentContainer>
          <form>
            <Row>
              <>
                {columns?.map((column, i) => (
                  <Column xl={6} key={i}>
                    <ExportInputContainer>
                      <input
                        type="checkbox"
                        onChange={e => {
                          if (e.target.checked) {
                            setSelectedColumns(cols => [...cols, column]);
                          } else {
                            const arrayIndex = selectedColumns.indexOf(column);

                            if (arrayIndex > -1)
                              setSelectedColumns(cols => {
                                cols.splice(arrayIndex, 1);

                                return cols;
                              });
                          }
                        }}
                        value={column}
                      />
                      <p>{column}</p>
                    </ExportInputContainer>
                  </Column>
                ))}
              </>
            </Row>
          </form>
        </ExportContentContainer>
      )}
      <FancyboxFooter>
        <Row>
          <Column xl={6}>
            <CustomButtom background={PrimaryTheme.background.secondary}>
              <button
                onClick={() => {
                  fancyboxSetState(false);
                }}
              >
                FECHAR
              </button>
            </CustomButtom>
          </Column>
          <Column xl={6}>
            <CustomButtom>
              <button
                onClick={async () => {
                  await makeCsv(data, selectedColumns, `export-${new Date().getTime()}`);
                }}
              >
                EXPORTAR
              </button>
            </CustomButtom>
          </Column>
        </Row>
      </FancyboxFooter>
    </ExportContainer>
  );
};

export default Export;
