import React, { useState, useEffect } from 'react';
import CustomButtom from '../../components/button';
import { ButtonTheme } from '../../styles/theme';
import ApplicationApi from '../../api/config';
import Datatable from '../../components/datatable';
import Loading from '../../components/loading';
import { FaEye } from 'react-icons/fa6';
import { useNavigate, Link } from 'react-router-dom';
import { type UserAddressResponse, type UserPaymentResponse, type UserResponse } from '../users/list';
import { type CuponResponse } from '../cupons/create';
import { type ProductResponse } from '../products/create';
import { OrdersContainer } from '../../styles/pages/orders';
import { formatMoney } from '../../helpers/numberMethods';
import { useAuth } from '../../hooks/useAuth';
import { type FastDeliveryResponse } from '../fastDeliveries/create';

export interface OrderResponse {
  id: number;
  users: UserResponse;
  code: string;
  amount: string;
  subTotal: string;
  orderStatus: {
    id: number;
    name: string;
    description: string | null;
  };
  discountCoupon: CuponResponse | null;
  orderPayments: {
    id?: number;
    chargeId?: string | null;
    orderId?: number;
    paymentTypeId?: number;
    paymentsTypes: { id: number; name: string; description: string | null };
    userPaymentId?: number | null;
    userPayments?: UserPaymentResponse | null;
    qrCode?: string | null;
    qrLink?: string | null;
    ticketCode?: string | null;
    ticketLink?: string | null;
  };
  orderDeliveries?: {
    id?: number;
    chargeId?: string | null;
    orderId?: number;
    deliveryTypeId?: number;
    deliveryTypes?: { id: number; name: string; description: string };
    fastDeliveryId?: number | null;
    fastDeliveries?: FastDeliveryResponse | null;
    company?: string | null;
    deliveryTimeMin?: number | null;
    deliveryTimeMax?: number | null;
    price?: string;
    userAddressId?: number;
    userAddresses?: UserAddressResponse | null;
    trackingCode?: string | null;
    observations?: string | null;
  };
  orderProducts: Array<{
    id: number;
    orderId: number;
    productId: number;
    quantity: number;
    products: ProductResponse;
  }>;
  notificationUrl: string;
  statusId: number;
  orderId: string | null;
  updatedAt: Date;
  createdAt: Date;
}

const Orders: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [orders, setOrders] = useState<OrderResponse[]>([]);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    ApplicationApi.get<OrderResponse[]>('pedidos', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setOrders(data);
        setTableLoading(false);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <OrdersContainer>
        {tableLoading ? <Loading /> : <></>}
        <Datatable
          exportPath="pedidos"
          columns={[
            { field: 'id', title: 'ID', width: 80 },
            { field: 'code', title: 'CÓDIGO' },
            { field: 'amount', title: 'TOTAL' },
            { field: 'user', title: 'USUÁRIO' },
            { field: 'paymentType', title: 'FORMA DE PAGAMENTO' },
            { field: 'status', title: 'STATUS' },
            { field: 'createdAt', title: 'CADASTRO', width: 200 },
            { field: 'view', title: 'VISUALIZAR', width: 100 },
          ]}
          data={orders.map(order => ({
            id: order.id,
            code: order.code,
            amount: formatMoney(order.amount.toString()),
            user: <Link to={`/usuarios/${order.users.id}`}>{order.users.email}</Link>,
            paymentType: order.orderPayments.paymentsTypes.name,
            status: order.orderStatus.name,
            createdAt: new Date(order.createdAt).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }),
            view: (
              <CustomButtom background={ButtonTheme.background.warning} color={ButtonTheme.color.secondary} small>
                <button
                  onClick={() => {
                    navigate(`${order.id}`);
                  }}
                >
                  <FaEye />
                </button>
              </CustomButtom>
            ),
          }))}
        />
      </OrdersContainer>
    </>
  );
};

export default Orders;
