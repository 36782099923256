import styled from 'styled-components';

export const BrandsContainer = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 12px;
  position: relative;
  min-height: 100px;
`;

export const BrandsRemoveLabel = styled.p`
  font-size: 0.9rem;
  margin-bottom: 8px;
`;
