import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import Row from '../components/row';
import Column from '../components/column';
import {
  DashboardCardContentContainer,
  DashboardCardContentTitle,
  DashboardCardLarge,
  DashboardCardSmall,
  NotificationBox,
  NotificationContainer,
} from '../styles/pages/dashboard';
import Loading from '../components/loading';
import { Bar } from 'react-chartjs-2';
import ApplicationApi from '../api/config';
import { PrimaryTheme } from '../styles/theme';
import { useAuth } from '../hooks/useAuth';
import Alert, { AlertTypes } from '../components/alerts';
import CustomInput from '../components/input';

interface AnalyticsResponse {
  amount: {
    visits: number;
    orders: number;
    users: number;
    products: number;
    pages: Array<{ path: string; _count: { _all: number } }>;
  };
}

type VistisByPeriodResponse = Record<string, number>;
type UsersByPeriodResponse = Record<string, number>;
type SellsByPeriodResponse = Record<string, number>;
type OrdersByPeriodResponse = Record<string, number>;
type PagesByPeriodResponse = Record<string, number>;
type ReclamationsByPeriodResponse = Record<string, number>;

interface NotificationResponse {
  id: number;
  title: string;
  description: string | null;
  viewed: number;
  link: string | null;
}

const Dashboard: React.FC = () => {
  const date = new Date();
  const dateInPeriod = new Date();
  dateInPeriod.setMonth(date.getMonth() - 2);

  const { token } = useAuth();

  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState<AnalyticsResponse>();
  const [notifications, setNotifications] = useState<NotificationResponse[]>([]);

  // filters anda graphics Data

  const [visitsByPeriod, setVisitsByPeriod] = useState<VistisByPeriodResponse>();
  const [visitPeriod, setVisitPriod] = useState<{ in: number; out: number }>({
    in: dateInPeriod.getTime(),
    out: date.getTime(),
  });

  const dataVisits = {
    datasets: [
      {
        label: 'Número de visitas',
        data: visitsByPeriod,
        backgroundColor: PrimaryTheme.background.primary,
      },
    ],
  };

  const [usersByPeriod, setUsersByPeriod] = useState<UsersByPeriodResponse>();
  const [userPeriod, setUserPeriod] = useState<{ in: number; out: number }>({
    in: dateInPeriod.getTime(),
    out: date.getTime(),
  });

  const dataUsers = {
    datasets: [
      {
        label: 'Número de usuários cadastrados',
        data: usersByPeriod,
        backgroundColor: PrimaryTheme.background.primary,
      },
    ],
  };

  const [sellsByPeriod, setSellsByPeriod] = useState<SellsByPeriodResponse>();
  const [sellPeriod, setSellPeriod] = useState<{ in: number; out: number }>({
    in: dateInPeriod.getTime(),
    out: date.getTime(),
  });

  const dataSells = {
    datasets: [
      {
        label: 'Número de vendas',
        data: sellsByPeriod,
        backgroundColor: PrimaryTheme.background.primary,
      },
    ],
  };

  const [ordersByPeriod, setOrdersByPeriod] = useState<OrdersByPeriodResponse>();
  const [orderPeriod, setOrderPeriod] = useState<{ in: number; out: number }>({
    in: dateInPeriod.getTime(),
    out: date.getTime(),
  });

  const dataOrders = {
    datasets: [
      {
        label: 'Número de pedidos',
        data: ordersByPeriod,
        backgroundColor: PrimaryTheme.background.primary,
      },
    ],
  };

  const [pagesByPeriod, setPagesByPeriod] = useState<PagesByPeriodResponse[]>();
  const [pagePeriod, setPagePeriod] = useState<{ in: number; out: number }>({
    in: dateInPeriod.getTime(),
    out: date.getTime(),
  });

  const dataPages = {
    datasets: [
      {
        label: 'Número de visitas por página',
        data: pagesByPeriod,
        backgroundColor: PrimaryTheme.background.primary,
      },
    ],
  };

  const [reclamationsByPeriod, setReclamationsByPeriod] = useState<ReclamationsByPeriodResponse[]>();
  const [reclamationPeriod, setReclamationPeriod] = useState<{ in: number; out: number }>({
    in: dateInPeriod.getTime(),
    out: date.getTime(),
  });

  const dataReclamations = {
    datasets: [
      {
        label: 'Número de reclamações',
        data: reclamationsByPeriod,
        backgroundColor: PrimaryTheme.background.primary,
      },
    ],
  };

  useEffect(() => {
    ApplicationApi.get<AnalyticsResponse>('analytics', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setAnalytics(data);
        setLoading(false);
      })
      .catch(() => {});
    ApplicationApi.get<NotificationResponse[]>('notificacoes', { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setNotifications(data);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    ApplicationApi.get<VistisByPeriodResponse>(`analytics/visitas?in=${visitPeriod.in}&out=${visitPeriod.out}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setVisitsByPeriod(data);
      })
      .catch(() => {});
  }, [visitPeriod]);

  useEffect(() => {
    ApplicationApi.get<VistisByPeriodResponse>(`analytics/usuarios?in=${userPeriod.in}&out=${userPeriod.out}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setUsersByPeriod(data);
      })
      .catch(() => {});
  }, [userPeriod]);

  useEffect(() => {
    ApplicationApi.get<SellsByPeriodResponse>(`analytics/vendas?in=${sellPeriod.in}&out=${sellPeriod.out}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setSellsByPeriod(data);
      })
      .catch(() => {});
  }, [sellPeriod]);

  useEffect(() => {
    ApplicationApi.get<OrdersByPeriodResponse>(`analytics/pedidos?in=${orderPeriod.in}&out=${orderPeriod.out}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setOrdersByPeriod(data);
      })
      .catch(() => {});
  }, [orderPeriod]);

  useEffect(() => {
    ApplicationApi.get<PagesByPeriodResponse[]>(`analytics/paginas?in=${pagePeriod.in}&out=${pagePeriod.out}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        setPagesByPeriod(data);
      })
      .catch(() => {});
  }, [pagePeriod]);

  useEffect(() => {
    ApplicationApi.get<ReclamationsByPeriodResponse[]>(
      `analytics/reclamacoes?in=${reclamationPeriod.in}&out=${reclamationPeriod.out}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
      .then(({ data }) => {
        setReclamationsByPeriod(data);
      })
      .catch(() => {});
  }, [reclamationPeriod]);

  ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend);

  return (
    <Row>
      <Column xl={3}>
        <DashboardCardSmall>
          {loading ? (
            <Loading />
          ) : (
            <>
              {analytics !== undefined ? (
                <DashboardCardContentContainer>
                  <h1>{analytics.amount.visits < 9 ? `0${analytics.amount.visits}` : analytics.amount.visits}</h1>
                  <DashboardCardContentTitle>
                    <p>TOTAL DE</p>
                    <h2>VISITAS</h2>
                  </DashboardCardContentTitle>
                </DashboardCardContentContainer>
              ) : (
                <></>
              )}
            </>
          )}
        </DashboardCardSmall>
      </Column>
      <Column xl={3}>
        <DashboardCardSmall>
          {loading ? (
            <Loading />
          ) : (
            <>
              {analytics !== undefined ? (
                <DashboardCardContentContainer>
                  <h1>{analytics.amount.orders < 9 ? `0${analytics.amount.orders}` : analytics.amount.orders}</h1>
                  <DashboardCardContentTitle>
                    <p>TOTAL DE</p>
                    <h2>PEDIDOS</h2>
                  </DashboardCardContentTitle>
                </DashboardCardContentContainer>
              ) : (
                <></>
              )}
            </>
          )}
        </DashboardCardSmall>
      </Column>
      <Column xl={3}>
        <DashboardCardSmall>
          {loading ? (
            <Loading />
          ) : (
            <>
              {analytics !== undefined ? (
                <DashboardCardContentContainer>
                  <h1>{analytics.amount.users < 9 ? `0${analytics.amount.users}` : analytics.amount.users}</h1>
                  <DashboardCardContentTitle>
                    <p>TOTAL DE</p>
                    <h2>USUÁRIOS</h2>
                  </DashboardCardContentTitle>
                </DashboardCardContentContainer>
              ) : (
                <></>
              )}
            </>
          )}
        </DashboardCardSmall>
      </Column>
      <Column xl={3}>
        <DashboardCardSmall>
          {loading ? (
            <Loading />
          ) : (
            <>
              {analytics !== undefined ? (
                <DashboardCardContentContainer>
                  <h1>{analytics.amount.products < 9 ? `0${analytics.amount.products}` : analytics.amount.products}</h1>
                  <DashboardCardContentTitle>
                    <p>TOTAL DE</p>
                    <h2>PRODUTOS</h2>
                  </DashboardCardContentTitle>
                </DashboardCardContentContainer>
              ) : (
                <></>
              )}
            </>
          )}
        </DashboardCardSmall>
      </Column>
      <Column xl={9}>
        <DashboardCardLarge>
          <form>
            <Row>
              <Column xl={2}>
                <CustomInput label="De" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setVisitPriod({ ...visitPeriod, in: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
              <Column xl={2}>
                <CustomInput label="Até" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setVisitPriod({ ...visitPeriod, out: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
            </Row>
          </form>
          <Bar
            width={1173}
            height={250}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom' as const,
                },
                title: {
                  display: false,
                },
              },
            }}
            data={dataVisits}
          />
        </DashboardCardLarge>
      </Column>
      <Column xl={3}>
        <DashboardCardLarge>
          <h4>Notificações</h4>
          <NotificationContainer>
            {notifications.length > 0 ? (
              <>
                {notifications.map((notification, i) => (
                  <NotificationBox key={i}>
                    <div>
                      <h1>{notification.title}</h1>
                      {notification.description !== null ? (
                        <p>
                          {notification.description.length > 70
                            ? `${notification.description.substring(0, 70)}...`
                            : notification.description}
                        </p>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </NotificationBox>
                ))}
              </>
            ) : (
              <Alert type={AlertTypes.WARNING}>Nehuma notificação encontrada.</Alert>
            )}
          </NotificationContainer>
        </DashboardCardLarge>
      </Column>
      <Column xl={4}>
        <DashboardCardLarge>
          <form>
            <Row>
              <Column xl={3}>
                <CustomInput label="De" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setUserPeriod({ ...userPeriod, in: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
              <Column xl={3}>
                <CustomInput label="Até" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setUserPeriod({ ...userPeriod, out: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
            </Row>
          </form>
          <Bar
            width={1173}
            height={450}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom' as const,
                },
                title: {
                  display: false,
                },
              },
            }}
            data={dataUsers}
          />
        </DashboardCardLarge>
      </Column>
      <Column xl={4}>
        <DashboardCardLarge>
          <form>
            <Row>
              <Column xl={3}>
                <CustomInput label="De" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setSellPeriod({ ...sellPeriod, in: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
              <Column xl={3}>
                <CustomInput label="Até" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setSellPeriod({ ...sellPeriod, out: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
            </Row>
          </form>
          <Bar
            width={1173}
            height={450}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom' as const,
                },
                title: {
                  display: false,
                },
              },
            }}
            data={dataSells}
          />
        </DashboardCardLarge>
      </Column>
      <Column xl={4}>
        <DashboardCardLarge>
          <form>
            <Row>
              <Column xl={3}>
                <CustomInput label="De" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setOrderPeriod({ ...orderPeriod, in: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
              <Column xl={3}>
                <CustomInput label="Até" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setOrderPeriod({ ...orderPeriod, out: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
            </Row>
          </form>
          <Bar
            width={1173}
            height={450}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom' as const,
                },
                title: {
                  display: false,
                },
              },
            }}
            data={dataOrders}
          />
        </DashboardCardLarge>
      </Column>
      <Column xl={6}>
        <DashboardCardLarge>
          <form>
            <Row>
              <Column xl={3}>
                <CustomInput label="De" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setPagePeriod({ ...pagePeriod, in: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
              <Column xl={3}>
                <CustomInput label="Até" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setPagePeriod({ ...pagePeriod, out: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
            </Row>
          </form>
          <Bar
            width={1173}
            height={450}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom' as const,
                },
                title: {
                  display: false,
                },
              },
            }}
            data={dataPages}
          />
        </DashboardCardLarge>
      </Column>
      <Column xl={6}>
        <DashboardCardLarge>
          <form>
            <Row>
              <Column xl={3}>
                <CustomInput label="De" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setReclamationPeriod({ ...reclamationPeriod, in: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
              <Column xl={3}>
                <CustomInput label="Até" marginBottom>
                  <input
                    type="date"
                    onChange={e => {
                      setReclamationPeriod({ ...reclamationPeriod, out: new Date(e.target.value).getTime() });
                    }}
                  />
                </CustomInput>
              </Column>
            </Row>
          </form>
          <Bar
            width={1173}
            height={450}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom' as const,
                },
                title: {
                  display: false,
                },
              },
            }}
            data={dataReclamations}
          />
        </DashboardCardLarge>
      </Column>
    </Row>
  );
};

export default Dashboard;
