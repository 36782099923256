import styled from 'styled-components';

interface CustomButtonContainerProps {
  background?: string;
  color?: string;
  marginBottom?: boolean;
  small?: boolean;
}

export const CustomButtonContainer = styled.div<CustomButtonContainerProps>`
  margin-bottom: ${({ marginBottom }) => (marginBottom ?? false ? '8px' : '0')};
  width: 100%;

  button {
    background: ${({ background, theme }) => background ?? theme.background.primary};
    color: ${({ color, theme }) => color ?? theme.color.primary};
    width: 100%;
    height: ${({ small }) => (small ?? false ? '32px' : '42px')};
    border: 0;
    border-radius: 6px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: filter 0.2s ease;
  }

  button:hover {
    filter: contrast(1.2);
  }
`;
