import React, { createRef, useEffect, useState } from 'react';
import {
  UploadContainer,
  UploadNoContent,
  UploadHeader,
  UploadContent,
  UploadCard,
  UploadNewCard,
  UploadBox,
  UploadEANCard,
  UploadCardDocument,
  UploadCardDocumentView,
  UploadCardDocumentSelect,
  UploadNewCardDocument,
} from '../styles/components/upload';
import ApplicationApi from '../api/config';
import { FaCheck, FaDownload, FaPlus, FaRegCircle, FaUpload } from 'react-icons/fa6';
import Loading from './loading';
import { PrimaryTheme } from '../styles/theme';
import CustomInput from './input';
import { FaCheckCircle, FaSearch } from 'react-icons/fa';
import { useAuth } from '../hooks/useAuth';

export interface UploadResponse {
  id: number;
  originalName: string | null;
  filename: string;
  origin: string;
  size: string | null;
  createdAt: Date;
}

interface UploadProps {
  currentUpload: UploadResponse | undefined;
  path?: string;
  stateRef: React.Dispatch<React.SetStateAction<UploadResponse | undefined>>;
}

interface UploadDocumentProps {
  currentUpload: UploadResponse | undefined;
  stateRef: React.Dispatch<React.SetStateAction<UploadResponse | undefined>>;
}

interface UploadMultipleProps {
  currentUpload: UploadResponse[] | undefined;
  path?: string;
  ean?: string;
  stateRef: React.Dispatch<React.SetStateAction<UploadResponse[] | undefined>>;
}

interface ProductsUploadsPaginationResponse {
  data: UploadResponse[];
  currentPage: number;
  pagesCount: number;
  totalItems: number;
}

const Upload: React.FC<UploadProps> = ({ currentUpload, path, stateRef }) => {
  const { token } = useAuth();

  const [loading, setLoading] = useState(true);
  const [uploads, setUploads] = useState<UploadResponse[]>([]);
  const [selectedUploads, setSelectedUploads] = useState<UploadResponse | undefined>(currentUpload);

  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    ApplicationApi.get<UploadResponse[]>(`uploads/${path ?? ''}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setUploads(data);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    stateRef(selectedUploads);
  }, [selectedUploads]);

  useEffect(() => {
    if (currentUpload !== undefined) {
      setSelectedUploads(currentUpload);
    } else {
      setSelectedUploads(undefined);
    }
  }, [currentUpload]);

  const selectUpload = (upload: UploadResponse): void => {
    setSelectedUploads(upload);
  };

  const removeUpload = (upload: UploadResponse): void => {
    setSelectedUploads(undefined);
  };

  const submitForm = async (): Promise<void> => {
    const files = inputRef.current?.files;

    if (files != null) {
      setLoading(true);
      try {
        const formData = new FormData();

        for (const x in files) {
          formData.append('files', files[x]);
        }

        const { data } = await ApplicationApi.post<UploadResponse[]>('uploads', formData, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoading(false);

        data.forEach(upload => {
          setUploads(uploads => [...uploads, upload]);
        });
      } catch {}
    }
  };

  return (
    <UploadContainer>
      <UploadHeader>
        <h1>UPLOADS</h1>
        <CustomInput>
          <input type="text" placeholder="Pesquisar" />
        </CustomInput>
      </UploadHeader>
      <input onChange={submitForm} ref={inputRef} type="file" multiple max={5} accept="image/*" />
      <UploadContent>
        {uploads.length > 0 ? (
          <UploadBox>
            {loading ? <Loading /> : <></>}
            {uploads.map((upload, i) => (
              <>
                {selectedUploads?.id === upload.id ? (
                  <UploadCard
                    active={true}
                    key={i}
                    background={upload.origin}
                    onClick={() => {
                      removeUpload(upload);
                    }}
                  >
                    <div className="hover">
                      <div>
                        <FaCheck />
                      </div>
                    </div>
                  </UploadCard>
                ) : (
                  <UploadCard
                    active={false}
                    key={i}
                    background={upload.origin}
                    onClick={() => {
                      selectUpload(upload);
                    }}
                  >
                    <div className="hover">
                      <div>
                        <FaCheck />
                      </div>
                    </div>
                  </UploadCard>
                )}
              </>
            ))}
            <UploadNewCard onClick={() => (!loading ? inputRef.current?.click() : false)}>
              <FaPlus />
            </UploadNewCard>
          </UploadBox>
        ) : (
          <UploadNoContent onClick={() => (!loading ? inputRef.current?.click() : false)}>
            {loading ? (
              <Loading background={PrimaryTheme.background.third} color={PrimaryTheme.background.fifth} />
            ) : (
              <>
                <FaUpload />
                <p>ADICIONAR ARQUIVOS</p>
              </>
            )}
          </UploadNoContent>
        )}
      </UploadContent>
    </UploadContainer>
  );
};

const UploadMultiple: React.FC<UploadMultipleProps> = ({ currentUpload, path, ean, stateRef }) => {
  const { token } = useAuth();

  const [loading, setLoading] = useState(true);
  const [uploads, setUploads] = useState<UploadResponse[]>([]);
  const [selectedUploads, setSelectedUploads] = useState<UploadResponse[] | undefined>(currentUpload);
  const [eanActivated, setEanActivated] = useState(false);
  const [ids, setIds] = useState<number[]>();

  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (path === 'produtos') {
      ApplicationApi.get<ProductsUploadsPaginationResponse>(`uploads/produtos/paginacao?pagina=1&limite=50`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(({ data }) => {
          setUploads(data.data);
          setLoading(false);
        })
        .catch(() => {});
    } else {
      ApplicationApi.get<UploadResponse[]>(`uploads/${path ?? ''}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(({ data }) => {
          setUploads(data);
          setLoading(false);
        })
        .catch(() => {});
    }
  }, []);

  useEffect(() => {
    if (currentUpload !== undefined && currentUpload.length > 0 && uploads.length > 0) {
      currentUpload.forEach(up => {
        ApplicationApi.get<UploadResponse>(`uploads/${up.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then(({ data }) => {
            if (!uploads.some(item => item.id === data.id)) {
              setUploads([...uploads, data]);
            }
          })
          .catch(() => {});
      });
    }
  }, [currentUpload, uploads]);

  useEffect(() => {
    stateRef(selectedUploads);
    setIds(selectedUploads?.map(value => value.id));
  }, [selectedUploads]);

  useEffect(() => {
    if (currentUpload !== undefined && currentUpload.length > 0) {
      setSelectedUploads(currentUpload);
    } else {
      setSelectedUploads(undefined);
    }
  }, [currentUpload]);

  const selectUpload = (upload: UploadResponse): void => {
    setSelectedUploads(uploads => (uploads !== undefined ? [...uploads, upload] : [upload]));
  };

  const removeUpload = (upload: UploadResponse): void => {
    setSelectedUploads(uploads => (uploads !== undefined ? uploads.filter(item => item.id !== upload.id) : undefined));
  };

  const submitForm = async (): Promise<void> => {
    const files = inputRef.current?.files;

    if (files != null) {
      setLoading(true);
      try {
        const formData = new FormData();

        for (const x in files) {
          formData.append('files', files[x]);
        }

        const { data } = await ApplicationApi.post<UploadResponse[]>('uploads', formData, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoading(false);
        data.forEach(upload => {
          setUploads(uploads => [...uploads, upload]);
        });
      } catch {}
    }
  };

  const searchEanImage = async (): Promise<void> => {
    try {
      setLoading(true);
      const { data } = await ApplicationApi.get<UploadResponse>(`produtos/imagem/${ean}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setLoading(false);
      setUploads(uploads => [...uploads, data]);
      setEanActivated(true);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <UploadContainer>
      <UploadHeader>
        <h1>UPLOADS</h1>
        <CustomInput>
          <input type="text" placeholder="Pesquisar" />
        </CustomInput>
      </UploadHeader>
      <input onChange={submitForm} ref={inputRef} type="file" multiple max={5} accept="image/*" />
      <UploadContent>
        {uploads.length > 0 ? (
          <UploadBox>
            {loading ? <Loading /> : <></>}
            {uploads.map((upload, i) => (
              <>
                {ids !== undefined && ids.includes(upload.id) ? (
                  <UploadCard
                    active={true}
                    key={i}
                    background={upload.origin}
                    onClick={() => {
                      removeUpload(upload);
                    }}
                  >
                    <div className="hover">
                      <div>
                        <FaCheck />
                      </div>
                    </div>
                  </UploadCard>
                ) : (
                  <UploadCard
                    active={false}
                    key={i}
                    background={upload.origin}
                    onClick={() => {
                      selectUpload(upload);
                    }}
                  >
                    <div className="hover">
                      <div>
                        <FaCheck />
                      </div>
                    </div>
                  </UploadCard>
                )}
              </>
            ))}
            <UploadNewCard onClick={() => (!loading ? inputRef.current?.click() : false)}>
              <FaPlus />
            </UploadNewCard>
            {ean !== undefined && !eanActivated ? (
              <UploadEANCard onClick={searchEanImage}>
                <FaSearch />
                <p>Buscar pelo EAN</p>
              </UploadEANCard>
            ) : (
              <></>
            )}
          </UploadBox>
        ) : (
          <UploadNoContent>
            {loading ? (
              <Loading background={PrimaryTheme.background.third} color={PrimaryTheme.background.fifth} />
            ) : (
              <>
                <FaUpload />
                <p onClick={() => (!loading ? inputRef.current?.click() : false)}>ADICIONAR ARQUIVOS</p>
                {ean !== undefined ? (
                  <>
                    <span>OU</span>
                    <p onClick={searchEanImage}>BUSCAR IMAGEM PELO EAN</p>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </UploadNoContent>
        )}
      </UploadContent>
    </UploadContainer>
  );
};

const UploadDocument: React.FC<UploadDocumentProps> = ({ currentUpload, stateRef }) => {
  const { token } = useAuth();

  const [loading, setLoading] = useState(true);
  const [uploads, setUploads] = useState<UploadResponse[]>([]);
  const [selectedUploads, setSelectedUploads] = useState<UploadResponse | undefined>(currentUpload);

  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    ApplicationApi.get<UploadResponse[]>(`uploads/documentos`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => {
        setUploads(data);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    stateRef(selectedUploads);
  }, [selectedUploads]);

  useEffect(() => {
    if (currentUpload !== undefined) {
      setSelectedUploads(currentUpload);
    } else {
      setSelectedUploads(undefined);
    }
  }, [currentUpload]);

  const selectUpload = (upload: UploadResponse): void => {
    setSelectedUploads(upload);
  };

  const removeUpload = (upload: UploadResponse): void => {
    setSelectedUploads(undefined);
  };

  const submitForm = async (): Promise<void> => {
    const files = inputRef.current?.files;

    if (files != null) {
      setLoading(true);
      try {
        const formData = new FormData();

        for (const x in files) {
          formData.append('files', files[x]);
        }

        const { data } = await ApplicationApi.post<UploadResponse[]>('uploads', formData, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoading(false);

        data.forEach(upload => {
          setUploads(uploads => [...uploads, upload]);
        });
      } catch {}
    }
  };

  return (
    <UploadContainer>
      <UploadHeader>
        <h1>UPLOADS</h1>
        <CustomInput>
          <input type="text" placeholder="Pesquisar" />
        </CustomInput>
      </UploadHeader>
      <input onChange={submitForm} ref={inputRef} type="file" multiple max={5} accept="image/*,.pdf,.docx" />
      <UploadContent>
        {uploads.length > 0 ? (
          <UploadBox>
            {loading ? <Loading /> : <></>}
            {uploads.map((upload, i) => (
              <>
                {selectedUploads?.id === upload.id ? (
                  <UploadCardDocument active={true} key={i}>
                    {selectedUploads.filename}
                    <div>
                      <UploadCardDocumentView>
                        <a href={selectedUploads.origin} target="new">
                          <FaDownload />
                        </a>
                      </UploadCardDocumentView>
                      <UploadCardDocumentSelect
                        onClick={() => {
                          removeUpload(upload);
                        }}
                        active={true}
                      >
                        <FaCheckCircle />
                      </UploadCardDocumentSelect>
                    </div>
                  </UploadCardDocument>
                ) : (
                  <UploadCardDocument active={true} key={i}>
                    {upload.filename}
                    <div>
                      <UploadCardDocumentView>
                        <a href={upload.origin} target="new">
                          <FaDownload />
                        </a>
                      </UploadCardDocumentView>
                      <UploadCardDocumentSelect
                        onClick={() => {
                          selectUpload(upload);
                        }}
                        active={false}
                      >
                        <FaRegCircle />
                      </UploadCardDocumentSelect>
                    </div>
                  </UploadCardDocument>
                )}
              </>
            ))}
            <UploadNewCardDocument onClick={() => (!loading ? inputRef.current?.click() : false)}>
              <FaPlus />
            </UploadNewCardDocument>
          </UploadBox>
        ) : (
          <UploadNoContent onClick={() => (!loading ? inputRef.current?.click() : false)}>
            {loading ? (
              <Loading background={PrimaryTheme.background.third} color={PrimaryTheme.background.fifth} />
            ) : (
              <>
                <FaUpload />
                <p>ADICIONAR ARQUIVOS</p>
              </>
            )}
          </UploadNoContent>
        )}
      </UploadContent>
    </UploadContainer>
  );
};

export { Upload, UploadMultiple, UploadDocument };
