import React from 'react';
import { AlertContainer } from '../styles/components/alerts';
import { FaCircleCheck, FaCircleExclamation } from 'react-icons/fa6';

export enum AlertTypes {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

interface AlertProps {
  type: AlertTypes;
  children: string;
}

const Alert: React.FC<AlertProps> = ({ type, children }) => {
  let background;
  let color;
  let icon;

  switch (type) {
    case AlertTypes.SUCCESS:
      background = '#d4edda';
      color = '#2a6738';
      icon = <FaCircleCheck />;
      break;
    case AlertTypes.WARNING:
      background = '#fff3cd';
      color = '#856404';
      icon = <FaCircleExclamation />;
      break;
    case AlertTypes.ERROR:
      background = '#f8d7da';
      color = '#721c24';
      icon = <FaCircleExclamation />;
      break;
  }

  return (
    <AlertContainer background={background} color={color}>
      {icon}&nbsp;{children}
    </AlertContainer>
  );
};

export default Alert;
