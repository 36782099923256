import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import {
  ContentContainer,
  ContentContainerBox,
  ContentContainerHeader,
  ContentContainerTitle,
  ContentHeaderProfileContainer,
  ContentHeaderProfileImage,
  LayoutContainer,
} from '../styles/pages/layout';
import Navbar from './includes/navbar';
import { FaBars, FaCircleUser } from 'react-icons/fa6';
import { useAuth } from '../hooks/useAuth';
import Loading from '../components/loading';
import ApplicationApi from '../api/config';

const Layout: React.FC = () => {
  const currentPath = useLocation().pathname.split('/');

  const { token, user, onLogout } = useAuth();
  const [isNavActive, setIsNavActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const currentPathTitle = currentPath.length > 0 && currentPath[1] != null ? currentPath[1] : 'dashboard';

  document.title = `Administração :: ${currentPathTitle.charAt(0).toUpperCase()}${currentPathTitle.slice(1)}`;

  useEffect(() => {
    if (user !== null) {
      ApplicationApi.get(`administradores/${user?.id}`, { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          onLogout();
        });
    }
  }, [user?.id]);

  if (token === null) return <Navigate to="/login" replace />;

  return (
    <LayoutContainer>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Navbar isActive={isNavActive} currentPath={currentPathTitle} />
          <ContentContainer>
            <ContentContainerHeader>
              <FaBars
                onClick={() => {
                  isNavActive ? setIsNavActive(false) : setIsNavActive(true);
                }}
              />
              <ContentHeaderProfileContainer>
                {user?.avatar !== undefined ? (
                  <ContentHeaderProfileImage background={user.avatar ?? ''} />
                ) : (
                  <FaCircleUser />
                )}
                <p>{user?.name.toUpperCase()}</p>
              </ContentHeaderProfileContainer>
            </ContentContainerHeader>
            <ContentContainerBox>
              <ContentContainerTitle>{currentPathTitle}</ContentContainerTitle>
              <Outlet />
            </ContentContainerBox>
          </ContentContainer>
        </>
      )}
    </LayoutContainer>
  );
};

export default Layout;
